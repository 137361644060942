import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import PlainText from 'components/plainText'
import Nav from 'components/nav'
import Section from 'components/section'
import Footer from 'components/footer'

const StyledPressRelease = styled.div``

const PressRelease = props => {
  return (
    <StyledPressRelease>
      <Nav pageType="neutral" />
      <Section size="sm">
        <PlainText>{props.children}</PlainText>
      </Section>
      <Footer withBorder />
    </StyledPressRelease>
  )
}

PressRelease.propTypes = {
  children: PropTypes.node
}

export default PressRelease
