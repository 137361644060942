import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { color, fontSize } from 'styles/theme'

import LifeTimeLogoUrl from 'img/logo.svg'
import KimUrl from 'img/kim-logo.jpg'
import AenhhUrl from 'img/aenhh.png'

const Section = styled.section`
  height: 80px;
  border-bottom: 1px solid ${color.grey150};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  max-height: 40px;
  ${media.sm`
    max-height: 25px;
  `}
  ${media.xs`
    max-height: 20px;
  `}
`

const Bar = styled.span`
  font-size: ${fontSize.f3};
  color: ${color.grey200};
  margin: 0 32px;
  font-weight: 900;
  ${media.sm`
    font-size: ${fontSize.f2};
    margin: 0 16px;
  `}
`

const Header = () => {
  return (
    <Section>
      <Logo src={KimUrl} />
      <Bar>|</Bar>
      <Logo src={AenhhUrl} />
      <Bar>|</Bar>
      <Logo src={LifeTimeLogoUrl} />
    </Section>
  )
}

export default Header
