import Reimbursement from 'components/contentSections/reimbursement'
import Dialog from './dialog/'
import Footer from 'components/footer'
import React, { Fragment } from 'react'
import { color } from 'styles/theme'
import { googleAnalyticsTracker } from 'utils/tracking'
import Advantages from './advantages'
import CallToAction from './callToAction'
import Header from './header'
import Hero from './hero'
import Logos from 'components/logos'

class ReactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false
    }
  }

  openDialog = e => {
    googleAnalyticsTracker.trackCTAButtonClick(e)
    this.setState({ dialogIsOpen: true })
  }

  closeDialog = () => {
    this.setState({ dialogIsOpen: false })
  }

  render() {
    return (
      <Fragment>
        <Dialog
          isOpen={this.state.dialogIsOpen}
          closeDialog={this.closeDialog}
        />
        <Header />
        <Hero onClick={this.openDialog} />
        <Logos />
        <Reimbursement background={color.grey50} brandName="LifeTime-KIM" />
        <Advantages />
        <CallToAction onClick={this.openDialog} />
        <Footer noTopPadding />
      </Fragment>
    )
  }
}
export default ReactPage
