import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { fontSize, color, shadow } from 'styles/theme'

import Icon from 'components/icons'

const StyledDownload = styled.li`
  width: 100%;
  padding: 20px 80px;
  margin: 0;
  text-indent: 0;
  list-style-type: none;
  border-top: 1px solid ${color.grey150};
  display: flex;
  align-items: center;
  &:first-of-type {
    border-top: none;
  }
  ${media.md`
    padding: 20px 0;
  `}
  ${media.sm`
    flex-direction: column;
    padding: 16px 0;
  `}
`

const Thumbnail = styled.div`
  background: url(${props => props.url});
  background-size: cover;
  background-position: center center;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  flex-shrink: 0;
  border: 8px solid white;
  box-shadow: ${shadow.s0};
  ${media.sm`
    margin-bottom: 32px;
  `}
`

const TextWrapper = styled.div`
  padding: 0 40px;
  flex-grow: 1;
  ${media.sm`
    text-align: center;
    padding: 0 16px;
    margin-bottom: 16px;
  `}
`

const DownloadButton = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-shrink: 0;
  :hover {
    background: ${color.grey50};
  }
  cursor: pointer;
  ${media.sm`
    width: 64px;
    height: 64px;
    background: ${color.grey50};
    :hover {
      background: ${color.grey50};
    }
  `}
`

const Title = styled.div`
  font-weight: 900;
  font-size: ${fontSize.f5};
  ${media.sm`
    margin-bottom: 8px;
    font-size: ${fontSize.f4};
  `}
`

const FileType = styled.span`
  text-transform: uppercase;
`

const Download = props => {
  return (
    <StyledDownload>
      <Thumbnail url={props.thumbnail} />
      <TextWrapper>
        <Title>{props.title}</Title>
        <FileType>{props.fileType}</FileType>
      </TextWrapper>
      {(props.fileType === 'jpg' || props.fileType === 'png') && (
        <a href={props.file} download>
          <DownloadButton>
            <Icon glyph="download" />
          </DownloadButton>
        </a>
      )}
      {props.fileType === 'pdf' && (
        <a href={props.file} target="_blank" rel="noopener noreferrer">
          <DownloadButton>
            <Icon glyph="download" />
          </DownloadButton>
        </a>
      )}
    </StyledDownload>
  )
}

Download.propTypes = {
  file: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  fileType: PropTypes.string,
  thumbnail: PropTypes.string
}

export default Download
