import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import join from 'url-join'
import i18n from 'components/i18n'

class Link extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      localizedPath: ''
    }
  }

  componentDidMount() {
    this.setState({
      localizedPath: this.getLocalizedPath()
    })
  }

  getCurrentLanguage = () => i18n.language
  getLocalizedPath = () => {
    if (this.props.to) {
      const path = this.props.to.split('/')
      const currentLanguage = this.getCurrentLanguage()
      if (currentLanguage === 'en') {
        path.unshift(currentLanguage)
      }
      return '/' + join(path)
    } else {
      return ''
    }
  }

  render() {
    return (
      <Fragment>
        <a
          href={this.state.localizedPath}
          className={this.props.className}
          data-turbolinks="false"
        >
          {this.props.children}
        </a>
      </Fragment>
    )
  }
}

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default Link
