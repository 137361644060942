import React, { Fragment } from 'react'
import { Trans } from 'react-i18next'

import { color } from 'styles/theme'

import Header from 'components/header'
import Hero from './hero'
import TextWithIllustration from 'components/textWithIllustration'
import References from 'components/references'
import CallToAction from './callToAction'
import Footer from 'components/footer'

import SecurityIllustrationUrl from 'img/security-illustration.svg'
import DoneIllustrationUrl from 'img/abhaken.svg'
import QualityIllustrationUrl from 'img/quality-illustration.png'
import DrKrollnerUrl from 'img/krollner.jpg'
import DrTrettinUrl from 'img/trettin.jpg'
import DrRathmerUrl from 'img/rathmer.jpg'

class ReactPage extends React.Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Hero onClick={this.openDialog} />
        <TextWithIllustration
          subtitle="Zertifizierte Datensicherheit"
          title="Zu 100% datenschutzkonform"
          illustrationUrl={SecurityIllustrationUrl}
          background={color.grey50}
          smallIllustration
        >
          <p>
            LifeTime ist dank <em>verschlüsselter Datenübertragung</em> absolut
            sicher. Das System basiert auf dezentraler Datenhaltung – es findet
            keine dauerhafte Speicherung von Daten auf externen Plattformen
            statt.
          </p>
          <p>
            Unser Unternehmen hat zu keinem Zeitpunkt Zugriff auf
            personenbezogene Daten. LifeTime wurde mit dem ePrivacy-Gütesiegel
            zertifizert.
          </p>
        </TextWithIllustration>
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.digitalOffice.qualitySubtitle" />}
          title={<Trans i18nKey="pages.digitalOffice.qualityTitle" />}
          illustrationUrl={QualityIllustrationUrl}
          reverse
        >
          {<Trans i18nKey="pages.digitalOffice.qualityText" />}
        </TextWithIllustration>
        <TextWithIllustration
          subtitle="Prozessvereinfachung"
          title="Erledigtes einfach abhaken"
          illustrationUrl={DoneIllustrationUrl}
          background={color.grey50}
          smallIllustration
        >
          <p>
            Mit LifeTime haben Sie immer im Überblick welche Dokumente bereits
            abgelegt wurden.
          </p>
          <p>
            Durch die praktische Nachrichtenfunktion ist alles auch ohne weitere
            Telefonate und Rückfragen schnell erledigt.
          </p>
        </TextWithIllustration>
        <References noBottomPadding>
          <References.Reference
            avatar={DrKrollnerUrl}
            text={<Trans i18nKey="pages.digitalOffice.reference1Text" />}
            name={<Trans i18nKey="pages.digitalOffice.reference1Name" />}
            role={<Trans i18nKey="pages.digitalOffice.reference1Role" />}
          />
          <References.Reference
            avatar={DrTrettinUrl}
            text={<Trans i18nKey="pages.digitalOffice.reference2Text" />}
            name={<Trans i18nKey="pages.digitalOffice.reference2Name" />}
            role={<Trans i18nKey="pages.digitalOffice.reference2Role" />}
          />
          <References.Reference
            avatar={DrRathmerUrl}
            text={<Trans i18nKey="pages.digitalOffice.reference3Text" />}
            name={<Trans i18nKey="pages.digitalOffice.reference3Name" />}
            role={
              <Fragment>
                <Trans i18nKey="pages.digitalOffice.reference3Role" />
              </Fragment>
            }
          />
        </References>
        <CallToAction />
        <Footer noTopPadding />
      </Fragment>
    )
  }
}
export default ReactPage
