import React from 'react'

import PressReleaseLayout from './layout'

const PressRelease = () => {
  return (
    <PressReleaseLayout>
      <h1>
        Sicher und dezentral - Erstellung und Versand digitaler Test- und
        Impfnachweise mit LifeTime
      </h1>
      <p>
        <strong>
          Hamburg, 27.05.2021 – Das Unternehmen LifeTime entwickelt den sicheren
          Messenger weiter, so dass auch Impf- und Testnachweise erstellt und
          Ende-zu-Ende-verschlüsselt versendet werden können. Als Nachweis
          dienen fälschungssichere QR-Codes (sog. „BärCODEs”), die vom Zentrum
          für Digitale Gesundheit am BIH in der Berliner Charité entwickelt
          wurden.
        </strong>
      </p>
      <p>
        Um schnell wieder gemeinschaftliches und kulturelles Leben zu
        ermöglichen, ist ein fälschungssicherer Nachweis über negative
        COVID19-Tests bzw. digitale Impfnachweise über erfolgte Impfungen
        erforderlich. Doch wie können Test-, Impfeinrichtungen und Arztpraxen
        die Nachweise einfach digital erstellen und sicher versenden? Und wie
        können diese anschließend von Veranstaltern, Gastronomen und anderen
        beim Einlass überprüft werden, ohne den Schutz der sensiblen Daten zu
        gefährden?
      </p>
      <p>
        Hierzu bietet LifeTime jetzt eine einfache Lösung. Mithilfe der
        gleichnamigen Messenger-Software können Impf- und Testeinrichtungen und
        Praxen die fälschungssicheren, von der Charité entwickelten und bereits
        in diversen öffentlichen Berliner Einrichtungen eingesetzten BärCODEs
        erstellen und sicher an BürgerInnen versenden. Alle personenbezogenen
        Informationen werden dabei - anders als bei den vielfach kritisierten
        zentralisierten App-Lösungen - dezentral in den QR-Codes gespeichert und
        erst bei der Einlasskontrolle ausgelesen. Die Datensätze können so nicht
        von Dritten eingesehen werden und auch offline auf Validität geprüft
        werden.
      </p>
      <h2>LifeTime bietet einfache Lösung für alle Einrichtungen</h2>
      <p>
        Für die Erstellung der fälschungssicheren Nachweise kann die LifeTime
        Software unkompliziert und kostenfrei eingesetzt werden. Alle
        Einrichtungen, die impfen oder testen - also beispielsweise Arztpraxen,
        Apotheken, Labore sowie Test- oder Impfzentren - können nach
        Installation und Verifikation die Option “Impfnachweis / Testnachweis”
        aktivieren. Nach Eingabe der relevanten Daten des Geimpften / Getesteten
        wird mit einem Klick der fälschungssichere QR-Code erstellt.
      </p>
      <p>
        Zusätzlich bietet die Software die Möglichkeit, die generierten Codes
        unmittelbar und DSGVO-konform an die Getesteten bzw. Geimpften zu
        senden. Die Übertragung erfolgt unabhängig davon, ob der Empfänger die
        LifeTime-App zum Versandzeitpunkt bereits installiert hat. Der
        entsprechende Empfänger wird automatisch darüber informiert, wenn neue
        Unterlagen zum Abruf bereitstehen. Diese Funktion wird den Einrichtungen
        für drei Monate kostenfrei angeboten.
      </p>
      <p>
        „Durch die spezielle Architektur unserer Lösung ist es uns gelungen,
        höchste Datenschutzanforderungen mit einem extrem simplen Prozess für
        die Nutzer zu vereinen und so einen schnellen Neustart des Kulturlebens
        in Deutschland zu ermöglichen.”, so Dr. med. Johannes Jacubeit, Gründer
        und Geschäftsführer von LifeTime.
      </p>
      <p>
        Die Übertragung erfolgt bei LifeTime ausschließlich
        Ende-zu-Ende-verschlüsselt, und nur ein authentifizierter Empfänger
        (2-Faktor-Authentifizierungsverfahren) kann den gesendeten Nachweis
        entschlüsseln. Der Code wird ausschließlich lokal beim Versender und
        Empfänger gespeichert. Wenn der Getestete oder Geimpfte dies möchte,
        kann der Test- oder digitale Impfnachweis auch in eine andere App
        exportiert und dort zusätzlich gespeichert werden.
      </p>
      <p>
        Weitere Informationen zu LifeTime und BärCODE finden Sie unter:
        lifetime.eu/baercode
      </p>
      <h3>Über LifeTime</h3>
      <p>
        Mit dem LifeTime Messenger können Arztpraxen, Labore, MVZs und andere
        Einrichtungen datenschutzkonform mit Kollegen und Patienten
        kommunizieren und Dokumente sicher austauschen. Die Kommunikation
        erfolgt Ende-zu-Ende-verschlüsselt und funktioniert auch, wenn der
        Empfänger noch kein LifeTime-Nutzer ist. Neben dem sicheren Chat
        ermöglicht LifeTime den Versand von KIM-E-Mails über die
        Telematikinfrastruktur sowie das Erstellen und Versenden von
        fälschungssicheren Impf- und Testnachweisen. LifeTime wird inzwischen
        von vielen tausend Ärzten und Patienten in ihrem Arbeits- und
        Lebensalltag genutzt.
      </p>
    </PressReleaseLayout>
  )
}

export default PressRelease
