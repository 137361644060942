import { PrivacyEmail } from 'components/contactInformation'
import Footer from 'components/footer'
import Nav from 'components/nav'
import PlainText from 'components/plainText'
import Section from 'components/section'
import React, { Fragment } from 'react'
import PrivacyNav from './privacyNav'

const ReactPage = () => {
  return (
    <Fragment>
      <Nav pageType="neutral" />
      <Section size="sm">
        <PlainText>
          <PrivacyNav />
          <h1>
            Datenschutzerklärung
            <br />
            LifeTime App
          </h1>
          <p>
            Der verantwortungsvolle Umgang mit personenbezogenen Daten ist uns,
            der LifeTime GmbH, ein wichtiges Anliegen, das wir in all unseren
            Geschäftsprozessen berücksichtigen.
          </p>
          <p>
            Wir erheben, verwenden und speichern Ihre personenbezogenen Daten
            ausschließlich auf Grundlage der anwendbaren datenschutzrechtlichen
            Bestimmungen, insbesondere der Datenschutz-Grundverordnung (DSGVO)
            und des Bundesdatenschutzgesetzes (BDSG).
          </p>
          <p>
            In der LifeTime App behalten Patienten ihre Befunde, Arzttermine,
            Medikamente und Ärzte stets im Zugriff und Überblick. Mithilfe der
            App können Röntgenbilder, Befunde, andere Dokumente und Nachrichten
            digital und datenschutzkonform von medizinischen Einrichtungen
            empfangen und an diese gesendet werden.
          </p>
          <h2>Funktionen der App</h2>
          <p>
            Die LifeTime GmbH verarbeitet selbst keine personenbezogenen Daten.
          </p>
          <p>
            Wenn Sie als App Nutzer von Ihrem Smartphone aus Nachrichten an eine
            Einrichtung (z.B. Arztpraxis / Krankenhaus / Apotheke) versenden
            wollen UND die Einrichtungen die Möglichkeit der „Rückkommunikation“
            von der Patienten App hin zur Einrichtung aktiviert hat, findet
            einmalig ein SMS-Verifikationsprozess statt. Die LifeTime App
            übermittelt nach expliziter Freigabe des Anwenders (vor dem
            Nachrichtenversand an eine Einrichtung) die Mobilnummer für einen
            Handynummer-Verifikationsprozess an Twilio. Für das reine Empfangen
            von Nachrichten und Dokumenten ist dieser Verifikationsprozess nicht
            erforderlich.
          </p>
          <p>
            Personenbezogene Daten, die von Ihnen in die App eingegeben werden,
            werden ausschließlich dort lokal gespeichert. Bei Nutzung der
            LifeTime App werden keine Profile oder Accounts erstellt. Die
            LifeTime GmbH stellt lediglich das System zur Speicherung und
            Übertragung zur Verfügung. Eingetragene Arzttermine, Kontakte,
            Informationen zu Medikamenten und deren Einnahme sowie wie die
            erhaltenen Befunde und Nachrichten und sonstige Inhalte, die in die
            App eingestellt werden, werden ausschließlich lokal und
            verschlüsselt in der App gespeichert. Die LifeTime GmbH hat
            keinerlei Zugriff darauf und kann diese Informationen nicht
            verarbeiten.
          </p>
          <p>
            Beim Versenden von Dokumenten und Nachrichten sind die Daten
            Ende-zu-Ende verschlüsselt und anonymisiert. Somit werden auch dabei
            keine personenbezogenen Daten durch die LifeTime GmbH verarbeitet.
          </p>
          <p>
            Im Folgenden werden zur Übersichtlichkeit auch die einzelnen
            Funktionsmöglichkeiten der App kurz erläutert, bei denen LifeTime
            keine personenbezogenen Daten verarbeitet.
          </p>
          <h3>Empfang von Dokumenten und Nachrichten</h3>
          <p>
            Die LifeTime GmbH oder Dritte haben zu keinem Zeitpunkt Zugriff auf
            die personenbezogenen Daten. Die Dokumente und Nachrichten sind bei
            der Übertragung anonymisiert und verschlüsselt.
          </p>
          <p>
            Der Dokumenten- und Nachrichtenempfang von einer
            Gesundheitseinrichtung erfordert ggf. die Eingabe Ihrer
            Versichertennummer. Diese ist als Absicherung erforderlich, damit
            Sie von der App als rechtmäßiger Empfänger identifiziert werden
            können. Ihre Versichertennummer wird dabei nur lokal in der LifeTime
            App gespeichert und verarbeitet und nicht an die LifeTime GmbH oder
            Dritte übermittelt.
          </p>
          <p>
            In den Einstellungen der App können Sie Ihre Versichertennummer
            jederzeit ändern. Ihre Daten werden automatisch gelöscht, sobald die
            App gelöscht wird.
          </p>
          <h3>Versand von Dokumenten und Nachrichten</h3>
          <p>
            Die Dokumente und Nachrichten sind beim Versand an
            Gesundheitseinrichtungen ebenfalls verschlüsselt und anonymisiert,
            sodass LifeTime keinen Zugriff darauf hat.
          </p>
          <p>
            Der Dokumenten- und Nachrichtenversand an eine
            Gesundheitseinrichtung erfordert ggf. die einmalige Eingabe Ihrer
            Mobilnummer. Diese ist notwendig, damit Sie von der empfangenden
            Gesundheitseinrichtung als Absender identifiziert werden können. Die
            Eingabe der Mobilnummer ist ausschließlich erforderlich, wenn Nutzer
            Nachrichten und Dokumente an Einrichtungen senden und diese Option
            der „Rückkommunikation” von der Einrichtung aktiviert wurde.
          </p>
          <p>
            Wenn Nutzer die Mobilnummer explizit in der App eingeben, erfolgt
            eine einmalige Überprüfung dieser unter Zuhilfenahme eines Dienstes
            der Twilio Inc. (375 Beale Street, Suite 300 San Francisco, CA
            94105). Diese wird temporär gespeichert und nach Abschluss der
            Überprüfung unmittelbar gelöscht. Die jeweils aktuellen
            Datenschutzinformationen zu Twilio und ergänzende Informationen
            finden Sie hier:{' '}
            <a href="​https://www.twilio.com/legal/privacy​">
              https://www.twilio.com/legal/privacy
            </a>{' '}
            und{' '}
            <a href="https://www.twilio.com/legal/data-protection-addendum">
              https://www.twilio.com/legal/data-protection-addendum
            </a>
          </p>
          <p>
            Wenn Sie Nachrichten oder Dokumente an eine Einrichtung senden, von
            der Sie bisher keine Dokumente oder Nachrichten erhalten haben, wird
            Ihre Mobilnummer als Absenderkennung an die Einrichtung übertragen,
            damit diese Ihre Nachrichten zuordnen kann. Die Übertragung der
            Mobilnummer erfolgt für die LifeTime GmbH anonym und vollständig
            verschlüsselt. Die LifeTime GmbH hat keinen Zugriff darauf.
          </p>
          <p>
            Rechtsgrundlage für die Übermittlung Ihrer Mobilfunknummer an Twilio
            ist die Erfüllung eines Vertrages nach Art. 6 Abs. 1 lit. b) DSGVO.
          </p>
          <p>
            Die LifeTime GmbH hat zu keinem Zeitpunkt Zugriff auf die
            personenbezogenen Daten. In den Einstellungen der App können Sie
            Ihre Mobilnummer jederzeit ändern. Ihre Daten werden automatisch
            gelöscht, sobald die App gelöscht wird.
          </p>
          <h3>Medikamente</h3>
          <p>
            Beim Anlegen einer Medikation wird ein eingegebener Suchbegriff,
            bzw. der aus einem Barcode-Scan ausgelesene Medikamentenname mit
            einer Medikamentendatenbank abgeglichen, die von der Medizinische
            Medien Informations GmbH (Am Forsthaus Gravenbruch 7, 63263
            Neu-Isenburg) bereitgestellt wird. Dabei werden der Suchbegriff oder
            der Medikamentenname an die Medizinische Medien Informations GmbH zu
            dem Zweck übertragen, dass die korrekten Bezeichnungen der
            Medikamente ausgewählt und für die eigene Medikation lokal
            gespeichert werden können.
          </p>
          <p>
            Die LifeTime GmbH hat zu keinem Zeitpunkt Zugriff auf die Daten. Die
            Daten werden von der Medizinische Medien Informations GmbH nicht
            geteilt oder gespeichert, sondern umgehend gelöscht. Alle Daten der
            laufenden und vergangenen Medikationen, Einnahmeerinnerungen und
            -Schemas verbleiben lokal auf Ihrem Smartphone und werden weder an
            die LifeTime GmbH, noch an Dritte übertragen.
          </p>
          <p>
            Rechtsgrundlage für die Übermittlung ist Ihre Einwilligung nach Art.
            6 Abs. 1 lit. a) bzw. Art. 9 Abs. 2 lit a) DSGVO. Diese Einwilligung
            können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Dies
            kann unter anderem dadurch geschehen, dass Sie die Eingaben von
            Suchbegriffen oder Medikamentennamen abbrechen. Eine weitere
            Datenverarbeitung findet dann nicht mehr statt.
          </p>
          <p>
            Weitere Informationen zur Verarbeitung durch die Medizinische Medien
            Informations GmbH finden Sie unter:{' '}
            <a href="https://www.mmi.de/datenschutz">
              https://www.mmi.de/datenschutz
            </a>
          </p>
          <p>
            Die LifeTime GmbH hat zu keinem Zeitpunkt Zugriff auf die
            personenbezogenen Daten.
          </p>
          <h3>Anzeigen von Gesundheitseinrichtungen</h3>
          <p>
            Die LifeTime App benötigt Zugriff auf Ihren Standort zur Anzeige von
            Gesundheitseinrichtungen in Ihrer Umgebung. Der Standort wird dabei
            ausschließlich lokal auf Ihrem Gerät verarbeitet. Die Daten werden
            nicht gespeichert oder übertragen und weder der LifeTime GmbH noch
            Dritten zur Verfügung gestellt.
          </p>
          <p>
            Der Standort wird ausschließlich genutzt, um den entsprechenden
            Kartenausschnitt darstellen zu können, wenn Sie die Kartenfunktion
            in der App nutzen.
          </p>
          <p>
            Rechtsgrundlage für den Zugriff auf Ihren Standort ist Ihre
            Einwilligung nach Art. 6 Abs. 1 lit. a) DSGVO.
          </p>
          <p>
            Sie haben die Möglichkeit, der LifeTime App den Standortzugriff in
            den Einstellungen Ihres Smartphones zu untersagen.
          </p>
          <h2>Datenübermittlung an/von genutzte(n) Services</h2>
          <p>
            Bei jeder aktiven Internetverbindung wird Ihre IP-Adresse
            übermittelt. Ohne diese ist eine Datenübermittlung technisch nicht
            möglich. Es erfolgt keine Speicherung oder sonstige Auswertung von
            IP-Adressen.
          </p>
          <h2>Verwendung anonymisierter Daten zur Produktverbesserung</h2>
          <p>
            Zu Zwecken der Produktverbesserung erheben wir anonymisierte
            Nutzungsdaten unter Zuhilfenahme der Dienste Dritter.
          </p>
          <p>
            Teilweise findet eine Übertragung anonymisierter Nutzungsdaten in
            Länder außerhalb des Europäischen Wirtschaftsraums (EWR) statt. Eine
            Übermittlung von Daten erfolgt nur in Drittländer, bei denen die
            EU-Kommission ein angemessenes Schutzniveau bestätigt hat oder die
            Einhaltung von internationalen Sicherheitsstandards sichergestellt
            wurde (z.B. EU Standardvertragsklauseln).
          </p>
          <p>
            Folgende Drittanbieter werden im Zuge der Produktverbesserung
            eingesetzt:
          </p>
          <h3>Amplitude</h3>
          <p>
            Amplitude, ein Analysedienst der Amplitude Inc. (631 Howard St 5th
            floor, San Francisco, CA 94105, US), wird zur Analyse des
            Nutzerverhaltens genutzt. Zu diesem Zweck übermittelt die LifeTime
            App anonymisierte Informationen über Ihre Nutzung an einen Server
            von Amplitude. Es kommt dabei zu keiner Übertragung von Daten, die
            Rückschlüsse auf einen einzelnen Nutzer zulassen.
          </p>
          <p>
            <a href="https://amplitude.com/privacy">
              https://amplitude.com/privacy
            </a>
          </p>
          <p>
            Rechtsgrundlage für die Übermittlung ist Ihre Einwilligung nach Art.
            6 Abs. 1 lit. a) DSGVO. Sie haben in den Einstellungen der App
            jederzeit die Möglichkeit, Ihre Einwilligung zum Tracking zu
            widerrufen.
          </p>
          <h3>Crashlytics</h3>
          <p>
            Wir nutzen Crashlytics, einen Analysedienst der Google Ireland
            Limited (Gordon House, Barrow Street, Dublin 4, Irland) zur Analyse
            von Fehlern, die während der Nutzung der App auftreten. Zu diesem
            Zweck übermittelt die LifeTime App anonymisierte Informationen über
            aufgetretene Fehler an einen Server von Google. Es kommt dabei zu
            keiner Übertragung von Daten, die Rückschlüsse auf einen einzelnen
            Nutzer zulassen.
          </p>
          <p>
            <a href="https://firebase.google.com/terms/crashlytics">
              https://firebase.google.com/terms/crashlytics
            </a>
          </p>
          <p>
            Im Rahmen der Einrichtung der LifeTime App haben Sie die
            Möglichkeit, die Erhebung aller vorgenannten Daten zu aktivieren
            oder zu deaktivieren. Über eine Option in der „Datenschutzerklärung“
            in den „Einstellungen“ können Sie darüber hinaus jederzeit Ihre
            Auswahl ändern.
          </p>
          <p>
            Rechtsgrundlage für die Übermittlung ist Ihre Einwilligung nach Art.
            6 Abs. 1 lit. a) DSGVO. Sie haben in den Einstellungen der App die
            Option, Ihre Einwilligung zum Tracking zu widerrufen.
          </p>
          <h2>Speicherdauer</h2>
          <p>
            Wir speichern Ihre Daten nur so lange, wie es für die Erreichung des
            Verarbeitungszweckes nötig ist oder die Speicherung einer
            gesetzlichen Aufbewahrungsfrist unterliegt.
          </p>
          <p>
            Wir speichern Ihre Daten,
            <ul>
              <li>
                wenn Sie in die Verarbeitung eingewilligt haben, höchstens so
                lange, bis Sie Ihre Einwilligung widerrufen;
              </li>
              <li>
                wenn wir die Daten zur Durchführung eines Vertrags benötigen,
                höchstens so lange, wie das Vertragsverhältnis mit Ihnen besteht
                oder gesetzliche Aufbewahrungsfristen laufen.
              </li>
            </ul>
          </p>
          <p>
            Sollten Sie die App löschen, werden auch Ihre in den App
            gespeicherten Daten vollständig gelöscht. Möglichkeiten zum Widerruf
            der Einwilligung können Sie dem vorab beschriebenen Erläuterungen
            entnehmen.
          </p>
          <h2>Datensicherheit</h2>
          <p>
            Die Übertragung personenbezogener Daten erfolgt stets verschlüsselt.
            Darüber hinaus haben wir technische und organisatorische Maßnahmen
            ergriffen, um Ihre personenbezogenen Daten zu schützen, insbesondere
            gegen zufällige oder vorsätzliche Manipulation, Verlust, Zerstörung
            oder Zugriff unberechtigter Personen. Diese Sicherheitsmaßnahmen
            werden entsprechend der technologischen Entwicklung fortlaufend
            angepasst.
          </p>
          <h2>Rechte der Betroffenen</h2>
          <p>
            Grundsätzlich haben Sie laut Gesetz das Recht,
            <ul>
              <li>
                unentgeltlich Auskunft über die Verarbeitung Ihrer Daten zu
                verlangen, sowie auf den Erhalt einer Kopie Ihrer
                personenbezogenen Daten. Auskunft können Sie u. a. verlangen
                über die Zwecke der Verarbeitung, die Kategorien der
                personenbezogenen Daten, die verarbeitet werden, die Empfänger
                der Daten (sofern eine Weitergabe erfolgt), die Dauer der
                Speicherung oder die Kriterien für die Festlegung der Dauer;
              </li>
              <li>
                Ihre Daten zu berichtigen. Sollten Ihre personenbezogenen Daten
                unvollständig sein, so haben Sie, unter Berücksichtigung der
                Verarbeitungszwecke, das Recht, die Daten zu vervollständigen;
              </li>
              <li>
                Ihre Daten löschen oder sperren zu lassen. Gründe für das
                Bestehen eines Löschungs-/Sperrungsanspruchs können u.a. sein:
                der Widerruf der Einwilligung, auf der die Verarbeitung fußt;
                die betroffene Person legt Widerspruch gegen die Verarbeitung
                ein; die personenbezogenen Daten wurden unrechtmäßig
                verarbeitet;
              </li>
              <li>die Verarbeitung einschränken zu lassen;</li>
              <li>der Verarbeitung Ihrer Daten zu widersprechen;</li>
              <li>
                Ihre Einwilligung zur Verarbeitung Ihrer Daten für die Zukunft
                zu widerrufen;
              </li>
              <li>
                sich bei der zuständigen Aufsichtsbehörde über eine unzulässige
                Datenverarbeitung zu beschweren.
              </li>
            </ul>
          </p>
          <p>
            Wir weisen darauf hin, dass im Falle der LifeTime App die LifeTime
            GmbH keinen Zugriff auf personenbezogene Daten hat, die Daten nur
            vollständig verschlüsselt übertragen werden und nur die
            Rechtsgrundlage der Einwilligung genutzt wird, womit insbesondere
            die Rechte auf Auskunft, Berichtigung, Löschung und Einschränkung
            der Verarbeitung nur bedingt zu Ergebnissen führen würden.
          </p>
          <h2>Verantwortlicher</h2>
          <p>
            Verantwortlich im Sinne der DSGVO, des BDSG und sonstiger
            datenschutzrechtlicher Bestimmungen ist die:
          </p>
          <p>
            LifeTime GmbH
            <br />
            Rehmkamp 1, 24161 Altenholz
            <br />
            Geschäftsführer: Dr. André Kaeding
            <br />
            E-Mail: <PrivacyEmail />
          </p>
          <h2>Datenschutzbeauftragter</h2>
          <p>
            ePrivacy GmbH
            <br />
            vertreten durch Prof. Dr. Christoph Bauer
            <br />
            Große Bleichen 21, 20354 Hamburg
            <br />
          </p>
          <p>
            Zu allen Fragen und Anliegen bezüglich Ihrer Daten, wenden Sie sich
            gerne an <PrivacyEmail />
          </p>
          <p>
            Sollten Sie direkt mit unserem Datenschutzbeauftragten kommunizieren
            wollen (beispielsweise, weil Sie ein besonders sensibles Anliegen
            haben), kontaktieren Sie diesen bitte auf dem Postweg, da die
            Kommunikation per E-Mail immer Sicherheitslücken aufweisen kann.
            Bitte geben Sie bei der Anfrage an, dass sich Ihr Anliegen auf die
            Firma LifeTime bezieht.
          </p>
          <h2>Änderungen</h2>
          <p>
            Vor dem Hintergrund der sich fortlaufend ändernden technologischen
            Rahmenbedingungen behalten wir uns vor, Änderungen an der
            vorliegenden Datenschutzerklärung vorzunehmen.
          </p>
          <p>Gültig ab: 01.03.2022</p>
        </PlainText>
      </Section>
      <Footer withBorder />
    </Fragment>
  )
}

export default ReactPage
