import Section from 'components/section'
import BlueSwirlUrl from 'img/blue-swirl.svg'
import AttachmentIconUrl from 'img/feature-icon-attachment.svg'
import ComposeIconUrl from 'img/feature-icon-compose.svg'
import ConfirmationIconUrl from 'img/feature-icon-confirmation.svg'
import DoneIconUrl from 'img/feature-icon-done.svg'
import ExportIconUrl from 'img/feature-icon-export.svg'
import FilterIconUrl from 'img/feature-icon-filter.svg'
import RoundCheckmarkUrl from 'img/round-checkmark.svg'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { color, fontSize } from 'styles/theme'
import media from 'utils/media-queries'

const BlueSection = styled(Section)`
  padding: 290px 0 96px 0;
  margin-top: -300px;
  background-color: ${color.blue500};
  background-image: url(${BlueSwirlUrl});
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  ${media.xl`
    padding-bottom: 80px;
    margin-top: -240px;
  `}
  ${media.md`
    padding: 180px 0 64px 0;
    margin-top: -160px;
  `}
  ${media.sm`
    margin-top: -160px;
    padding-bottom: 40px;
  `}
  ${media.xs`
    padding-top: 120px;
    margin-top: -100px;
  `}
`

const FeaturesGrid = styled.div`
  display: none;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 32px;
  color: white;
  h3 {
    font-size: ${fontSize.f3};
    margin-bottom: 12px;
  }
  ${media.md`
    display: grid;
  `}
  ${media.sm`
    display: none;
  `}
`

const FeatureIcon = styled.div`
  background: url(${props => props.icon});
  height: 28px;
  width: 40px;
  background-repeat: no-repeat;
  margin-bottom: 24px;
  background-size: contain;
`

const AdvantagesIcon = styled.div`
  background: url(${RoundCheckmarkUrl});
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  margin-bottom: 32px;
  background-size: contain;
`

const AdvantagesGrid = styled.div`
  border-top: 2px solid ${color.blue400};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 48px;
  color: white;
  margin-top: 80px;
  padding-top: 80px;
  h2 {
    font-size: ${fontSize.f7};
    margin-bottom: 20px;
    ${media.sm`
      font-size: ${fontSize.f6};
    `}
    ${media.xs`
      font-size: ${fontSize.f5};
    `}
  }
  a {
    color: white;
    :visited {
      color: white;
    }
    :hover {
      color: white;
    }
    :focus {
      color: white;
    }
  }
  ${media.md`
    grid-template-columns: auto;
    grid-gap: 24px;
    margin-top: 40px;
    padding-top: 40px;
  `}
  ${media.sm`
    grid-gap: 16px;
  `}
`

const AdvantagesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const TestNow = props => {
  return (
    <BlueSection>
      <FeaturesGrid>
        <div>
          <FeatureIcon icon={ComposeIconUrl} />
          <h3>Eine Benutzeroberfläche - alle Kanäle</h3>
          <p>
            Neue Nachricht mit wenigen Klicks per Chat, KIM oder Fax versenden
          </p>
        </div>
        <div>
          <FeatureIcon icon={AttachmentIconUrl} />
          <h3>Dateien anhängen</h3>
          <p>
            Per drag & drop, per Druckvorgang aus Ihrem PVS oder automatisiert
            per API
          </p>
        </div>
        <div>
          <FeatureIcon icon={DoneIconUrl} />
          <h3>Erledigtes abhaken</h3>
          <p>Freier Kopf dank leerer Inbox</p>
        </div>
        <div>
          <FeatureIcon icon={ExportIconUrl} />
          <h3>Nachrichten archivieren</h3>
          <p>Dokumentationspflichten erfüllen</p>
        </div>
        <div>
          <FeatureIcon icon={ConfirmationIconUrl} />
          <h3>Empfangsbestätigung</h3>
          <p>Erfahren Sie, ob Ihre Nachricht angekommen ist</p>
        </div>
        <div>
          <FeatureIcon icon={FilterIconUrl} />
          <h3>Nachrichtenverläufe filtern</h3>
          <p>Sehen Sie nur die für Sie relevanten Gespräche</p>
        </div>
      </FeaturesGrid>
      <AdvantagesWrapper>
        <AdvantagesGrid>
          <div>
            <AdvantagesIcon />
            <h2>Weniger Telefon&shy;aufkommen</h2>
            <p>
              Anfragen von Kollegen und Patienten erreichen Sie per sicherem
              Chat und können so ganz entspannt der Reihe nach abgearbeitet
              werden.
            </p>
          </div>
          <div>
            <AdvantagesIcon />
            <h2>Sicherer Kanal zu Kollegen und Patienten</h2>
            <p>
              Unabhängig davon, ob Ihre Patienten und Kollegen LifeTime bereits
              nutzen oder nicht – Ihre Nachricht wird über einen der{' '}
              <a href="#channels">integrierten Kanäle</a> zugestellt (SMS, Fax,
              Chat, KIM).
            </p>
          </div>
          <div>
            <AdvantagesIcon />
            <h2>Zukunftssicher & abrechenbar</h2>
            <p>
              LifeTime ist vollständig kompatibel mit allen KIM-Diensten und
              Praxisverwaltungssystemen. Versandvorgänge mit LifeTime sind{' '}
              <a href="#reimbursement">abrechenbar</a>.
            </p>
          </div>
        </AdvantagesGrid>
      </AdvantagesWrapper>
    </BlueSection>
  )
}

TestNow.propTypes = {
  background: PropTypes.string
}

export default TestNow
