import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import media from 'utils/media-queries'

import Link from 'components/link'
import Icon from 'components/icons'
import Section from 'components/section'
import CTAContainer from 'components/ctaContainer'
import Button from 'components/button'
import { SalesPhone } from 'components/contactInformation'

const CTAInfo = styled.div``

const Ul = styled.ul`
  padding-left: 0;
  max-width: 250px;
  margin: 24px auto;
`

const Li = styled.li`
  display: flex;
  align-items: center;
`

const ListItemText = styled.span`
  padding-left: 8px;
  padding-top: 4px;
  text-align: left;
`

const ButtonWrapper = styled.div`
  margin: 16px 0;
  ${media.sm`
    margin: 24px 0;
  `}
`

const CTAButton = styled(Button)`
  margin: 8px 16px;
`

const PhoneWrapper = styled.div`
  a {
    font-weight: 900;
    color: white;
    &:visited {
      color: white;
    }
    &:hover {
      color: white;
    }
    &:active {
      color: white;
    }
  }
`

const CTAText = styled.div`
  max-width: 700px;
`

const CallToAction = props => {
  const { t } = useTranslation()
  return (
    <Section>
      <CTAContainer>
        <h2>Jetzt LifeTime-Account anlegen</h2>
        <CTAInfo>
          <CTAText>
            <p>
              Sobald KIM verfügbar ist, werden Sie darüber informiert und können
              den Service unkompliziert dazubuchen.
            </p>
            <p>
              Falls Sie noch nicht an die Telematik-Infrastruktur angebunden
              sind, übernehmen wir darüber hinaus auch gerne die Einrichtung und
              Betreuung eines Konnektors.
            </p>
          </CTAText>
          <Ul>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>Kommunikation mit allen Akteuren</ListItemText>
            </Li>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>
                {t('contentSections.callToAction.listItem2')}
              </ListItemText>
            </Li>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>
                {t('contentSections.callToAction.listItem3')}
              </ListItemText>
            </Li>
          </Ul>
        </CTAInfo>
        <ButtonWrapper>
          <CTAButton buttonType="light" onClick={props.onClick}>
            {t('contentSections.callToAction.button1')}
          </CTAButton>
          <Link to="/order/plan">
            <CTAButton buttonType="outline">
              {t('contentSections.callToAction.button2')}
            </CTAButton>
          </Link>
        </ButtonWrapper>
        <Trans i18nKey="contentSections.callToAction.call" />
        <PhoneWrapper>
          <SalesPhone />
        </PhoneWrapper>
      </CTAContainer>
    </Section>
  )
}

CallToAction.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default CallToAction
