import Button from 'components/button'
import List from 'components/list'
import Section from 'components/section'
import KimFaxChatUrl from 'img/kim-fax-chat2.png'
import ListStyleImageUrl from 'img/list-arrow.svg'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { color, fontSize } from 'styles/theme'
import media from 'utils/media-queries'
import Link from 'components/link'

const H1 = styled.h1`
  font-size: ${fontSize.f9};
  color: ${color.blue500};
  margin-bottom: 64px;
  line-height: 1.3;
  ${media.xl`
    font-size: ${fontSize.f8};
  `}
  ${media.md`
    font-size: ${fontSize.f7};
    margin-bottom: 32px;
  `}
  ${media.sm`
    font-size: ${fontSize.f6};
  `}
`

const Small = styled.div`
  font-size: ${fontSize.f7};
  font-weight: normal;
  color: ${color.grey900};
  margin-top: 4px;
  ${media.md`
    font-size: ${fontSize.f6};
  `}
  ${media.sm`
    font-size: ${fontSize.f5};
  `}
`

const H2 = styled.h2`
  font-size: ${fontSize.f4};
  margin-top: 64px;
  ${media.md`
    margin-top: 32px;
    margin-bottom: 8px;
  `}
`

const TextAndImage = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 32px;
  justify-content: space-between;
  grid-template-rows: auto;
  margin-bottom: 2rem;
  ${media.md`
    grid-template-columns: 1fr 1fr;
  `}
  ${media.sm`
    display: block;
  `}
`

const Text = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
`

const HeroImage = styled.div`
  background: url(${KimFaxChatUrl});
  height: 450px;
  width: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;

  ${media.sm`
    height: 200px;
    margin-bottom: 20px;
  `}
`

const Bullet = styled.span``

const CTAButton = styled(Button)`
  margin: 0 16px 8px 0;
  ${media.sm`
    width: 100%;
  `}
`

const Hero = props => {
  return (
    <Section noBottomPadding>
      <TextAndImage>
        <HeroImage />
        <Text>
          <H1>
            Der sichere All-in-One Messenger
            <Small>für Ärzte, Einrichtungen und Patienten</Small>
          </H1>
          <CTAButton onClick={props.onClick}>Infos anfordern</CTAButton>
          <Link to="/order/plan">
            <CTAButton buttonType="darkOutline">Jetzt ausprobieren</CTAButton>
          </Link>
          <H2>Sämtliche Kommunikation an einem Ort</H2>
          <List
            listStyleImage={ListStyleImageUrl}
            narrow
            items={[
              <Bullet key="listEntry1">
                <em>DSGVO-konform</em> und abrechenbar
              </Bullet>,
              <Bullet key="listEntry2">
                <em>KIM-E-Mail, sicherer Chat und Fax</em> – optional inkl.
                KIM-Adresse(n)
              </Bullet>,
              <Bullet key="listEntry3">
                Mit <em>Patienten, Kollegen</em>, Krankenhäusern, Behörden und
                anderen Einrichtungen
              </Bullet>
            ]}
          />
        </Text>
      </TextAndImage>
    </Section>
  )
}

Hero.propTypes = {
  onClick: PropTypes.func
}

export default Hero
