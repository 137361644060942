import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationDE from 'locales/de.json'
import translationEN from 'locales/en.json'

const resources = {
  de: {
    translation: translationDE
  },
  en: {
    translation: translationEN
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      // order and from where user language should be detected
      order: ['htmlTag', 'path'],

      // keys or params to lookup language from
      lookupFromPathIndex: 0
    },
    resources,
    fallbackLng: 'de',

    keySeparator: '.',

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'em']
    }
  })

export default i18n
