import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { useTranslation, Trans } from 'react-i18next'

import Link from 'components/link'
import Section from 'components/section'
import NoWrap from 'components/noWrap'
import Button from 'components/button'

const CTAContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const ButtonWrapper = styled.div`
  margin: 40px 0 0 0;
  ${media.sm`
    margin: 8px 0 0 0;
  `}
`

const OrderLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  text-decoration: none;
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`

const Text = styled.div`
  max-width: 600px;
`

const OrderNow = props => {
  const { t } = useTranslation()
  return (
    <Section background={props.background}>
      <CTAContainer>
        <h2>{t('pages.hartmannbund.orderNow.title')}</h2>
        <Text>
          <Trans i18nKey="pages.hartmannbund.orderNow.text1" />
          <NoWrap>
            <Trans i18nKey="pages.hartmannbund.orderNow.text2" />
          </NoWrap>
          <Trans i18nKey="pages.hartmannbund.orderNow.text3" />
        </Text>
        <ButtonWrapper>
          <OrderLink to="/order">
            <Button buttonType="dark">
              {t('pages.hartmannbund.orderNow.button')}
            </Button>
          </OrderLink>
        </ButtonWrapper>
      </CTAContainer>
    </Section>
  )
}

OrderNow.propTypes = {
  background: PropTypes.string
}

export default OrderNow
