import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import media from 'utils/media-queries'

import { color } from 'styles/theme'

import Icon from 'components/icons'
import Section from 'components/section'
import Subtitle from 'components/subtitle'

const Center = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  ${media.sm`
    text-align: left;
  `}
`

const TextWrapper = styled.div`
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.sm`
    padding: 0;
    align-items: flex-start;
  `}
`

const Text = styled.div`
  max-width: 800px;
`

const CompareIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`
const CompareTable = styled.table`
  hyphens: auto;
  width: 100%;
  border-collapse: separate;
  margin-top: 64px;
  ${media.xl`
    margin-top: 40px;
  `}
  ${media.sm`
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  `}
`

const Tr = styled.tr`
  height: 80px;
  text-align: left;
  &:nth-child(even) {
    background: ${color.grey50};
    border-radius: 10px;
  }
`

const Th = styled.th`
  border-right: 1px solid ${color.grey100};
  text-align: center;
  font-weight: normal;
  padding: 32px 32px 20px 32px;
  ${media.xl`
    padding: 16px 16px 12px 16px;
  `}
  ${media.sm`
    padding: 16px 8px 12px 8px;
  `}
  &:nth-of-type(3) {
    ${media.lg`
      border: none;
    `}
  }
  &:last-child {
    border: none;
    font-weight: 900;
    ${media.lg`
      display: none;
    `}
  }
`

const H4 = styled.h4`
  font-weight: normal;
`

const Td = styled.td`
  padding: 32px 80px;
  border-right: 1px solid ${color.grey100};
  ${media.xl`
    padding: 16px 80px;
  `}
  ${media.lg`
    padding: 16px 32px;
  `}
  ${media.md`
    padding: 16px 32px;
  `}
  ${media.sm`
    padding: 16px 16px;
  `}
  &:nth-of-type(2) {
    width: 200px;
    ${media.lg`
      width: 120px;
    `}
  }
  &:nth-of-type(3) {
    position: relative;
    width: 200px;
    ${media.lg`
      width: 120px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border: none;
    `}
    &:before {
      box-shadow: -40px 0 40px -40px inset ${color.grey150};
      content: ' ';
      height: 100%;
      top: 0;
      left: -40px;
      position: absolute;
      width: 40px;
    }
    &:after {
      box-shadow: 40px 0 40px -40px inset ${color.grey150};
      content: ' ';
      height: 100%;
      top: 0;
      right: -40px;
      position: absolute;
      width: 40px;
      ${media.lg`
        display: none;
      `}
    }
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 32px 70px 32px 0;
    border: none;
    ${media.lg`
      display: none;
    `}
  }
`

const Advantages = props => {
  const { t } = useTranslation()
  return (
    <Section background={props.background}>
      <Center>
        <TextWrapper>
          <Subtitle>{t('contentSections.advantages.subtitle')}</Subtitle>
          <h2>{t('contentSections.advantages.title')}</h2>
          <Text>
            <p>{t('contentSections.advantages.text')}</p>
          </Text>
        </TextWrapper>
        <CompareTable>
          <tbody>
            <Tr>
              <Th />
              <Th>
                <H4>
                  <Trans i18nKey="contentSections.advantages.col1Heading" />
                </H4>
              </Th>
              <Th>
                <h4>
                  <Trans i18nKey="contentSections.advantages.col2Heading" />
                </h4>
              </Th>
              <Th />
            </Tr>
            <Tr>
              <Td>
                <h4>{t('contentSections.advantages.row1Heading')}</h4>
                {t('contentSections.advantages.row1Text')}
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <h4>{t('contentSections.advantages.row2Heading')}</h4>
                {t('contentSections.advantages.row2Text')}
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <h4>{t('contentSections.advantages.row3Heading')}</h4>
                {t('contentSections.advantages.row3Text')}
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <h4>{t('contentSections.advantages.row4Heading')}</h4>
                {t('contentSections.advantages.row4Text')}
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <h4>{t('contentSections.advantages.row5Heading')}</h4>
                {t('contentSections.advantages.row5Text')}
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
          </tbody>
        </CompareTable>
      </Center>
    </Section>
  )
}

Advantages.propTypes = {
  background: PropTypes.string
}

export default Advantages
