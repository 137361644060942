import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { fontSize, shadow } from 'styles/theme'

export const StyledTeamMember = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

export const Avatar = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 80px;
  background-image: url(${props => props.image});
  filter: ${props =>
    props.applyFilter ? 'contrast(60%) brightness(120%)' : ''};
  background-position: top center;
  background-size: cover;
  margin-bottom: 16px;
  box-shadow: ${shadow.s0};
  ${media.sm`
    width: 120px;
    height: 120px;
    margin-bottom: 8px;
  `}
`

export const Name = styled.div`
  font-weight: 900;
  margin: 16px 0 4px 0;
`

export const Role = styled.div`
  font-size: ${fontSize.f3};
`

const TeamMember = props => {
  return (
    <StyledTeamMember>
      <Avatar image={props.image} applyFilter={props.applyFilter} />
      <Name>{props.name}</Name>
      <Role>{props.role}</Role>
    </StyledTeamMember>
  )
}

TeamMember.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  image: PropTypes.string,
  applyFilter: PropTypes.bool
}

export default TeamMember
