import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'

import References from 'components/references'

import DrKrollnerUrl from 'img/krollner.jpg'
import DrTrettinUrl from 'img/trettin.jpg'
import DrHeidrichUrl from 'img/heidrich.jpg'

const CustomReferences = props => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <References
        background={props.background}
        noBottomPadding={props.noBottomPadding}
      >
        <References.Reference
          avatar={DrKrollnerUrl}
          text={t('contentSections.references.text1')}
          name={t('contentSections.references.name1')}
          role={t('contentSections.references.role1')}
        />
        <References.Reference
          avatar={DrTrettinUrl}
          text={t('contentSections.references.text2')}
          name={t('contentSections.references.name2')}
          role={t('contentSections.references.role2')}
        />
        <References.Reference
          avatar={DrHeidrichUrl}
          text={t('contentSections.references.text3')}
          name={t('contentSections.references.name3')}
          role={
            <Fragment>
              <Trans i18nKey="contentSections.references.role3" />
            </Fragment>
          }
        />
      </References>
    </Fragment>
  )
}

CustomReferences.propTypes = {
  background: PropTypes.string,
  noBottomPadding: PropTypes.bool
}

export default CustomReferences
