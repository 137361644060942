import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

const StyledSpan = styled.span`
  white-space: nowrap;
  ${media.sm`
    white-space: normal;
  `}
`

const NoWrap = props => {
  return <StyledSpan>{props.children}</StyledSpan>
}

NoWrap.propTypes = {
  children: PropTypes.node
}

export default NoWrap
