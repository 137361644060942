import React, { Fragment } from 'react'

import { color } from 'styles/theme'

import Dialog from 'components/dialog/'
import Header from './header'
import Hero from './hero'
import DecentralSolution from 'components/contentSections/decentralSolution'
import Advantages from 'components/contentSections/advantages'
import Security from 'components/contentSections/security'
import Video from 'components/contentSections/video'
import OrderNow from './orderNow.js'
import References from 'components/contentSections/references'
import CallToAction from './callToAction'
import Footer from 'components/footer'

import { googleAnalyticsTracker } from 'utils/tracking'

class ReactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false
    }
  }

  openDialog = e => {
    googleAnalyticsTracker.trackCTAButtonClick(e)
    this.setState({ dialogIsOpen: true })
  }

  closeDialog = () => {
    this.setState({ dialogIsOpen: false })
  }

  render() {
    return (
      <Fragment>
        <Dialog
          isOpen={this.state.dialogIsOpen}
          closeDialog={this.closeDialog}
        />
        <Header />
        <Hero onClick={this.openDialog} />
        <Video background={color.grey50} />
        <OrderNow background={color.blue500} />
        <DecentralSolution background={color.grey50} />
        <Advantages />
        <Security background={color.grey50} />
        <References noBottomPadding />
        <CallToAction onClick={this.openDialog} />
        <Footer noTopPadding />
      </Fragment>
    )
  }
}
export default ReactPage
