import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color, shadow } from 'styles/theme'
import media from 'utils/media-queries'

import Link from 'components/link'

const StyledLink = styled(Link)`
  :hover {
    color: ${color.grey900};
  }
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
  padding: 40px 80px;
  ${media.md`
    padding: 40px 40px;
  `}
  ${media.sm`
    padding: 32px 0;  
  `}
`

const StyledPressRelease = styled.li`
  width: 100%;
  margin: 0 !important;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  border-top: 1px solid ${color.grey150};
  border-radius: 0px;
  cursor: pointer;
  :hover {
    box-shadow: ${shadow.s0};
    border-top: 1px solid transparent;
    border-radius: 10px;
    & + ul {
      border-top: 1px solid transparent;
    }
  }
  transition: box-shadow 0.3s, border 0.3s;
  &:first-of-type {
    border-top: none;
  }
  & + :last-of-type {
    border-bottom: 1px solid ${color.grey150};
    :hover {
      border-bottom: 1px solid transparent;
    }
  }
  ${media.sm`
    :hover {
      box-shadow: none;
      border-radius: none;
    }
  `}
`
const Date = styled.div`
  margin-bottom: 24px;
`

const Title = styled.h2``

const Preview = styled.div``

const PressRelease = props => {
  return (
    <StyledPressRelease>
      <StyledLink to={props.route}>
        <Date>{props.date}</Date>
        <Title>{props.title}</Title>
        <Preview>{props.preview}</Preview>
      </StyledLink>
    </StyledPressRelease>
  )
}

PressRelease.propTypes = {
  date: PropTypes.string,
  preview: PropTypes.string,
  title: PropTypes.string,
  route: PropTypes.string
}

export default PressRelease
