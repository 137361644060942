import React from 'react'
import styled from 'styled-components'

import Link from 'components/link'

import { color } from 'styles/theme'

import LogoUrl from 'img/logo.svg'

const Section = styled.section`
  height: 80px;
  border-bottom: 1px solid ${color.grey150};
  display: flex;
  justify-content: center;
  align-items: center;
`

const CenteredLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = () => {
  return (
    <Section>
      <CenteredLink to="/">
        <img src={LogoUrl} />
      </CenteredLink>
    </Section>
  )
}

export default Header
