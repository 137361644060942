import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import media from 'utils/media-queries'

import Link from 'components/link'
import Icon from 'components/icons'
import Section from 'components/section'
import CTAContainer from 'components/ctaContainer'
import Button from 'components/button'
import { SalesPhone } from 'components/contactInformation'

const LineBreak = styled.span`
  ${media.md`
    display: none;
  `}
`

const CTAInfo = styled.div``

const Ul = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
`

const Li = styled.li`
  display: flex;
  align-items: center;
`

const ListItemText = styled.span`
  padding-left: 8px;
  padding-top: 4px;
  text-align: left;
`

const ButtonWrapper = styled.div`
  margin: 16px 0;
  ${media.sm`
    margin: 24px 0;
  `}
`

const CTAButton = styled(Button)`
  margin: 8px 16px;
`

const PhoneWrapper = styled.div`
  a {
    font-weight: 900;
    color: white;
    &:visited {
      color: white;
    }
    &:hover {
      color: white;
    }
    &:active {
      color: white;
    }
  }
`

const CallToAction = () => {
  const { t } = useTranslation()
  return (
    <Section>
      <CTAContainer>
        <h2>
          {t('contentSections.callToAction.title1')}
          <LineBreak>
            <br />
          </LineBreak>
          {t('contentSections.callToAction.title2')}
        </h2>
        <CTAInfo>
          <Ul>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>Intuitive Benutzeroberfläche</ListItemText>
            </Li>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>
                {t('contentSections.callToAction.listItem2')}
              </ListItemText>
            </Li>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>
                {t('contentSections.callToAction.listItem3')}
              </ListItemText>
            </Li>
          </Ul>
        </CTAInfo>
        <ButtonWrapper>
          <Link to="/order">
            <CTAButton buttonType="dark">
              Jetzt kostenfrei installieren
            </CTAButton>
          </Link>
        </ButtonWrapper>
        <Trans i18nKey="contentSections.callToAction.call" />
        <PhoneWrapper>
          <SalesPhone />
        </PhoneWrapper>
      </CTAContainer>
    </Section>
  )
}

CallToAction.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default CallToAction
