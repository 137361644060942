import React from 'react'
import styled from 'styled-components'

import Link from 'components/link'

const StyledPrivacyNav = styled.div`
  margin-bottom: 40px;
`

const PrivacyNav = () => (
  <StyledPrivacyNav>
    <Link to="/privacypolicy/web">LifeTime Web</Link> |{' '}
    <Link to="/privacypolicy/app">LifeTime App</Link> |{' '}
    <Link to="/privacypolicy/software">LifeTime Software</Link>
  </StyledPrivacyNav>
)

export default PrivacyNav
