import Button from 'components/button'
import Section from 'components/section'
import KimUrl from 'img/kim.jpg'
import ListStyleImageUrl from 'img/list-style-image.svg'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { color, fontSize } from 'styles/theme'
import media from 'utils/media-queries'
import Card from 'components/card'
import PraxisnetzLogoUrl from 'img/praxisnetz-kiel.jpg'
import List from 'components/list'

const TeaserText = styled.div`
  p {
    font-size: ${fontSize.f5};
  }
  max-width: 800px;
  margin-bottom: 2rem;
`

const H1 = styled.h1`
  max-width: 900px;
  line-height: 1.3;
  font-size: ${fontSize.f9};
  ${media.md`
    font-size: ${fontSize.f8};
  `}
  ${media.sm`
    font-size: ${fontSize.f7};
  `}
`

const Blue = styled.span`
  color: ${color.blue500};
`

const StyledImg = styled.div`
  background: url(${KimUrl});
  margin-top: 72px;
  position: relative;
  width: 100%;
  height: 600px;
  max-width: 100%;
  max-height: 800px;
  background-size: cover;
  ${media.md`
    margin-top: 64px;
    max-height: 400px;
    background-size: 150%;
    background-position: 0% 0%;
    background-repeat: no-repeat;
  `}
  ${media.sm`
    margin-top: 64px;
    max-height: 400px;
    background-size: 200%;
  `}
`

const StyledQuotation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
  text-align: center;
  border: 1px solid ${color.grey200};
  border-radius: 20px;
  padding: 2rem;
  ${media.sm`
    margin-top: 40px;
  `}
`

const QuotationWrapper = styled.div`
  max-width: 800px;
  display: flex;
  ${media.sm`
    flex-direction: column;
    align-items: center;
  `}
`

const QuotationContent = styled.div`
  font-size: ${fontSize.f5};
  line-height: 1.3;
  ${media.md`
    font-size: ${fontSize.f4};
  `}
  ${media.sm`
    font-size: ${fontSize.f3};
  `}
`

const QuotationText = styled.div`
  font-style: italic;
`

const QuotationName = styled.div`
  font-weight: 900;
  margin-top: 32px;
`

const QuotationRole = styled.div``

const PraxisnetzLogoSmall = styled.img`
  padding-right: 80px;
  max-height: 250px;
  max-width: 350px;
  object-fit: contain;
  ${media.md`
    padding-right: 0;
    padding-bottom: 80px;
  `}
  ${media.sm`
    max-height: 220px;
    padding-bottom: 40px;
  `}
`

const Advantages = styled.div`
  text-align: left;
`

const Hero = props => {
  return (
    <Section centerText background={props.background} noBottomPadding>
      <H1>
        <Blue>(KIM-)Nachrichten</Blue> <br /> sicher an Ärzte und Patienten
        senden
      </H1>
      <TeaserText>
        <p>Die sichere Kommunikationsplattform für das Medizinwesen.</p>
        <p>
          Senden Sie mit <em>unbegrenztem Datenvolumen</em> TI-KIM-E-Mails,
          Dokumente und sichere Chatnachrichten an Ärzte, medizinische
          Einrichtungen und Patienten.
        </p>
      </TeaserText>
      <a href="/order/plan">
        <Button type="button">Jetzt bestellen</Button>
      </a>
      <Card>
        <PraxisnetzLogoSmall src={PraxisnetzLogoUrl} />
        <Advantages>
          <h3>Ihre Vorteile als Mitglied des PRAXISNETZ Kiel e.V.:</h3>
          <List
            listStyleImage={ListStyleImageUrl}
            items={[
              '20% Rabatt auf monatl. Abos (Gutschein: PNKiel21)',
              'Kostenübernahme für KIM in ersten Jahr',
              'Premium-Support'
            ]}
          />
        </Advantages>
      </Card>
      <StyledImg />
      <StyledQuotation>
        <QuotationWrapper>
          <QuotationContent>
            <QuotationText>
              „Durch die Kooperation mit Lifetime freuen wir uns, unsere
              Mitglieder in den Themen KIM und Digitalisierung unterstützen zu
              können.“
            </QuotationText>
            <QuotationName>
              <em>Lars Prinzhorn</em>
            </QuotationName>
            <QuotationRole>Geschäftsführer PRAXISNETZ Kiel e.V.</QuotationRole>
          </QuotationContent>
        </QuotationWrapper>
      </StyledQuotation>
    </Section>
  )
}

Hero.propTypes = {
  background: PropTypes.string,
  onClick: PropTypes.func
}

export default Hero
