import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'

import TextWithIllustration from 'components/textWithIllustration'
import NoWrap from 'components/noWrap'

import DecentralIllustrationUrl from 'img/decentral-illustration.svg'

const DecentralSolution = props => {
  const { t } = useTranslation()
  return (
    <TextWithIllustration
      subtitle={t('contentSections.decentralSolution.subtitle')}
      title={
        <Fragment>
          {t('contentSections.decentralSolution.title1')}
          <NoWrap>{t('contentSections.decentralSolution.title2')}</NoWrap>
        </Fragment>
      }
      illustrationUrl={DecentralIllustrationUrl}
      background={props.background}
      smallIllustration
    >
      <Trans i18nKey="contentSections.decentralSolution.text" />
    </TextWithIllustration>
  )
}

DecentralSolution.propTypes = {
  background: PropTypes.string
}

export default DecentralSolution
