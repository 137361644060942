import React from 'react'
import styled, { keyframes } from 'styled-components'

const OuterSpinnerCircle = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  box-sizing: content-box;
  position: relative;
  top: 3px;
  left: -12px;
  right: 0;
  bottom: 0;
  display: inline-block;
  margin-right: 8px;
`
const Rotate = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`

const InnerSpinnerCircle = styled.div`
  width: 100%;
  height: 100%;
  border-right: 2px solid white;
  border-left: 2px solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-radius: 50%;
  box-sizing: content-box;
  position: relative;
  top: -2px;
  left: -2px;
  right: 0;
  bottom: 0;
  animation: ${Rotate} 1.5s linear infinite;
`

const SubmitSpinner = () => (
  <OuterSpinnerCircle>
    <InnerSpinnerCircle />
  </OuterSpinnerCircle>
)

export default SubmitSpinner
