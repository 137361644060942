import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/icons'

import Link from 'components/link'

import { color } from 'styles/theme'

const StyledWarningBanner = styled.section`
  display: ${props => (props.visible ? 'flex' : 'none')};
  text-align: center;
  width: 100%;
  background: #fffcd8;
  align-items: center;
  justify-content: center;
  height: 80px;
  color: white;
  padding: 0 16px;
`

const StyledInfoBanner = styled.section`
  display: ${props => (props.visible ? 'flex' : 'none')};
  text-align: center;
  width: 100%;
  background: ${color.blue100};
  align-items: center;
  justify-content: center;
  height: 64px;
  color: ${color.blue800};
  padding: 0 16px;
`

const InfoIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`

const NotificationWarning = styled.div`
  background: #ef4343;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotificationInfo = styled.div`
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinkWithoutUnderline = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  :active {
    text-decoration: none;
  }
  :visited {
    text-decoration: none;
  }
`

const Banner = props => {
  return (
    <LinkWithoutUnderline to={props.to}>
      <StyledWarningBanner visible={props.notificationType === 'warning'}>
        <NotificationWarning>
          <InfoIcon>
            <Icon glyph="info" color="white" size={20} />
          </InfoIcon>
          {props.children}
        </NotificationWarning>
      </StyledWarningBanner>
      <StyledInfoBanner visible={props.notificationType === 'info'}>
        <NotificationInfo>
          <InfoIcon>
            <Icon glyph="info" color={color.blue800} size={20} />
          </InfoIcon>
          {props.children}
        </NotificationInfo>
      </StyledInfoBanner>
    </LinkWithoutUnderline>
  )
}

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  notificationType: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
}

export default Banner
