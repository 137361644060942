export const googleAnalyticsTracker = {
  trackCTAButtonClick: () => {},
  trackRequestInformation: () => {}
}

// ---------- Google Analytics----------

googleAnalyticsTracker.trackCTAButtonClick = e => {
  if (typeof GoogleAnalytics !== 'undefined') {
    // eslint-disable-next-line no-undef
    GoogleAnalytics.trackCTAButtonClick('open_overlay', e.target.innerText)
  }
}

googleAnalyticsTracker.trackRequestInformation = () => {
  if (typeof GoogleAnalytics !== 'undefined') {
    // eslint-disable-next-line no-undef
    GoogleAnalytics.trackRequestInformation()
  }
}
