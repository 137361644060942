import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import HeroIllustrationUrl from 'img/hero-illustration.svg'

const StyledImg = styled.img`
  margin-top: 112px;
  max-width: 100%;
  max-height: 600px;
  height: auto;
  ${media.xl`
    margin-top: 56px;
  `}
  ${media.sm`
    margin-top: 64px;
  `}
`

const Illustration = () => {
  return <StyledImg src={HeroIllustrationUrl} />
}

export default Illustration
