import React, { Fragment } from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import Footer from 'components/footer'
import Nav from 'components/nav'
import PlainText from 'components/plainText'
import Section from 'components/section'

import Button from 'components/button'
import ExternalLinkButtonIcon from '../../img/external-link-button.svg'

const ButtonWrapper = styled.div`
  margin: 40px 0 0 0;
  ${media.sm`
    margin: 8px 0 0 0;
  `}
`

const LinkExternal = styled.a`
  display: block;
  color: white;
  text-decoration: none;
  :active {
    color: white;
  }
  :hover {
    color: white;
  }
  :visited {
    color: white;
  }
  &:after {
    content: '';
    display: inline-block;
    background: url(${ExternalLinkButtonIcon}) no-repeat;
    width: 20px;
    height: 20px;
    background-size: contain;
    bottom: -4px;
    left: 8px;
    position: relative;
  }
`

const ReactPage = () => {
  return (
    <Fragment>
      <Nav pageType="neutral" />
      <Section size="md">
        <h1>IT-Job gesucht?</h1>
        <h2>Wir stellen ein.</h2>
        <PlainText>
          Hinter LifeTime steht AKQUINET, ein IT-Unternehmen mit Hauptsitz in
          Hamburg. Wir bieten umfassende IT-Infrastrukturservices für das
          digitale Gesundheitswesen wie TI as a Service, oder TI-Fachdienste wie
          KIM und TIM für Kliniken, Praxen und Pflegeeinrichtungen an. Das
          persönliche Miteinander und flache Hierarchien zeichnen unsere Kultur
          aus. Du hast Interesse? Dann werde jetzt Teil unseres Teams.
        </PlainText>
        <ButtonWrapper>
          <Button>
            <LinkExternal href="https://karriere.akquinet.de/teams/health-service.html">
              Zu den Jobs
            </LinkExternal>
          </Button>
        </ButtonWrapper>
      </Section>
      <Footer withBorder />
    </Fragment>
  )
}

export default ReactPage
