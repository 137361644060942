import TextWithIllustration from 'components/textWithIllustration'
import ExternalLinkIcon from 'img/external-link.svg'
import KBVUrl from 'img/kbv-logo.png'
import PaymentIllustrationUrl from 'img/payment-illustration.svg'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { color, fontSize } from 'styles/theme'
import media from 'utils/media-queries'

const Ul = styled.ul`
  padding-inline-start: 0;
  margin: 0 0 16px 0;
  li {
    margin-bottom: 8px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
`

const Card = styled.div`
  font-size: ${fontSize.f2};
  border: 1px solid ${color.grey200};
  border-radius: 10px;
  margin-top: 20px;
  padding: 40px 32px 40px 56px;
  width: 100%;
  ${media.sm`
    padding: 20px 24px 20px 32px;
  `}
`

const LinkExternal = styled.a`
  display: block;
  &:after {
    content: '';
    display: inline-block;
    background: url(${ExternalLinkIcon}) no-repeat;
    width: 16px;
    height: 16px;
    background-size: contain;
    bottom: -3px;
    left: 8px;
    position: relative;
    margin-right: -16px;
  }
`

const Img = styled.img`
  display: block;
  margin-bottom: 16px;
`

const Reimbursement = props => {
  return (
    <TextWithIllustration
      subtitle="Abrechenbare Leistungen"
      title="Erhalten Sie Rückvergütungen"
      illustrationUrl={PaymentIllustrationUrl}
      background={props.background}
      smallIllustration
      noBottomPadding={props.noBottomPadding}
      id="reimbursement"
    >
      <p>
        Mit {props.brandName} erfüllen Sie alle Anforderungen, um signierte
        eArztbriefe und andere Dokumente <em>abrechenbar zu versenden</em>,
        sowie Betriebskostenerstattungen und Zuschüsse zu erhalten.
      </p>
      <Card>
        <Ul>
          <li>
            <em>10,99 ct</em> je versendetem eArztbrief-Anhang (GOP 01660) - bis
            2023 extrabudgetär und ohne Limit
          </li>
          <li>
            <em>28 ct</em> je versendetem (GOP 86900) bzw. 27 ct pro empfangenen
            (GOP 86901) eArztbrief-Anhang - gedeckelt auf 23,40 € pro Quartal
          </li>
          <li>
            <em>23,40 €</em> pro Quartal KIM Betriebskostenpauschale pro Praxis
          </li>
          <li>
            <em>10 ct</em> je versendetem Arztbrief per Fax (GOP 40111) -
            arztgruppenspezifisch gedeckelt
          </li>
          <li>
            <em>100 €</em> einmaliger Einrichtungs-Zuschlag
          </li>
        </Ul>
        <LinkExternal href="https://www.kbv.de/html/1150_45717.php">
          <Img src={KBVUrl} />
          Mehr Infos (Kassenärztliche Bundesvereinigung)
        </LinkExternal>
      </Card>
    </TextWithIllustration>
  )
}

Reimbursement.propTypes = {
  background: PropTypes.string,
  brandName: PropTypes.string,
  noBottomPadding: PropTypes.bool
}

Reimbursement.defaultProps = {
  brandName: 'LifeTime'
}

export default Reimbursement
