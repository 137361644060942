import Button from 'components/button'
import PropTypes from 'prop-types'
import { SalesPhone } from 'components/contactInformation'
import CTAContainer from 'components/ctaContainer'
import Icon from 'components/icons'
// import Link from 'components/link'
import Section from 'components/section'
import React from 'react'
import styled from 'styled-components'
import { fontSize } from 'styles/theme'
import media from 'utils/media-queries'

const CTAInfo = styled.div``

const Price = styled.span`
  font-size: ${fontSize.f6};
  font-weight: 900;
  ${media.xl`
    font-size: ${fontSize.f5};
  `}
  ${media.md`
    font-size: ${fontSize.f4};
  `}
  ${media.sm`
    font-size: ${fontSize.f3};
  `}
`

const PriceWrapper = styled.div`
  text-align: left;
  max-width: 400px;
  ${media.sm`
    text-align: center;
  `}
`

const Ul = styled.ul`
  padding-left: 0;
`

const Li = styled.li`
  display: flex;
  align-items: center;
`

const ListItemText = styled.span`
  padding-left: 8px;
  padding-top: 4px;
  text-align: left;
`

const ButtonWrapper = styled.div`
  margin: 40px 0;
  ${media.sm`
    margin: 8px 0 24px 0;
  `}
`

const PhoneWrapper = styled.div`
  a {
    font-weight: 900;
    color: white;
    &:visited {
      color: white;
    }
    &:hover {
      color: white;
    }
    &:active {
      color: white;
    }
  }
`

const CallToAction = props => {
  return (
    <Section>
      <CTAContainer>
        <h2>Legen Sie jetzt direkt los</h2>
        <CTAInfo>
          <PriceWrapper>
            <Price>Ab 8,- € </Price>
            <span>/ Monat zzgl. MwSt. </span>
            <br />
            <br />
            <p>
              20% Rabatt auf monatl. Abos für Mitglieder des PRAXISNETZ Kiel
              e.V. (Gutschein <em>PNKiel21</em>)
            </p>
            <br />
            <p>Im Angebot enthalten:</p>
          </PriceWrapper>
          <Ul>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>
                Eine KIM-Adresse (weitere gegen Aufpreis)
              </ListItemText>
            </Li>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>Unbegrenzte Anzahl von Nachrichten</ListItemText>
            </Li>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>Unbegrenztes Datenvolumen</ListItemText>
            </Li>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>
                Rückerstattung der Kosten für KIM im ersten Jahr durch das
                PRAXISNETZ Kiel e.V.
              </ListItemText>
            </Li>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>Schneller Support</ListItemText>
            </Li>
          </Ul>
        </CTAInfo>
        <ButtonWrapper>
          <a href="/order/plan">
            <Button buttonType="dark">Jetzt bestellen</Button>
          </a>
        </ButtonWrapper>
        Weitere Infos erhalten Sie unter:
        <PhoneWrapper>
          <SalesPhone />
        </PhoneWrapper>
      </CTAContainer>
    </Section>
  )
}

CallToAction.propTypes = {
  onClick: PropTypes.func
}

export default CallToAction
