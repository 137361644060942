import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { color, fontSize } from 'styles/theme'

const StyledSubtitle = styled.div`
  font-size: ${fontSize.f5};
  color: ${color.grey400};
  font-weight: 900;
  margin-bottom: 16px;
  ${media.sm`
    font-size: ${fontSize.f4};
  `}
`

const SubTitle = props => {
  return <StyledSubtitle>{props.children}</StyledSubtitle>
}

SubTitle.propTypes = {
  children: PropTypes.node.isRequired
}

export default SubTitle
