import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { color, fontSize, shadow } from 'styles/theme'

const StyledQuotation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 112px;
  ${media.xl`
    margin-top: 80px;
  `}
  ${media.sm`
    margin-top: 40px;
  `}
`

const QuotationWrapper = styled.div`
  max-width: 800px;
  text-align: left;
  display: flex;
  ${media.sm`
    flex-direction: column;
    align-items: center;
  `}
`

const QuotationAvatar = styled.div`
  width: 192px;
  height: 192px;
  border-radius: 96px;
  background: ${color.grey100};
  box-shadow: ${shadow.s2};
  background-image: url(${props => props.avatar});
  background-repeat: no-repeat;
  background-size: contain;
  ${media.sm`
    margin-bottom: 32px;
    width: 120px;
    height: 120px;
  `}
`

const QuotationTextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 64px;
  ${media.sm`
    margin: 0;
    text-align: center;
  `}
`

const QuotationContent = styled.div`
  font-size: ${fontSize.f5};
  line-height: 1.3;
  ${media.md`
    font-size: ${fontSize.f4};
  `}
  ${media.sm`
    font-size: ${fontSize.f3};
  `}
`

const QuotationText = styled.div`
  font-style: italic;
`

const QuotationName = styled.div`
  font-weight: 900;
  margin-top: 32px;
`

const QuotationRole = styled.div``

const Quotation = props => {
  return (
    <StyledQuotation>
      <QuotationWrapper>
        <QuotationAvatar avatar={props.avatar} />
        <QuotationTextWrapper>
          <QuotationContent>
            <QuotationText>{props.text}</QuotationText>
            <QuotationName>
              <em>{props.name}</em>
            </QuotationName>
            <QuotationRole>{props.role}</QuotationRole>
          </QuotationContent>
        </QuotationTextWrapper>
      </QuotationWrapper>
    </StyledQuotation>
  )
}

Quotation.propTypes = {
  avatar: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
}

export default Quotation
