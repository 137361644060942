import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import Link from 'components/link'
import { color } from 'styles/theme'

import Section from 'components/section'
import Text from 'components/hero/text'
import Button from 'components/button'
import Logos from 'components/logos'
import ScreenshotSrc from 'img/screenshot-inbox.svg'

const H1 = styled.h1`
  max-width: 760px;
`

const Blue = styled.span`
  color: ${color.blue500};
`

const StyledImg = styled.div`
  background: url(${ScreenshotSrc});
  margin-top: 24px;
  position: relative;
  width: 100%;
  height: 600px;
  max-width: 100%;
  max-height: 800px;
  background-size: cover;
  ${media.xl`
    margin-top: 56px;
  `}
  ${media.sm`
    margin-top: 64px;
    max-height: 400px;
  `}
`

const OrderLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  text-decoration: none;
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`

const Hero = props => {
  return (
    <div>
      <Section centerText>
        <H1>
          Dokumente <Blue>kostenfrei</Blue> in Originalqualität empfangen
        </H1>
        <Text>
          Die einfachste und <em>datenschutzkonforme</em> Versandlösung für
          Arztpraxen - empfangen Sie Dokumente von Ihren Kollegen jetzt
          kostenfrei direkt auf Ihrem Rechner.
        </Text>
        <OrderLink to="/order">
          <Button type="button" onClick={props.onClick}>
            Jetzt installieren
          </Button>
        </OrderLink>
        <StyledImg />
        <Logos />
      </Section>
    </div>
  )
}

Hero.propTypes = {
  onClick: PropTypes.func
}

export default Hero
