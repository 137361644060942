import React, { Fragment } from 'react'
import styled from 'styled-components'

import Footer from 'components/footer'
import Link from 'components/link'
import Nav from 'components/nav'
import PlainText from 'components/plainText'
import Section from 'components/section'
import PressRelease from './pressRelease'
import Download from './download'

// team & contact information
import { TradePressPhone } from 'components/contactInformation'

// downloads
import ProductUrl from './downloads/LifeTime_Produkt.jpg'
import LogoUrl from './downloads/LifeTime_Logo.png'
import SoftwareUrl from './downloads/LifeTime_Software.jpg'
import TransactionsUrl from './downloads/LifeTime_OnePager_Transactions.pdf'
import OnePagerUrl from './downloads/LifeTime_OnePager_Aerzte.pdf'

// download thumbnails 240 x 240px
import ProductTnUrl from './downloads/thumbnails/LifeTime_Produkt_tn.jpg'
import LogoTnUrl from './downloads/thumbnails/LifeTime_Logo_tn.jpg'
import SoftwareTnUrl from './downloads/thumbnails/LifeTime_Software_tn.jpg'
import PdfTnUrl from './downloads/thumbnails/pdf_tn.jpg'

const List = styled.ul`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  width: 100%;
`

const ArchiveLink = styled.div`
  margin: 32px 0;
`

const ContactPersons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 240px));
  grid-column-gap: 56px;
  width: 100%;
  justify-content: center;
  margin: 56px 0 32px 0;
`

const ContactPerson = styled.div`
  text-align: center;
  margin-bottom: 40px;
`

const ReactPage = () => {
  return (
    <Fragment>
      <Nav pageType="neutral" />
      <Section size="md" noBottomPadding>
        <h1>Pressemitteilungen</h1>
        <PlainText>
          <List>
            <PressRelease
              date="27.05.2021"
              title="Sicher und dezentral – Erstellung und Versand digitaler Test- und Impfnachweise mit LifeTime"
              preview="LifeTime entwickelt seinen sicheren Messenger weiter, so dass auch Impf- und Testnachweise (BärCODEs) erstellt und Ende-zu-Ende-verschlüsselt an Smartphones versendet werden können."
              route="/presse/baercode"
            />
          </List>
          <ArchiveLink>
            <Link to="/presse/archiv">Archiv →</Link>
          </ArchiveLink>
        </PlainText>
      </Section>
      <Section size="md" noBottomPadding>
        <h1>Kontakt</h1>
        <ContactPersons>
          <ContactPerson>
            <a href="mailto:presse@lifetime.eu">presse@lifetime.eu</a>
            <br />
            <TradePressPhone />
            <br />
          </ContactPerson>
        </ContactPersons>
      </Section>
      <Section size="md">
        <h1>Downloads</h1>
        <PlainText>
          <List>
            <Download
              title="LifeTime Produkt"
              file={ProductUrl}
              thumbnail={ProductTnUrl}
              fileType="jpg"
            />
            <Download
              title="LifeTime Logo"
              file={LogoUrl}
              thumbnail={LogoTnUrl}
              fileType="png"
            />
            <Download
              title="LifeTime Software-Ansicht"
              file={SoftwareUrl}
              thumbnail={SoftwareTnUrl}
              fileType="jpg"
            />
            <Download
              title="Trend: Digitale Transaktionen"
              file={TransactionsUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="OnePager für Ärzte"
              file={OnePagerUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
          </List>
        </PlainText>
      </Section>
      <Footer withBorder />
    </Fragment>
  )
}

export default ReactPage
