import Icon from 'components/icons'
import Section from 'components/section'
import Subtitle from 'components/subtitle'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { color } from 'styles/theme'
import media from 'utils/media-queries'

const Center = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  ${media.sm`
    text-align: left;
  `}
`

const TextWrapper = styled.div`
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.sm`
    padding: 0;
    align-items: flex-start;
  `}
`

const Text = styled.div`
  max-width: 800px;
`

const CompareIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`
const CompareTable = styled.table`
  hyphens: auto;
  width: 100%;
  border-collapse: separate;
  margin-top: 64px;
  ${media.xl`
    margin-top: 40px;
  `}
  ${media.sm`
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  `}
`

const Tr = styled.tr`
  text-align: left;
  &:nth-child(even) {
    background: ${color.grey50};
    border-radius: 10px;
  }
`

const Th = styled.th`
  border-right: 1px solid ${color.grey100};
  text-align: center;
  font-weight: normal;
  padding: 32px 32px 20px 32px;
  ${media.xl`
    padding: 16px 16px 12px 16px;
  `}
  ${media.sm`
    padding: 16px 8px 12px 8px;
  `}
  &:nth-of-type(3) {
    ${media.lg`
      border: none;
    `}
  }
  &:last-child {
    border: none;
    font-weight: 900;
    ${media.lg`
      display: none;
    `}
  }
`

const H4 = styled.h4`
  font-weight: normal;
  margin-bottom: 0;
`

const Td = styled.td`
  padding: 20px 64px;
  border-right: 1px solid ${color.grey100};
  ${media.xl`
    padding: 16px 64px;
  `}
  ${media.lg`
    padding: 16px 32px;
  `}
  ${media.md`
    padding: 16px 32px;
  `}
  ${media.sm`
    padding: 16px 16px;
  `}
  &:nth-of-type(2) {
    width: 200px;
    ${media.lg`
      width: 120px;
    `}
  }
  &:nth-of-type(3) {
    position: relative;
    width: 200px;
    ${media.lg`
      width: 120px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border: none;
    `}
    &:before {
      box-shadow: -40px 0 40px -40px inset ${color.grey150};
      content: ' ';
      height: 100%;
      top: 0;
      left: -40px;
      position: absolute;
      width: 40px;
    }
    &:after {
      box-shadow: 40px 0 40px -40px inset ${color.grey150};
      content: ' ';
      height: 100%;
      top: 0;
      right: -40px;
      position: absolute;
      width: 40px;
      ${media.lg`
        display: none;
      `}
    }
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 32px 70px 32px 0;
    border: none;
    ${media.lg`
      display: none;
    `}
  }
`

const Advantages = props => {
  return (
    <Section background={props.background} noBottomPadding>
      <Center>
        <TextWrapper>
          <Subtitle>Der Vergleich</Subtitle>
          <h2>LifeTime-KIM kann mehr.</h2>
          <Text>
            <p>
              Erreichen Sie alle Ärzte, Heilberufler, Apotheken, Krankenkassen
              und Patienten über unsere Software{' '}
              <em>per TI-KIM, Fax, E-Mail und sicherem Chat</em>.
            </p>
          </Text>
        </TextWrapper>
        <CompareTable>
          <tbody>
            <Tr>
              <Th />
              <Th>
                <H4>Herkömmliche KIM-Anbieter</H4>
              </Th>
              <Th>
                <H4>LifeTime-KIM</H4>
              </Th>
              <Th />
            </Tr>
            <Tr>
              <Td>
                <H4>KIM-E-Mails senden</H4>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <H4>Integrierte Benutzeroberfläche</H4>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <H4>Unbegrenztes Datenvolumen</H4>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <H4>Kommunikation mit Patienten</H4>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <H4>Kommunikation mit Privatärzten</H4>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <H4>Sicherer Chat</H4>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <H4>Integrierter Fax-Versand</H4>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <H4>Monatlich kündbar</H4>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <H4>Schneller Support per Chat</H4>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'cross'} />
                </CompareIconWrapper>
              </Td>
              <Td>
                <CompareIconWrapper>
                  <Icon glyph={'checkmark'} />
                </CompareIconWrapper>
              </Td>
              <Td />
            </Tr>
          </tbody>
        </CompareTable>
      </Center>
    </Section>
  )
}

Advantages.propTypes = {
  background: PropTypes.string
}

export default Advantages
