import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'

import Section from 'components/section'
import Typewriter from 'components/typewriter'
import NoWrap from 'components/noWrap'
import Text from 'components/hero/text'
import Button from 'components/button'
import Illustration from 'components/hero/illustration'
import Logos from 'components/logos'

const Hero = props => {
  const { t } = useTranslation()
  return (
    <Section centerText>
      <h1>
        <Trans i18nKey="pages.secureTransmission.hero.title1" />
        <Typewriter />
        <Trans i18nKey="pages.secureTransmission.hero.title2" />
      </h1>
      <Text>
        {t('pages.secureTransmission.hero.text1')}
        <NoWrap>{t('pages.secureTransmission.hero.text2')}</NoWrap>
      </Text>
      <Button type="button" onClick={props.onClick}>
        {t('pages.secureTransmission.hero.button')}
      </Button>
      <Illustration />
      <Logos />
    </Section>
  )
}

Hero.propTypes = {
  onClick: PropTypes.func
}

export default Hero
