import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { Trans } from 'react-i18next'

import { color, fontSize } from 'styles/theme'

import i18n from 'components/i18n'
import Icon from 'components/icons'
import Button from 'components/button'
import NoWrap from 'components/noWrap'
import SubmitSpinner from './spinner'
import { SalesPhone } from 'components/contactInformation'

import { googleAnalyticsTracker } from 'utils/tracking'

const StyledDialog = styled.div`
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${props => (props.isOpen ? '1' : '0')};
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
`

const Passepartout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
`

const CenterHorizontally = styled.div`
  max-width: 800px;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: min-content;
  z-index: 100;
  -webkit-transform: translate3d(0, 0, 0);
`

const CloseButton = styled.button`
  background: none;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: absolute;
  top: 22px;
  right: 22px;
`

const DialogWindow = styled.div`
  position: relative;
  padding: 80px;
  margin: 32px 16px 0 16px;
  background: white;
  border-radius: 10px;
  max-width: 800px;
  ${media.lg`
    padding: 64px;
  `}
  ${media.sm`
    padding: 32px;
  `}
`

const FormField = styled.div`
  margin-top: 32px;
  ${media.xl`
    margin-top: 24px;
  `}
  ${media.lg`
    margin-top: 16px;
  `}
  ${media.md`
    grid-column: 1 / 3;
  `}
`

const FormFieldFullWidth = styled(FormField)`
  grid-column: 1 / 3;
`

const SubmitButtonWrapper = styled.div`
  margin-top: 40px;
  justify-self: center;
  grid-column: 1 / 3;
  width: 100%;
`

const SubmitButton = styled(Button)`
  width: 100%;
`

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`

const PrivacyNote = styled.div`
  margin-top: 48px;
  font-size: ${fontSize.f1};
  line-height: 17px;
  color: ${color.grey500};
`

const PrivacyLink = styled.a`
  a {
    color: ${color.grey500};
  }
  & :visited {
    color: ${color.grey500};
  }
`

const ValidatedSelect = styled.select`
  border: ${props =>
    props.isNotValid ? '2px solid ' + color.warningred500 : ''};
`

const ValidatedInput = styled.input`
  border: ${props =>
    props.isNotValid ? '2px solid ' + color.warningred500 : ''};
`

const ErrorMessage = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  background: ${color.warningred50};
  border: 1px solid ${color.warningred200};
  border-radius: 3px;
  padding: 8px 16px;
  margin-top: 16px;
  color: ${color.warningred700};
`

const SelectWrapper = styled.div`
  position: relative;
  &:before {
    pointer-events: none;
    content: '';
    display: block;
    border: 0.4em solid transparent;
    border-top-color: ${color.grey700};
    position: absolute;
    right: 1.2em;
    top: 26px;
    z-index: 1;
  }
`

class Dialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      salutation: '',
      title: '',
      name: '',
      mobileNumber: '',
      email: '',
      salutationIsValid: false,
      nameIsValid: false,
      emailIsValid: false,
      formHasBeenValidated: false,
      formSubmissionPending: false,
      formHasBeenSubmitted: false
    }
  }

  componentDidMount() {
    this.getCsrfToken()
  }

  handleSubmit = e => {
    e.preventDefault()

    if (this.validateInput()) {
      const data = {
        request_information: {
          salutation: this.state.salutation,
          title: this.state.title,
          name: this.state.name,
          mobile: this.state.mobileNumber,
          email: this.state.email
        }
      }
      this.setState({
        formSubmissionPending: true
      })
      this.postData('request_information', data).then(() => {
        googleAnalyticsTracker.trackRequestInformation(e)
        this.setState({
          formSubmissionPending: false,
          formHasBeenSubmitted: true
        })
      })
    }
  }

  validateInput = () => {
    const salutationIsValid = this.state.salutation !== ''
    const nameIsValid = this.state.name !== ''
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const emailIsValid = emailPattern.test(this.state.email)
    this.setState({
      formHasBeenValidated: true,
      salutationIsValid: salutationIsValid,
      nameIsValid: nameIsValid,
      emailIsValid: emailIsValid
    })
    return salutationIsValid && nameIsValid && emailIsValid
  }

  postData = (url = ``, data = {}) => {
    const csrfToken = this.getCsrfToken()
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken
    })
    return fetch(url, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(data)
    })
  }

  getCsrfToken() {
    const metaElements = document.getElementsByTagName('meta')
    return metaElements.namedItem('csrf-token').content
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    return (
      <StyledDialog isOpen={this.props.isOpen}>
        <Passepartout onClick={this.props.closeDialog} />
        <CenterHorizontally>
          <DialogWindow>
            <CloseButton autoFocus onClick={this.props.closeDialog}>
              <Icon glyph={'close'} />
            </CloseButton>
            {!this.state.formHasBeenSubmitted && (
              <div>
                <h2>
                  <Trans i18nKey="dialog.heading" />
                </h2>
                <Trans i18nKey="dialog.text" />
                <Form>
                  <FormField>
                    <label htmlFor="salutation">
                      <Trans i18nKey="dialog.salutation" />
                    </label>
                    <SelectWrapper>
                      <ValidatedSelect
                        isNotValid={
                          this.state.formHasBeenValidated &&
                          !this.state.salutationIsValid
                        }
                        id="salutation"
                        value={this.state.salutation}
                        onChange={this.handleInputChange}
                        name="salutation"
                      >
                        <option value="">{i18n.t('dialog.choose')}</option>
                        <option value="Frau">{i18n.t('dialog.mrs')}</option>
                        <option value="Herr">{i18n.t('dialog.mr')}</option>
                      </ValidatedSelect>
                      <ErrorMessage
                        visible={
                          this.state.formHasBeenValidated &&
                          !this.state.salutationIsValid
                        }
                      >
                        <Trans i18nKey="dialog.noSalutation" />
                      </ErrorMessage>
                    </SelectWrapper>
                  </FormField>
                  <FormField>
                    <label htmlFor="title">
                      <Trans i18nKey="dialog.title" />
                    </label>
                    <SelectWrapper>
                      <select
                        id="title"
                        value={this.state.title}
                        onChange={this.handleInputChange}
                        name="title"
                      >
                        <option value="">{i18n.t('dialog.choose')}</option>
                        <option value="Dr.">{i18n.t('dialog.dr')}</option>
                        <option value="Dr. med.">
                          {i18n.t('dialog.drMed')}
                        </option>
                        <option value="Dr. med. dent.">
                          {i18n.t('dialog.drMedDent')}
                        </option>
                        <option value="Prof.">{i18n.t('dialog.prof')}</option>
                        <option value="Prof. Dr.">
                          {i18n.t('dialog.profDr')}
                        </option>
                      </select>
                    </SelectWrapper>
                  </FormField>
                  <FormFieldFullWidth>
                    <label htmlFor="name">
                      <Trans i18nKey="dialog.name" />
                    </label>
                    <ValidatedInput
                      isNotValid={
                        this.state.formHasBeenValidated &&
                        !this.state.nameIsValid
                      }
                      type="text"
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                    />
                    <ErrorMessage
                      visible={
                        this.state.formHasBeenValidated &&
                        !this.state.nameIsValid
                      }
                    >
                      <Trans i18nKey="dialog.noName" />
                    </ErrorMessage>
                  </FormFieldFullWidth>
                  <FormField>
                    <label htmlFor="email">
                      <Trans i18nKey="dialog.mail" />
                    </label>
                    <ValidatedInput
                      isNotValid={
                        this.state.formHasBeenValidated &&
                        !this.state.emailIsValid
                      }
                      type="email"
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                    <ErrorMessage
                      visible={
                        this.state.formHasBeenValidated &&
                        !this.state.emailIsValid
                      }
                    >
                      <Trans i18nKey="dialog.noEmail" />
                    </ErrorMessage>
                  </FormField>
                  <FormField>
                    <label htmlFor="mobilenumber">
                      <Trans i18nKey="dialog.mobile" />
                    </label>
                    <input
                      type="tel"
                      id="mobilenumber"
                      name="mobileNumber"
                      value={this.state.mobileNumber}
                      onChange={this.handleInputChange}
                    />
                  </FormField>
                  <SubmitButtonWrapper>
                    <SubmitButton
                      type="submit"
                      onClick={e => this.handleSubmit(e)}
                    >
                      {this.state.formSubmissionPending && <SubmitSpinner />}
                      <Trans i18nKey="dialog.requestNow" />
                    </SubmitButton>
                  </SubmitButtonWrapper>
                </Form>
                <PrivacyNote>
                  <Trans
                    i18nKey="dialog.privacyNote"
                    components={[
                      <NoWrap key="nowrap" />,
                      <PrivacyLink
                        href="https://lifetime.eu/datenschutz/web"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="privacyLink"
                      />
                    ]}
                  />
                </PrivacyNote>
              </div>
            )}
            {this.state.formHasBeenSubmitted && (
              <div>
                <h2>
                  <Trans i18nKey="dialog.thankYouTitle" />
                </h2>
                <Trans
                  i18nKey="dialog.thankYouText"
                  components={[<SalesPhone key="salesPhone" />]}
                />
              </div>
            )}
          </DialogWindow>
        </CenterHorizontally>
      </StyledDialog>
    )
  }
}

Dialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired
}

export default Dialog
