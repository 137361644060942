import React, { Fragment } from 'react'

import Nav from 'components/nav'
import Footer from 'components/footer'
import Section from 'components/section'
import PlainText from 'components/plainText'
import PrivacyNav from './privacyNav'
import { PrivacyEmail } from 'components/contactInformation'

const ReactPage = () => {
  return (
    <Fragment>
      <Nav pageType="neutral" />
      <Section size="sm">
        <PlainText>
          <PrivacyNav />
          <h1>
            Datenschutzerklärung
            <br />
            LifeTime Software
          </h1>
          <p>
            Der verantwortungsvolle Umgang mit personenbezogenen Daten ist uns,
            der LifeTime GmbH, ein wichtiges Anliegen, das wir in all unseren
            Geschäftsprozessen berücksichtigen. Wir erheben, verwenden und
            speichern Ihre personenbezogenen Daten ausschließlich auf Grundlage
            der anwendbaren datenschutzrechtlichen Bestimmungen, insbesondere
            der Datenschutz-Grundverordnung (DSGVO) und des
            Bundesdatenschutzgesetzes (BDSG).
          </p>

          <h2>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</h2>
          <p>
            Im Rahmen der Nutzung von LifeTime Desktop werden personenbezogenen
            Daten wie folgt verarbeitet:
          </p>

          <h3>Login</h3>
          <p>
            Die Anmeldung in LifeTime Desktop erfordert die Eingabe Ihrer
            E-Mail-Adresse, die Sie für die Erstellung Ihres LifeTime Accounts
            genutzt haben. Diese wird für die Anmeldung genutzt und in diesem
            Rahmen an einen Server von uns übermittelt. Außerdem nutzen wir
            diese E-Mail-Adresse, um Sie über Aktualisierungen unseres Produktes
            auf dem Laufenden zu halten. Eine Verwendung darüber hinaus findet
            nicht statt.
          </p>

          <h3>Dokumentenversand</h3>
          <p>
            Der Versand von Dokumenten an einen Patienten erfordert die Eingabe
            dessen Mobilfunk- und Versichertennummer. Die Mobilfunknummer wird
            genutzt, um dem Patienten eine SMS mit Informationen über
            bereitstehende Dokumente zu senden. Für den SMS-Versand nutzen wir
            einen Dienst der Mblox Limited (“MBlox”) und übermitteln diesem
            neben der Mobilfunknummer den SMS-Text, der den Namen Ihrer Praxis
            enthält, wie Sie ihn bei der Anlage Ihres Accounts angegeben haben.
            Die Versichertennummer wird lediglich im Rahmen der
            Zwei-Faktor-Authentifizierung genutzt und nicht übertragen. Wir
            haben zu keinem Zeitpunkt Zugriff auf die Mobilfunk- oder
            Versichertennummer.
          </p>

          <p>
            Die versendeten Dokumente werden in einer nur für den Empfänger
            lesbaren Form versendet, eine Zuordnung zu einer natürlichen Person
            ist uns darüber hinaus nicht möglich. Die Löschung dieser Daten
            erfolgt nach der Annahme durch den Empfänger oder spätestens nach 21
            Tagen.
          </p>

          <h2>Rechtsgrundlage der Datenverarbeitung</h2>
          <p>
            Die Verarbeitung Ihrer Daten geschieht auf den folgenden
            Rechtsgrundlagen: Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a)
            DSGVO, zur Durchführung eines Vertrags mit Ihnen nach Art. 6 Abs. 1
            lit. b) DSGVO, zur Erfüllung gesetzlicher Pflichten nach Art. 6 Abs.
            1 lit. c) DSGVO oder aus einem berechtigten Interesse nach Art. 6
            Abs. 1 lit. f) DSGVO. Sofern wir die Verarbeitung Ihrer
            personenbezogenen Daten auf berechtigte Interessen iSv. Art. 6 Abs.
            1 lit. f) DS-GVO stützen, sind solche die Verbesserung unseres
            Angebots, der Schutz vor Missbrauch und die Führung unserer
            Statistik.
          </p>

          <h2>Speicherdauer</h2>
          <p>
            Wir speichern Ihre personenbezogenen Daten nur solange, wie es für
            die Erreichung des Verarbeitungszweckes nötig ist oder die
            Speicherung einer gesetzlichen Aufbewahrungsfrist unterliegt. Wir
            speichern Ihre Daten,
            <ul>
              <li>
                wenn Sie in die Verarbeitung eingewilligt haben höchstens
                solange, bis Sie Ihre Einwilligung widerrufen,
              </li>
              <li>
                wenn wir die Daten zur Durchführung eines Vertrags benötigen
                höchstens solange, wie das Vertragsverhältnis mit Ihnen besteht
                oder gesetzliche Aufbewahrungsfristen laufen,
              </li>
              <li>
                wenn wir die Daten auf der Grundlage eines berechtigten
                Interesses verwenden höchstens solange, wie Ihr Interesse an
                einer Löschung oder Anonymisierung nicht überwiegt.
              </li>
            </ul>
          </p>

          <h2>Verwendung anonymisierter Daten zur Produktverbesserung</h2>
          <p>
            Zu Zwecken der Produktverbesserung erheben wir anonymisierte
            Nutzungsdaten unter Zuhilfenahme der Dienste Dritter: Teilweise
            findet eine Datenübertragung in Länder außerhalb des Europäischen
            Wirtschaftsraums (EWR) statt. Wir übermitteln personenbezogene Daten
            nur in Drittländer, bei denen die EU-Kommission ein angemessenes
            Schutzniveau bestätigt hat oder die Einhaltung von internationalen
            Sicherheitsstandards sichergestellt wurde (z.B. USA mit dem EU-US
            Privacy Shield Abkommen oder EU Standardvertragsklauseln).
          </p>

          <h3>Google Analytics</h3>
          <p>
            Wir nutzen Google Analytics, ein Analysedienst der Google Inc.
            („Google“), zur Analyse des Nutzerverhaltens. Zu diesem Zweck
            übermittelt LifeTime Desktop Informationen über Ihre Nutzung an
            einen Server von Google. Google wird diese Informationen in unserem
            Auftrag benutzen, um Reports zu erstellen und um uns gegenüber
            weitere mit der Nutzung von LifeTime Desktop verbundene
            Dienstleistungen zu erbringen. Dabei können aus den verarbeiteten
            Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden. Wir
            setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein.
            Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb
            von Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
            Server von Google in den USA übertragen und dort gekürzt. Die von
            dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit
            anderen Daten von Google zusammengeführt.
          </p>

          <h3>Sentry</h3>
          <p>
            Wir nutzen Sentry, ein Analysedienst der Functional Software Inc.
            (“Sentry”), zur Analyse von Fehlern (z.B. Verbindungs-, Datenbank-,
            oder Zertifikatsfehler), die während der Nutzung von LifeTime
            Desktop auftreten. Zu diesem Zweck übermittelt LifeTime Desktop
            Informationen über aufgetretene Fehler an einen Server von Sentry.
          </p>

          <h2>Datensicherheit</h2>
          <p>
            Die Übertragung personenbezogener Daten erfolgt stets verschlüsselt.
            Darüber hinaus haben wir technische und organisatorische Maßnahmen
            ergriffen, um Ihre personenbezogenen Daten zu schützen, insbesondere
            gegen zufällige oder vorsätzliche Manipulation, Verlust, Zerstörung
            oder Zugriff unberechtigter Personen. Diese Sicherheitsmaßnahmen
            werden entsprechend der technologischen Entwicklung fortlaufend
            angepasst.
          </p>

          <h2>Widerruf von Einwilligungen, Widerspruchsrecht, Auskünfte</h2>
          <p>
            Sie haben – teilweise unter bestimmten Voraussetzungen – das Recht,
            <ul>
              <li>
                unentgeltlich Auskunft über die Verarbeitung Ihrer Daten zu
                verlangen, sowie den Erhalt einer Kopie Ihrer personenbezogenen
                Daten. Auskunft können Sie u. a. verlangen über die Zwecke der
                Verarbeitung, die Kategorien der personenbezogenen Daten die
                verarbeitet werden, die Empfänger der Daten (sofern eine
                Weitergabe erfolgt), die Dauer der Speicherung oder die
                Kriterien für die Festlegung der Dauer;
              </li>
              <li>
                Ihre Daten zu berichtigen. Sollten Ihre personenbezogenen Daten
                unvollständig sein, so haben Sie, unter Berücksichtigung der
                Verarbeitungszwecke, das Recht die Daten zu vervollständigen;
              </li>
              <li>
                Ihre Daten löschen oder sperren zu lassen. Gründe für das
                Bestehen eines Löschungs-/Sperrungsanspruchs können u.a. sein
                der Widerruf der Einwilligung auf dem die Verarbeitung fußt, die
                betroffene Person legt Widerspruch gegen die Verarbeitung ein,
                die personenbezogenen Daten wurden unrechtmäßig verarbeitet;
              </li>
              <li>die Verarbeitung einschränken zu lassen;</li>
              <li>der Verarbeitung Ihrer Daten zu widersprechen;</li>
              <li>
                Ihre Einwilligung zur Verarbeitung Ihrer Daten für die Zukunft
                zu widerrufen;
              </li>
              <li>
                sich bei der zuständigen Aufsichtsbehörde über eine unzulässige
                Datenverarbeitung zu beschweren.
              </li>
            </ul>
          </p>

          <h2>Verantwortlicher</h2>
          <p>
            Verantwortlich im Sinne der DSGVO, des BDSG und sonstiger
            datenschutzrechtlicher Bestimmungen ist die:
          </p>

          <p>
            LifeTime GmbH
            <br />
            Rehmkamp 1, 24161 Altenholz
            <br />
            Geschäftsführer: Dr. André Kaeding
            <br />
            E-Mail: <PrivacyEmail />
          </p>

          <h2>Datenschutzbeauftragter</h2>
          <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>

          <p>
            ePrivacy GmbH
            <br />
            Große Bleichen 21, 20354 Hamburg
            <br />
            Geschäftsführer: Prof. Dr. Christoph Bauer
            <br />
            Tel.: <a href="tel:+4940609451810">+49 40 609451810</a>
          </p>

          <h2>Änderungen</h2>
          <p>
            Vor dem Hintergrund der sich fortlaufend ändernden technologischen
            Rahmenbedingungen behalten wir uns vor, Änderungen an der
            vorliegenden Datenschutzerklärung vorzunehmen.
          </p>

          <p>Gültig ab: 01.03.2022</p>
        </PlainText>
      </Section>
      <Footer withBorder />
    </Fragment>
  )
}

export default ReactPage
