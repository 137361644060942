import Section from 'components/section'
import BlueSwirlUrl from 'img/blue-swirl.svg'
import RoundCheckmarkUrl from 'img/round-checkmark.svg'
import PropTypes from 'prop-types'
import React from 'react'
import { color, fontSize } from 'styles/theme'
import styled from 'styled-components'
import media from 'utils/media-queries'

const BlueSection = styled(Section)`
  padding: 290px 0 96px 0;
  margin-top: -300px;
  background-color: ${color.blue500};
  background-image: url(${BlueSwirlUrl});
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  ${media.xl`
    padding-bottom: 80px;
    margin-top: -240px;
  `}
  ${media.md`
    padding: 180px 0 64px 0;
    margin-top: -160px;
  `}
  ${media.sm`
    margin-top: -160px;
    padding-bottom: 40px;
  `}
  ${media.xs`
    padding-top: 120px;
    margin-top: -100px;
  `}
`

const AdvantagesIcon = styled.div`
  background: url(${RoundCheckmarkUrl});
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  margin-bottom: 32px;
  background-size: contain;
`

const AdvantagesGrid = styled.div`
  border-top: 2px solid ${color.blue400};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 48px;
  color: white;
  margin-top: 80px;
  padding-top: 80px;
  h2 {
    font-size: ${fontSize.f7};
    margin-bottom: 20px;
    ${media.sm`
      font-size: ${fontSize.f6};
    `}
    ${media.xs`
      font-size: ${fontSize.f5};
    `}
  }
  ${media.md`
    grid-template-columns: auto;
    grid-gap: 24px;
    margin-top: 40px;
    padding-top: 40px;
  `}
  ${media.sm`
    grid-gap: 16px;
  `}
`

const AdvantagesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const LightLink = styled.a`
  color: white;
  :visited,
  :focus,
  :hover {
    color: white;
  }
`

const TestNow = props => {
  return (
    <BlueSection>
      <AdvantagesWrapper>
        <AdvantagesGrid>
          <div>
            <AdvantagesIcon />
            <h2>Dezentrale Datenhaltung</h2>
            <p>
              Beim Erstellen und Versand des BärCODES via LifeTime werden keine
              persönlichen Daten an Dritte übermittelt, sie bleiben stets unter
              der Kontrolle der getesteten / geimpften Personen und der
              ausführenden Einrichtung.
            </p>
            <p>
              So halten Sie alle DSGVO- und sonstigen Datenschutzvorgaben
              vollständig ein und kommen in den Genuss effizienter digitaler
              Prozesse.
            </p>
          </div>
          <div>
            <AdvantagesIcon />
            <h2>Offline überprüfbar</h2>
            <p>
              Der BärCODE ist ein spezieller QR-Code, der mithilfe der BärCODE
              Prüf-App schnell und verlässlich geprüft werden kann. Einfach im
              Browser auf dem Smartphone{' '}
              <LightLink href="https://scan.baercode.de">
                scan.baercode.de
              </LightLink>{' '}
              eingeben und BärCODEs prüfen.
            </p>
            <p>
              Die enthaltenen Informationen werden beim Scan nicht an einen
              Server übertragen. Die Überprüfung funktioniert auch offline.
            </p>
          </div>
          <div>
            <AdvantagesIcon />
            <h2>Open Source</h2>
            <p>
              Der BärCODE wird unabhängig und ohne wirtschaftliches Interesse
              unmittelbar unter der Leitung des BIH Zentrums für Digitale
              Gesundheit an der Charité entwickelt.
            </p>
            <p>
              Der Quellcode wird vollständig transparent offengelegt und kann so
              von der IT-Community auf Sicherheit und Funktionalität getestet
              werden. Mehr Infos unter{' '}
              <LightLink href="https://baercode.de">baercode.de</LightLink>
            </p>
          </div>
        </AdvantagesGrid>
      </AdvantagesWrapper>
    </BlueSection>
  )
}

TestNow.propTypes = {
  background: PropTypes.string
}

export default TestNow
