import React, { Fragment } from 'react'

import Nav from 'components/nav'
import Footer from 'components/footer'
import Section from 'components/section'
import PlainText from 'components/plainText'
import PrivacyNav from './privacyNav'
import { PrivacyEmail } from 'components/contactInformation'

const ReactPage = () => {
  return (
    <Fragment>
      <Nav pageType="neutral" />
      <Section size="sm">
        <PlainText>
          <PrivacyNav />
          <h1>
            Datenschutzerklärung
            <br />
            LifeTime Web
          </h1>
          <p>
            Der verantwortungsvolle Umgang mit personenbezogenen Daten ist uns,
            der LifeTime GmbH, ein wichtiges Anliegen, das wir in all unseren
            Geschäftsprozessen berücksichtigen. Wir erheben, verwenden und
            speichern Ihre personenbezogenen Daten ausschließlich auf Grundlage
            der anwendbaren datenschutzrechtlichen Bestimmungen, insbesondere
            der Datenschutz-Grundverordnung (DSGVO) und des
            Bundesdatenschutzgesetzes (BDSG).
          </p>

          <h2>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</h2>
          <p>
            a) Im Rahmen der Nutzung unserer Webseite werden durch Ihren Browser
            automatisch die folgenden Informationen an unseren Server gesendet
            und temporär gespeichert:
          </p>
          <ul>
            <li>IP-Adresse des anfragenden Rechners</li>
            <li>Datum und Uhrzeit des Zugriffs</li>
            <li>Name und URL der abgerufenen Datei</li>
            <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
            <li>
              verwendeter Browser und ggf. das Betriebssystem Ihres Rechners
            </li>
          </ul>
          <p>
            Die genannten Daten werden von uns zum Zweck der Gewährleistung
            eines reibungslosen Betriebs und weiteren administrativen Zwecken
            erhoben. In keinem Fall verwenden wir die erhobenen Daten um
            Rückschlüsse auf Ihre Person zu ziehen.
          </p>
          <p>
            b) Im Rahmen der Anforderung von Informationen zu LifeTime für Ärzte
            über das entsprechende Formular ist die Angabe Ihrer E-Mail-Adresse
            erforderlich, weitere Angaben können freiwillig gemacht werden. Die
            erhobenen Daten nutzen wir ausschließlich zur Kontaktaufnahme im
            Rahmen der Beantwortung Ihrer Anfrage.
          </p>
          <p>
            c) Im Rahmen der Bestellung von LifeTime für Ärzte über das
            entsprechende Formular ist die Angabe von Daten zu Ihrer Person und
            Ihrer Praxis erforderlich. Die erhobenen Daten speichern und nutzen
            wir zur Erfüllung Ihrer Bestellung und zugehörigen Prozessen. Für
            die Abwicklung der Zahlungen greifen wir auf Dienste Rocketship Inc
            und Stripe zurück und übermitteln zu diesem Zweck Abrechnungsdaten
            (u.a. Name, Anschrift und Zahlungsmittel) an diese.
          </p>
          <p>
            d) An uns gesendete Bewerbungsunterlagen verarbeiten wir nur zum
            Zwecke der Auswahl geeigneter Personen. Die Daten werden
            ausschließlich intern genutzt und nach maximal 6 Monaten gelöscht,
            außer die betroffene Person stimmt explizit einer längerfristigen
            Speicherung zu.
          </p>

          <h2>Rechtsgrundlage der Datenverarbeitung</h2>
          <p>
            Die Verarbeitung Ihrer Daten geschieht auf den folgenden
            Rechtsgrundlagen: Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a)
            DSGVO, zur Durchführung eines Vertrags mit Ihnen nach Art. 6 Abs. 1
            lit. b) DSGVO, zur Erfüllung gesetzlicher Pflichten nach Art. 6 Abs.
            1 lit. c) DSGVO oder aus einem berechtigten Interesse nach Art. 6
            Abs. 1 lit. f) DSGVO. Sofern wir die Verarbeitung Ihrer
            personenbezogenen Daten auf berechtigte Interessen iSv. Art. 6 Abs.
            1 lit. f) DS-GVO stützen, sind solche die Verbesserung unseres
            Angebots, der Schutz vor Missbrauch und die Führung unserer
            Statistik.
          </p>

          <h2>Speicherdauer</h2>
          <p>
            Wir speichern Ihre personenbezogenen Daten nur solange, wie es für
            die Erreichung des Verarbeitungszweckes nötig ist oder die
            Speicherung einer gesetzlichen Aufbewahrungsfrist unterliegt. Wir
            speichern Ihre Daten,
            <ul>
              <li>
                wenn Sie in die Verarbeitung eingewilligt haben höchstens
                solange, bis Sie Ihre Einwilligung widerrufen,
              </li>
              <li>
                wenn wir die Daten zur Durchführung eines Vertrags benötigen
                höchstens solange, wie das Vertragsverhältnis mit Ihnen besteht
                oder gesetzliche Aufbewahrungsfristen laufen,
              </li>
              <li>
                wenn wir die Daten auf der Grundlage eines berechtigten
                Interesses verwenden höchstens solange, wie Ihr Interesse an
                einer Löschung oder Anonymisierung nicht überwiegt.
              </li>
            </ul>
          </p>

          <h2>Verwendung von Cookies</h2>
          <p>
            Wir nutzen Cookies. Die meisten der von uns verwendeten Cookies sind
            so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs
            automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät
            gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns,
            Ihren Browser beim nächsten Besuch wiederzuerkennen.
          </p>
          <p>
            Darüber hinaus setzen wir Cookies ein, um die Nutzung unserer
            Website statistisch zu erfassen und zum Zwecke der Optimierung
            unseres Angebotes - Details dazu im folgenden Absatz.
          </p>
          <h2>Verwendung anonymisierter Daten zur Produktverbesserung</h2>
          <p>
            Wir nutzen Google Analytics, ein Analysedienst der Google Inc.
            („Google“), zur Analyse des Nutzerverhaltens. Google Analytics
            verwendet einen Cookie, der die Analyse der Nutzung der Website
            durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen
            über Ihre Nutzung dieser Website werden in der Regel an einen Server
            von Google in den USA übertragen und dort gespeichert. Google wird
            diese Informationen in unserem Auftrag benutzen um Reports zu
            erstellen und um uns gegenüber weitere mit der Nutzung der LifeTime
            Webseite verbundene Dienstleistungen zu erbringen. Dabei können aus
            den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer
            erstellt werden. Wir setzen Google Analytics nur mit aktivierter
            IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird
            von Google innerhalb von Mitgliedstaaten der Europäischen Union oder
            in anderen Vertragsstaaten des Abkommens über den Europäischen
            Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle
            IP-Adresse an einen Server von Google in den USA übertragen und dort
            gekürzt. Die von dem Browser des Nutzers übermittelte IP-Adresse
            wird nicht mit anderen Daten von Google zusammengeführt.
          </p>
          <p>
            Mit Hilfe eines Browser-Plugins haben Sie jederzeit die Möglichkeit,
            die Erhebung der vorgenannten Daten zu unterbinden. Das Plugin und
            Hinweise zur Installation finden Sie{' '}
            <a href="https://tools.google.com/dlpage/gaoptout">hier</a>.
          </p>
          <p>
            Darüber hinaus nutzen wir die Conversion-Pixel der u.g. Unternehmen
            zur Messung des Werbeerfolges von Werbekampagnen. Zu diesem Zweck
            übermittelt die LifeTime Webseite Informationen über Ihre Nutzung an
            einen Server des jeweiligen Betreibers des Werbenetzwerkes:
          </p>
          <ul>
            <li>Facebook Inc.</li>
            <li>Microsoft Corporation</li>
            <li>LinkedIn Ireland Unlimited Company</li>
            <li>Google Ads</li>
            <li>Bing</li>
          </ul>
          <h2>Datensicherheit</h2>
          <p>
            Die Übertragung personenbezogener Daten erfolgt stets verschlüsselt.
            Darüber hinaus haben wir technische und organisatorische Maßnahmen
            ergriffen, um Ihre personenbezogenen Daten zu schützen, insbesondere
            gegen zufällige oder vorsätzliche Manipulation, Verlust, Zerstörung
            oder Zugriff unberechtigter Personen. Diese Sicherheitsmaßnahmen
            werden entsprechend der technologischen Entwicklung fortlaufend
            angepasst.
          </p>

          <h2>Widerruf von Einwilligungen, Widerspruchsrecht, Auskünfte</h2>
          <p>
            Sie haben – teilweise unter bestimmten Voraussetzungen – das Recht,
            <ul>
              <li>
                unentgeltlich Auskunft über die Verarbeitung Ihrer Daten zu
                verlangen, sowie den Erhalt einer Kopie Ihrer personenbezogenen
                Daten. Auskunft können Sie u. a. verlangen über die Zwecke der
                Verarbeitung, die Kategorien der personenbezogenen Daten die
                verarbeitet werden, die Empfänger der Daten (sofern eine
                Weitergabe erfolgt), die Dauer der Speicherung oder die
                Kriterien für die Festlegung der Dauer;
              </li>
              <li>
                Ihre Daten zu berichtigen. Sollten Ihre personenbezogenen Daten
                unvollständig sein, so haben Sie, unter Berücksichtigung der
                Verarbeitungszwecke, das Recht die Daten zu vervollständigen;
              </li>
              <li>
                Ihre Daten löschen oder sperren zu lassen. Gründe für das
                Bestehen eines Löschungs-/Sperrungsanspruchs können u.a. sein
                der Widerruf der Einwilligung auf dem die Verarbeitung fußt, die
                betroffene Person legt Widerspruch gegen die Verarbeitung ein,
                die personenbezogenen Daten wurden unrechtmäßig verarbeitet;
              </li>
              <li>die Verarbeitung einschränken zu lassen;</li>
              <li>der Verarbeitung Ihrer Daten zu widersprechen;</li>
              <li>
                Ihre Einwilligung zur Verarbeitung Ihrer Daten für die Zukunft
                zu widerrufen;
              </li>
              <li>
                sich bei der zuständigen Aufsichtsbehörde über eine unzulässige
                Datenverarbeitung zu beschweren.
              </li>
            </ul>
          </p>

          <h2>Verantwortlicher</h2>
          <p>
            Verantwortlich im Sinne der DSGVO, des BDSG und sonstiger
            datenschutzrechtlicher Bestimmungen ist die:
          </p>

          <p>
            LifeTime GmbH
            <br />
            Rehmkamp 1, 24161 Altenholz
            <br />
            Geschäftsführer: Dr. André Kaeding
            <br />
            E-Mail: <PrivacyEmail />
          </p>

          <h2>Datenschutzbeauftragter</h2>
          <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>

          <p>
            ePrivacy GmbH
            <br />
            Große Bleichen 21, 20354 Hamburg
            <br />
            Geschäftsführer: Prof. Dr. Christoph Bauer
            <br />
            Tel.: <a href="tel:+4940609451810">+49 40 609451810</a>
          </p>

          <h2>Änderungen</h2>
          <p>
            Vor dem Hintergrund der sich fortlaufend ändernden technologischen
            Rahmenbedingungen behalten wir uns vor, Änderungen an der
            vorliegenden Datenschutzerklärung vorzunehmen.
          </p>

          <p>Gültig ab: 01.03.2022</p>
        </PlainText>
      </Section>
      <Footer withBorder />
    </Fragment>
  )
}

export default ReactPage
