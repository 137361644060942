import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { color, fontSize } from 'styles/theme'

const StyledAccordion = styled.div`
  border-bottom: 1px solid ${color.grey700};
  display: flex;
  flex-direction: column;
`

const Heading = styled.button`
  font-weight: 900;
  padding: 16px 0 12px 0;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
`

const HeadingText = styled.div`
  display: inline-block;
  height: 20px;
  color: ${props => (props.dark ? color.grey200 : color.grey900)};
`

const ArrowWrapper = styled.span`
  width: 16px;
  height: 16px;
  font-size: ${fontSize.f5};
  transform: ${props => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  transition: transform 0.2s ease-out;
  position: relative;
  align-items: center;
`

const Arrow = styled.div`
  color: ${props => (props.dark ? color.grey200 : color.grey900)};
  width: 16px;
  height: 16px;
  user-select: none;
  position: absolute;
  top: 0px;
  left: 0px;
  line-height: 0.8;
  text-align: center;
`

const Content = styled.div`
  margin-left: 24px;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  margin-bottom: 20px;
`

class Accordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  toggleAccordion = () => {
    const isOpen = this.state.isOpen
    this.setState({
      isOpen: !isOpen
    })
  }

  render() {
    return (
      <StyledAccordion>
        <Heading onClick={this.toggleAccordion}>
          <ArrowWrapper isOpen={this.state.isOpen}>
            <Arrow dark={this.props.dark}>▸</Arrow>
          </ArrowWrapper>
          <HeadingText dark={this.props.dark}>{this.props.heading}</HeadingText>
        </Heading>
        <Content isOpen={this.state.isOpen}>{this.props.children}</Content>
      </StyledAccordion>
    )
  }
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.node.isRequired,
  dark: PropTypes.bool
}

export default Accordion
