import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { fontSize } from 'styles/theme'

const StyledPlainText = styled.div`
  width: 100%;
  h1 {
    font-size: ${fontSize.f9};
    ${media.md`
      font-size: ${fontSize.f8};
    `}
    ${media.sm`
      font-size: ${fontSize.f6};
      hyphens: auto;
    `}
  }
  h2 {
    font-size: ${fontSize.f6};
    ${media.sm`
      font-size: ${fontSize.f5};
    `}
  }
  h3 {
    font-size: ${fontSize.f4};
    ${media.sm`
      font-size: ${fontSize.f3};
    `}
  }
  ul {
    margin-bottom: 32px;
  }
  li {
    margin-bottom: 12px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
`

const PlainText = props => <StyledPlainText>{props.children}</StyledPlainText>

PlainText.propTypes = {
  children: PropTypes.node
}

export default PlainText
