import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { color } from 'styles/theme'

const StyledCTAContainer = styled.div`
  border-radius: 10px;
  padding: 64px;
  background: ${color.blue500};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  ${media.sm`
    padding: 32px;
  `}
  ${media.xs`
    padding: 16px;
  `}
`
const CTAContainer = props => {
  return <StyledCTAContainer>{props.children}</StyledCTAContainer>
}

CTAContainer.propTypes = {
  children: PropTypes.node
}

export default CTAContainer
