import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { useTranslation } from 'react-i18next'

import { color, shadow, fontSize } from 'styles/theme'

import Section from 'components/section'
import Subtitle from 'components/subtitle'

const CenterElements = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 100%;
  ${media.sm`
    text-align: left;
    align-items: flex-start;
  `}
`

const AvatarSize = '144px'

const ReferencesWrapper = styled.div`
  text-align: left;
  display: grid;
  grid-template-rows: ${AvatarSize} auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 80px;
  grid-row-gap: 40px;
  margin-top: 48px;
  padding: 0 32px;
  grid-auto-flow: column;
  ${media.lg`
    grid-column-gap: 40px;
  `}
  ${media.md`
    grid-template-columns: auto;
    grid-template-rows: repeat(9, auto);
  `}
  ${media.sm`
    margin-top: 32px;
    padding: 0;
    grid-row-gap: 32px;
  `}
`

const Avatar = styled.div`
  width: ${AvatarSize};
  height: ${AvatarSize};
  border-radius: 96px;
  background: ${color.grey100};
  box-shadow: ${shadow.s2};
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${props => props.position};
  justify-self: center;
  ${media.md`
    margin-top: 32px;
    &:first-of-type{
      margin-top: 0;
    }
  `}
  ${media.sm`
    margin-top: 8px;
    &:first-of-type{
      margin-top: 0;
    }
    width: 120px;
    height: 120px;
  `}
`

const TextWrapper = styled.div`
  max-width: 352px;
  ${media.md`
    max-width: 512px;
  `}
`

const Text = styled.div`
  ${media.md`
    text-align: center;
  `}
`

const Person = styled.div``

const Name = styled.div`
  font-weight: 900;
  text-align: center;
  font-size: ${fontSize.f5};
  ${media.lg`
    font-size: ${fontSize.f4};
  `}
  ${media.sm`
    font-size: ${fontSize.f3};
  `}
`

const Role = styled.div`
  margin-top: 8px;
  font-size: ${fontSize.f2};
  text-align: center;
  ${media.lg`
    font-size: ${fontSize.f1};
  `}
  ${media.md`
    font-size: ${fontSize.f2};
  `}
  ${media.sm`
    margin-top: 8px;
  `}
`

const Reference = props => (
  <Fragment>
    <Avatar src={props.avatar} />
    <TextWrapper>
      <Text>{props.text}</Text>
    </TextWrapper>
    <Person>
      <Name>{props.name}</Name>
      <Role>{props.role}</Role>
    </Person>
  </Fragment>
)

Reference.propTypes = {
  avatar: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
  role: PropTypes.node.isRequired
}

const References = props => {
  const { t } = useTranslation()
  return (
    <Section
      background={props.background}
      noBottomPadding={props.noBottomPadding}
    >
      <CenterElements>
        <Subtitle>{t('references.subtitle')}</Subtitle>
        <h2>{t('references.title')}</h2>
        <ReferencesWrapper>{props.children}</ReferencesWrapper>
      </CenterElements>
    </Section>
  )
}

References.Reference = Reference

References.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  noBottomPadding: PropTypes.bool
}

export default References
