import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

const StyledContainer = styled.div`
  max-width: ${props => props.maxWidth.toString() + 'px'};
  width: 100%;
  padding: 0 32px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: ${props => (props.centerText ? 'center' : 'left')};
  ${media.sm`
    padding: 0 16px;
  `}
`

class Container extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      maxWidth: this.getContainerWidth()
    }
  }

  getContainerWidth = () => {
    switch (this.props.size) {
      case 'sm':
        return 720
      case 'md':
        return 1100
      case 'lg':
        return 1260
    }
    return 1260
  }

  render() {
    return (
      <StyledContainer
        className={this.props.className}
        centerText={this.props.centerText}
        maxWidth={this.state.maxWidth}
      >
        {this.props.children}
      </StyledContainer>
    )
  }
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  centerText: PropTypes.bool,
  size: PropTypes.string
}

export default Container
