import Dialog from 'components/dialog/'
import Footer from 'components/footer'
import NoWrap from 'components/noWrap'
import References from 'components/references'
import TextWithIllustration from 'components/textWithIllustration'
import DrKrollnerUrl from 'img/krollner.jpg'
import QualityIllustrationUrl from 'img/quality-illustration.png'
import DrRathmerUrl from 'img/rathmer.jpg'
import RetentionIllustrationUrl from 'img/retention-illustration.png'
import SavingsIllustrationUrl from 'img/savings-illustration.png'
import SecurityIllustrationUrl from 'img/security-illustration.svg'
import DrTrettinUrl from 'img/trettin.jpg'
import React, { Fragment } from 'react'
import { Trans } from 'react-i18next'
import { color } from 'styles/theme'
import { googleAnalyticsTracker } from 'utils/tracking'
import CallToAction from './callToAction'
import Header from './header'
import Hero from './hero'
import Reimbursement from 'components/contentSections/reimbursement'

class ReactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false
    }
  }

  openDialog = e => {
    googleAnalyticsTracker.trackCTAButtonClick(e)
    this.setState({ dialogIsOpen: true })
  }

  closeDialog = () => {
    this.setState({ dialogIsOpen: false })
  }

  render() {
    return (
      <Fragment>
        <Header />
        <Hero onClick={this.openDialog} />
        <Dialog
          isOpen={this.state.dialogIsOpen}
          closeDialog={this.closeDialog}
        />
        <Reimbursement
          background={color.grey50}
          brandName="der LifeTime Akquinet KIM-Lösung"
        />
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.digitalOffice.securitySubtitle" />}
          title={<Trans i18nKey="pages.digitalOffice.securityTitle" />}
          illustrationUrl={SecurityIllustrationUrl}
          smallIllustration
          reverse
        >
          <Trans i18nKey="pages.digitalOffice.securityText" />
        </TextWithIllustration>
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.digitalOffice.savingsSubtitle" />}
          title={
            <Fragment>
              <Trans i18nKey="pages.digitalOffice.savingsTitle1" />
              <NoWrap>
                <Trans i18nKey="pages.digitalOffice.savingsTitle2" />
              </NoWrap>
            </Fragment>
          }
          illustrationUrl={SavingsIllustrationUrl}
          background={color.grey50}
        >
          <p>
            Wenn Sie Ihren Patienten und Kollegen Nachrichten und Dokumente per
            LifeTime & KIM senden, muss Ihr Praxis- oder Klinikteam weniger
            faxen, drucken, scannen, Briefe versenden und telefonieren. So{' '}
            <em>sparen Sie Kosten für Porto und Papier</em> und optimieren
            gleichzeitig die Prozesse in Ihrer Praxis.
          </p>
        </TextWithIllustration>
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.digitalOffice.qualitySubtitle" />}
          title={<Trans i18nKey="pages.digitalOffice.qualityTitle" />}
          illustrationUrl={QualityIllustrationUrl}
          reverse
        >
          {<Trans i18nKey="pages.digitalOffice.qualityText" />}
        </TextWithIllustration>
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.digitalOffice.loyalitySubtitle" />}
          title="Patienten-App mit Ihrem Profil"
          illustrationUrl={RetentionIllustrationUrl}
          background={color.grey50}
        >
          <p>
            Nachdem Patienten ein Dokument oder eine Nachricht von Ihnen
            erhalten haben, wird das <em>Profil Ihrer Praxis</em> oder Klinik
            automatisch <em>in der LifeTime App für Patienten</em> hinzugefügt.
            So haben diese Ihre Telefonnummer und Adresse immer zur Hand und
            Ihre Praxis wird häufiger weiterempfohlen.
          </p>
        </TextWithIllustration>
        <References noBottomPadding>
          <References.Reference
            avatar={DrKrollnerUrl}
            text={<Trans i18nKey="pages.digitalOffice.reference1Text" />}
            name={<Trans i18nKey="pages.digitalOffice.reference1Name" />}
            role={<Trans i18nKey="pages.digitalOffice.reference1Role" />}
          />
          <References.Reference
            avatar={DrTrettinUrl}
            text={<Trans i18nKey="pages.digitalOffice.reference2Text" />}
            name={<Trans i18nKey="pages.digitalOffice.reference2Name" />}
            role={<Trans i18nKey="pages.digitalOffice.reference2Role" />}
          />
          <References.Reference
            avatar={DrRathmerUrl}
            text={<Trans i18nKey="pages.digitalOffice.reference3Text" />}
            name={<Trans i18nKey="pages.digitalOffice.reference3Name" />}
            role={
              <Fragment>
                <Trans i18nKey="pages.digitalOffice.reference3Role" />
              </Fragment>
            }
          />
        </References>
        <CallToAction onClick={this.openDialog} />
        <Footer noTopPadding />
      </Fragment>
    )
  }
}
export default ReactPage
