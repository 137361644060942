import React from 'react'
import PropTypes from 'prop-types'

export const SalesPhone = () => (
  <a href="tel:+4940228678978">+49 40 228678978</a>
)

export const SupportPhone = () => (
  <a href="tel:+494022867897">+49 40 22867897</a>
)

export const SupportEmail = () => (
  <a href="mailto:support@lifetime.eu">support@lifetime.eu</a>
)

export const SupportFax = () => <a href="tel:+494035674597">+49 40 35674597</a>

export const PrivacyEmail = () => (
  <a href="mailto:privacy@lifetime.eu">privacy@lifetime.eu</a>
)

export const SecurityEmail = () => (
  <a href="mailto:security@lifetime.eu">security@lifetime.eu</a>
)

export const JobEmail = props => {
  const email = 'jointhejourney@lifetime.eu'
  return (
    <a href={'mailto:' + email}>{props.children ? props.children : email}</a>
  )
}

JobEmail.propTypes = {
  children: PropTypes.node
}

export const ConsumerPressPhone = () => (
  <a href="tel: +4940228678976">+49 40 228678977</a>
)

export const TradePressPhone = () => (
  <a href="tel: +4940228678976">+49 40 228678976</a>
)
