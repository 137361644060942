import Button from 'components/button'
import Quotation from 'components/quotation'
import Section from 'components/section'
import SchuesselerUrl from 'img/aschuesseler.jpg'
import KimUrl from 'img/kim.jpg'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { color, fontSize } from 'styles/theme'
import media from 'utils/media-queries'

const TeaserText = styled.div`
  p {
    font-size: ${fontSize.f5};
  }
  max-width: 800px;
  margin-bottom: 2rem;
`

const H1 = styled.h1`
  max-width: 900px;
  line-height: 1.3;
  font-size: ${fontSize.f9};
  ${media.md`
    font-size: ${fontSize.f8};
  `}
  ${media.sm`
    font-size: ${fontSize.f7};
  `}
`

const Blue = styled.span`
  color: ${color.blue500};
`

const StyledImg = styled.div`
  background: url(${KimUrl});
  margin-top: 24px;
  position: relative;
  width: 100%;
  height: 600px;
  max-width: 100%;
  max-height: 800px;
  background-size: cover;
  ${media.xl`
    margin-top: 56px;
  `}
  ${media.md`
    margin-top: 64px;
    max-height: 400px;
    background-size: 150%;
    background-position: 0% 0%;
    background-repeat: no-repeat;
  `}
  ${media.sm`
    margin-top: 64px;
    max-height: 400px;
    background-size: 200%;
  `}
`

const Hero = props => {
  return (
    <Section centerText background={props.background} noBottomPadding>
      <H1>
        <Blue>(KIM-)Nachrichten</Blue> <br /> sicher an Ärzte und Patienten
        senden
      </H1>
      <TeaserText>
        <p>Die sichere Kommunikationsplattform für das Medizinwesen.</p>
        <p>
          Senden Sie mit <em>unbegrenztem Datenvolumen</em> TI-KIM-E-Mails,
          Dokumente und sichere Chatnachrichten an Ärzte, medizinische
          Einrichtungen und Patienten.
        </p>
      </TeaserText>
      <a href="/order/plan">
        <Button type="button">Jetzt bestellen</Button>
      </a>
      <StyledImg />
      <Quotation
        avatar={SchuesselerUrl}
        text=" Privaten Praxen ist es aktuell nicht möglich den TI- Dienst zu nutzen – die KBV hat daher in Zusammenarbeit mit Akquinet und LifeTime einen eigenen KIM-Dienst ins Leben gerufen, der mehr kann als Dienste anderer Marktanbieter."
        name="Dr. Andreas Schüßeler"
        role="Vorstand Ärztenetz Hamburg e.V."
      />
    </Section>
  )
}

Hero.propTypes = {
  background: PropTypes.string,
  onClick: PropTypes.func
}

export default Hero
