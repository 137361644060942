import React, { Fragment } from 'react'
import styled from 'styled-components'

import Footer from 'components/footer'
import Nav from 'components/nav'
import PlainText from 'components/plainText'
import Section from 'components/section'
import Download from './download'

// downloads

import EPrescriptionUrl from './pressReleases/archive/31.01.2019-LifeTime_ermöglicht_sichere_eRezept-Übertragung_im_Pilotprojekt.pdf'
import LetterUrl from './pressReleases/archive/07.11.2018-Nachlaessigkeiten_mit_Patientendaten_bedrohen_Reputation_der_E-Health-Branche.pdf'
import NewGenerationUrl from './pressReleases/archive/22.06.2017_PM_LifeTime_bringt_neue_Produktgeneration_auf_den_Markt.pdf'
import InvestUrl from './pressReleases/archive/13.06.2017-PM_LifeTime_Innovationsfondsprojekt INVEST.pdf'
import NationwideUrl from './pressReleases/archive/11.05.2017-PMLifeTimebundesweit.pdf'
import HPIUrl from './pressReleases/archive/24.03.2017_Pressemitteilung_Kooperation_HPI_LifeTime.pdf'
import OpenLetterUrl from './pressReleases/archive/24.01.2017_Offener_Brief_an_den_Gesundheitsminister.pdf'
import ThiemeENUrl from './pressReleases/archive/09.01.2017_Press_Release_LifeTime_Interim_Financing_Thieme.pdf'
import ThiemeDEUrl from './pressReleases/archive/09.01.2017_Pressemitteilung_LifeTime_Zwischenfinanzierung_Thieme.pdf'
import LaunchUrl from './pressReleases/archive/24.05.2016_Pressemitteilung_LifeTime_Launch.pdf'
import SeedFinancingENUrl from './pressReleases/archive/06.01.2016_Press_release_LifeTime_Seedfinancing.pdf'
import SeedFinancingDEUrl from './pressReleases/archive/06.01.2016_Pressemitteilung_LifeTime_Seedfinanzierung.pdf'

// download thumbnails 240 x 240px
import PdfTnUrl from './downloads/thumbnails/pdf_tn.jpg'

const List = styled.ul`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  width: 100%;
`

const ReactPage = () => {
  return (
    <Fragment>
      <Nav pageType="neutral" />
      <Section size="md">
        <h1>Pressemitteilungen Archiv</h1>
        <PlainText>
          <List>
            <Download
              title="LifeTime ermöglicht sichere E-Rezept-Übertragung"
              file={EPrescriptionUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="Nachlässigkeiten mit Patientendaten bedrohen Reputation der E-Health-Branche"
              file={LetterUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="LifeTime bringt neue Produktgeneration auf den Markt"
              file={NewGenerationUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="Innovationsfondsprojekt INVEST"
              file={InvestUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="LifeTime jetzt bundesweit"
              file={NationwideUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="Kooperation HPI"
              file={HPIUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="Offener Brief an den Gesundheitsminister"
              file={OpenLetterUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="Zwischenfinanzierung Thieme (EN)"
              file={ThiemeENUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="Zwischenfinanzierung Thieme (DE)"
              file={ThiemeDEUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="LifeTime Launch"
              file={LaunchUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="Millionenbetrag für Hamburger Gesundheits-App LifeTime (EN)"
              file={SeedFinancingENUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
            <Download
              title="Millionenbetrag für Hamburger Gesundheits-App LifeTime (DE)"
              file={SeedFinancingDEUrl}
              thumbnail={PdfTnUrl}
              fileType="pdf"
            />
          </List>
        </PlainText>
      </Section>
      <Footer withBorder />
    </Fragment>
  )
}

export default ReactPage
