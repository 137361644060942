import React, { Fragment } from 'react'
import { Trans } from 'react-i18next'

import { color } from 'styles/theme'

import Dialog from 'components/dialog/'
import Header from 'components/header'
import Hero from './hero'
import TextWithIllustration from 'components/textWithIllustration'
import NoWrap from 'components/noWrap'
import References from 'components/references'
import CallToAction from 'components/contentSections/callToAction'
import Footer from 'components/footer'

import SecurityIllustrationUrl from 'img/security-illustration.svg'
import SavingsIllustrationUrl from 'img/savings-illustration.png'
import QualityIllustrationUrl from 'img/quality-illustration.png'
import RetentionIllustrationUrl from 'img/retention-illustration.png'
import DrKrollnerUrl from 'img/krollner.jpg'
import DrTrettinUrl from 'img/trettin.jpg'
import DrRathmerUrl from 'img/rathmer.jpg'

import { googleAnalyticsTracker } from 'utils/tracking'

class ReactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false
    }
  }

  openDialog = e => {
    googleAnalyticsTracker.trackCTAButtonClick(e)
    this.setState({ dialogIsOpen: true })
  }

  closeDialog = () => {
    this.setState({ dialogIsOpen: false })
  }

  render() {
    return (
      <Fragment>
        <Header />
        <Hero onClick={this.openDialog} />
        <Dialog
          isOpen={this.state.dialogIsOpen}
          closeDialog={this.closeDialog}
        />
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.digitalOffice.securitySubtitle" />}
          title={<Trans i18nKey="pages.digitalOffice.securityTitle" />}
          illustrationUrl={SecurityIllustrationUrl}
          background={color.grey50}
          smallIllustration
        >
          <Trans i18nKey="pages.digitalOffice.securityText" />
        </TextWithIllustration>
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.digitalOffice.savingsSubtitle" />}
          title={
            <Fragment>
              <Trans i18nKey="pages.digitalOffice.savingsTitle1" />
              <NoWrap>
                <Trans i18nKey="pages.digitalOffice.savingsTitle2" />
              </NoWrap>
            </Fragment>
          }
          illustrationUrl={SavingsIllustrationUrl}
          reverse
        >
          <Trans i18nKey="pages.digitalOffice.savingsText" />
        </TextWithIllustration>
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.digitalOffice.qualitySubtitle" />}
          title={<Trans i18nKey="pages.digitalOffice.qualityTitle" />}
          illustrationUrl={QualityIllustrationUrl}
          background={color.grey50}
        >
          {<Trans i18nKey="pages.digitalOffice.qualityText" />}
        </TextWithIllustration>
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.digitalOffice.loyalitySubtitle" />}
          title={<Trans i18nKey="pages.digitalOffice.loyalityTitle" />}
          illustrationUrl={RetentionIllustrationUrl}
          reverse
        >
          {<Trans i18nKey="pages.digitalOffice.loyalityText" />}
        </TextWithIllustration>
        <References background={color.grey50}>
          <References.Reference
            avatar={DrKrollnerUrl}
            text={<Trans i18nKey="pages.digitalOffice.reference1Text" />}
            name={<Trans i18nKey="pages.digitalOffice.reference1Name" />}
            role={<Trans i18nKey="pages.digitalOffice.reference1Role" />}
          />
          <References.Reference
            avatar={DrTrettinUrl}
            text={<Trans i18nKey="pages.digitalOffice.reference2Text" />}
            name={<Trans i18nKey="pages.digitalOffice.reference2Name" />}
            role={<Trans i18nKey="pages.digitalOffice.reference2Role" />}
          />
          <References.Reference
            avatar={DrRathmerUrl}
            text={<Trans i18nKey="pages.digitalOffice.reference3Text" />}
            name={<Trans i18nKey="pages.digitalOffice.reference3Name" />}
            role={
              <Fragment>
                <Trans i18nKey="pages.digitalOffice.reference3Role" />
              </Fragment>
            }
          />
        </References>
        <CallToAction onClick={this.openDialog} />
        <Footer noTopPadding />
      </Fragment>
    )
  }
}
export default ReactPage
