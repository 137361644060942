import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { useTranslation, Trans } from 'react-i18next'

import Section from 'components/section'
import Typewriter from 'components/typewriter'
import Text from 'components/hero/text'
import Card from 'components/card'
import List from 'components/list'
import Button from 'components/button'
import EmbedContainer from 'components/embedContainer'
import Logos from 'components/logos'

import MedidokLogoUrl from 'img/medidok-logo.jpg'
import ListStyleImageUrl from 'img/list-style-image.svg'

const VerticalSpacer = styled.div`
  min-width: 100%;
  ${media.sm`
    margin-top: 32px;
  `}
`

const MedidokLogoSmall = styled.img`
  padding-right: 80px;
  max-height: 250px;
  max-width: 350px;
  object-fit: contain;
  ${media.md`
    padding-right: 0;
    padding-bottom: 80px;
  `}
  ${media.sm`
    max-height: 220px;
    padding-bottom: 40px;
  `}
`

const MedidokAdvantages = styled.div`
  text-align: left;
`

const Hero = props => {
  const { t } = useTranslation()
  return (
    <Section centerText>
      <h1>
        <Trans i18nKey="pages.medidok.hero.title1" />
        <Typewriter />
        <Trans i18nKey="pages.medidok.hero.title2" />
      </h1>
      <Text>
        <Trans i18nKey="pages.medidok.hero.text" />
      </Text>
      <Button type="button" onClick={props.onClick}>
        {t('pages.medidok.hero.button')}
      </Button>
      <Card>
        <MedidokLogoSmall src={MedidokLogoUrl} />
        <MedidokAdvantages>
          <h3>Ihre Vorteile als mediDOK-Nutzer:</h3>
          <List
            listStyleImage={ListStyleImageUrl}
            items={[
              t('pages.medidok.hero.advantage1'),
              t('pages.medidok.hero.advantage2'),
              t('pages.medidok.hero.advantage3')
            ]}
          />
        </MedidokAdvantages>
      </Card>
      <VerticalSpacer>
        <EmbedContainer />
      </VerticalSpacer>
      <Logos />
    </Section>
  )
}

Hero.propTypes = {
  onClick: PropTypes.func
}

export default Hero
