import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

import Section from 'components/section'
import Subtitle from 'components/subtitle'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: ${props =>
    props.reverse ? '"text illustration"' : '"illustration text"'};
  grid-column-gap: 112px;
  ${media.lg`
    grid-column-gap: 64px;
  `}
  ${media.md`
    padding: 0 80px;
    grid-template-columns: auto;
    grid-template-areas: "illustration"
                         "text";
    grid-row-gap: 64px;
  `}
  ${media.sm`
    padding: 0;
    grid-row-gap: 40px;
  `}
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-area: text;
  max-width: 620px;
  ${media.md`
    max-width: none;
  `}
  ${media.sm`
    hyphens: auto;
  `}
`

const Text = styled.div``

const IllustrationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: illustration;
`

const Illustration = styled.div`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: ${props => (props.small ? '280px' : '440px')};
  height: ${props => (props.small ? '280px' : '440px')};
  ${media.lg`
    width: ${props => (props.small ? '240px' : '360px')};
    height: ${props => (props.small ? '240px' : '360px')};
  `}
  ${media.sm`
    width: 240px;
    height: 240px;
  `}
  ${media.xs`
    width: 200px;
    height: 200px;
  `}
`

const TextWithIllustration = props => {
  return (
    <Section
      background={props.background}
      center
      size="md"
      id={props.id}
      noBottomPadding={props.noBottomPadding}
    >
      <GridContainer reverse={props.reverse}>
        <IllustrationWrapper>
          <Illustration
            src={props.illustrationUrl}
            small={props.smallIllustration}
          />
        </IllustrationWrapper>
        <TextWrapper>
          <Text>
            <Subtitle>{props.subtitle}</Subtitle>
            <h2>{props.title}</h2>
            {props.children}
          </Text>
        </TextWrapper>
      </GridContainer>
    </Section>
  )
}

TextWithIllustration.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  illustrationUrl: PropTypes.string.isRequired,
  noBottomPadding: PropTypes.bool,
  reverse: PropTypes.bool,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  smallIllustration: PropTypes.bool,
  id: PropTypes.string
}

export default TextWithIllustration
