import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { shadow } from 'styles/theme'

const StyledCard = styled.div`
  background: white;
  box-shadow: ${shadow.s3};
  border-radius: 10px;
  margin-top: 64px;
  padding: 80px;
  width: 100%;
  display: flex;
  ${media.md`
    flex-direction: column;
  `}
  ${media.xl`
    box-shadow: ${shadow.s2};
  `}
  ${media.lg`
    box-shadow: ${shadow.s1};
  `}
  ${media.sm`
    padding: 32px;
    margin-top: 32px;
  `}
`

const Card = props => {
  return <StyledCard className={props.className}>{props.children}</StyledCard>
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Card
