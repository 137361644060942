import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import media from 'utils/media-queries'

import { fontSize } from 'styles/theme'

import Link from 'components/link'
import Icon from 'components/icons'
import Section from 'components/section'
import CTAContainer from 'components/ctaContainer'
import Button from 'components/button'
import { SalesPhone } from 'components/contactInformation'

const LineBreak = styled.span`
  ${media.md`
    display: none;
  `}
`

const CTAInfo = styled.div``

const Price = styled.span`
  font-size: ${fontSize.f6};
  font-weight: 900;
  ${media.xl`
    font-size: ${fontSize.f5};
  `}
  ${media.md`
    font-size: ${fontSize.f4};
  `}
  ${media.sm`
    font-size: ${fontSize.f3};
  `}
`

const PriceWrapper = styled.div`
  text-align: left;
  ${media.sm`
    text-align: center;
  `}
`

const Ul = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
`

const Li = styled.li`
  display: flex;
  align-items: center;
`

const ListItemText = styled.span`
  padding-left: 8px;
  padding-top: 4px;
  text-align: left;
`

const ButtonWrapper = styled.div`
  margin: 16px 0;
  ${media.sm`
    margin: 24px 0;
  `}
`

const CTAButton = styled(Button)`
  margin: 8px 16px;
  ${media.sm`
    width: 100%;
    margin: 8px 0;
  `}
`

const PhoneWrapper = styled.div`
  a {
    font-weight: 900;
    color: white;
    &:visited {
      color: white;
    }
    &:hover {
      color: white;
    }
    &:active {
      color: white;
    }
  }
`

const CallToAction = props => {
  const { t } = useTranslation()
  return (
    <Section>
      <CTAContainer>
        <h2>
          {t('contentSections.callToAction.title1')}
          <LineBreak>
            <br />
          </LineBreak>
          {t('contentSections.callToAction.title2')}
        </h2>
        <CTAInfo>
          <PriceWrapper>
            <Price>{t('contentSections.callToAction.price')}</Price>
            <span>{t('contentSections.callToAction.priceUnit')}</span>
          </PriceWrapper>
          <Ul>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>
                {t('contentSections.callToAction.listItem1')}
              </ListItemText>
            </Li>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>
                {t('contentSections.callToAction.listItem2')}
              </ListItemText>
            </Li>
            <Li>
              <Icon glyph="small-checkmark" />
              <ListItemText>
                {t('contentSections.callToAction.listItem3')}
              </ListItemText>
            </Li>
          </Ul>
        </CTAInfo>
        <ButtonWrapper>
          <CTAButton buttonType="light" onClick={props.onClick}>
            {t('contentSections.callToAction.button1')}
          </CTAButton>
          <Link to="/order/plan">
            <CTAButton buttonType="outline">
              {t('contentSections.callToAction.button2')}
            </CTAButton>
          </Link>
        </ButtonWrapper>
        <Trans i18nKey="contentSections.callToAction.call" />
        <PhoneWrapper>
          <SalesPhone />
        </PhoneWrapper>
      </CTAContainer>
    </Section>
  )
}

CallToAction.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default CallToAction
