import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { Trans } from 'react-i18next'

import { color } from 'styles/theme'

import Link from 'components/link'

import LogoUrl from 'img/logo.svg'

const Section = styled.section`
  height: 96px;
  border-bottom: 1px solid ${color.grey150};
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1260px;
  padding: 0 0 0 32px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${media.md`
    padding: 0 32px;
  `}
  ${media.sm`
    padding: 0 16px;
  `}
`

const NavWrapper = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`

const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

const LeftNav = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  ${media.md`
    display: none;
  `}
`

const RightNav = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  ${media.md`
    display: none;
  `}
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const NavLink = styled(Link)`
  text-decoration: none;
  white-space: nowrap;
  padding: 6px 48px 0 48px;
  color: ${color.grey900};
  &:visited {
    color: ${color.grey900};
  }
  &:hover {
    color: ${color.blue500};
  }
  ${media.md`
    padding: 0;
    line-height: 3;
    display: block;
  `}
`

const SwitchLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 32px 0 32px;
  height: 100%;
  white-space: nowrap;
  border-left: 1px solid ${color.grey150};
  border-right: 1px solid ${color.grey150};
  &:nth-of-type(2) {
    border-left: none;
  }
`

const SwitchLink = styled(Link)`
  color: ${color.blue500};
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  &:visited {
    color: ${color.blue500};
  }
  &:hover {
    color: ${color.blue400};
  }
  ${media.md`
    line-height: 3;
    display: block;
  `}
`

const BurgerMenu = styled.div`
  height: 18px;
  width: 20px;
  justify-content: space-between;
  flex-direction: column;
  display: none;
  z-index: 12;
  ${media.md`display: flex;`}
  ${props => (props.sheetMenuOpen ? 'right: 16px;' : '')};
  cursor: pointer;
`

const BurgerMenuLine1 = styled.div`
  height: 2px;
  background: black;
  position: relative;
  transform: rotateZ(${props => (props.sheetMenuOpen ? '-45deg' : '0deg')})
    translateX(${props => (props.sheetMenuOpen ? '-7px' : '0')})
    translateY(${props => (props.sheetMenuOpen ? '4px' : '0')});
  width: ${props => (props.sheetMenuOpen ? '120%' : '100%')};
  transition: all 0.2s ease-out;
`

const BurgerMenuLine2 = styled.div`
  height: 2px;
  width: 100%;
  background: black;
  position: relative;
  display: ${props => (props.sheetMenuOpen ? 'none' : 'block')};
`

const BurgerMenuLine3 = styled.div`
  height: 2px;
  width: 100%;
  background: black;
  position: relative;
  transform: rotateZ(${props => (props.sheetMenuOpen ? '45deg' : '0deg')})
    translateX(${props => (props.sheetMenuOpen ? '-7px' : '0')})
    translateY(${props => (props.sheetMenuOpen ? '-4px' : '0')});
  width: ${props => (props.sheetMenuOpen ? '120%' : '100%')};
  transition: all 0.2s ease-out;
`

const Overlay = styled.div`
  background: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0;
  opacity: ${props => (props.sheetMenuOpen ? '.5' : '0')};
  z-index: 10;
  visibility: ${props => (props.sheetMenuOpen ? 'visible' : 'hidden')};
  transition: all 0.2s ease-out;
`

const SheetMenu = styled.div`
  background: white;
  padding: 80px 24px 24px 24px;
  height: 100%;
  width: 320px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 11;
  transform: translateX(${props => (props.sheetMenuOpen ? '0px' : '320px')});
  transition: all 0.2s ease-out;
  display: none;
  ${media.md`display: block;`};
`

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sheetMenuOpen: false
    }
  }

  toggleSheetMenu = () => {
    if (!this.state.sheetMenuOpen) {
      this.setState({ sheetMenuOpen: true })
    } else {
      this.setState({ sheetMenuOpen: false })
    }
  }

  hideSheetMenu = () => {
    this.setState({ sheetMenuOpen: false })
  }

  render() {
    return (
      <Fragment>
        <Overlay
          sheetMenuOpen={this.state.sheetMenuOpen}
          onClick={this.hideSheetMenu}
        />
        <SheetMenu sheetMenuOpen={this.state.sheetMenuOpen}>
          {this.props.pageType === 'doctor' && (
            <NavLink to="/">
              <Trans i18nKey="nav.forDoctors" />
            </NavLink>
          )}
          {this.props.pageType === 'doctor' && (
            <NavLink to="/order/plan">
              <Trans i18nKey="nav.plans" />
            </NavLink>
          )}
          {this.props.pageType === 'doctor' && (
            <NavLink to="/profile/login">
              <Trans i18nKey="nav.login" />
            </NavLink>
          )}
          {this.props.pageType === 'patient' && (
            <NavLink to="/lifetime-praxen">
              <Trans i18nKey="nav.findDoctors" />
            </NavLink>
          )}
          {(this.props.pageType === 'doctor' ||
            this.props.pageType === 'neutral') && (
            <SwitchLink to="/fuer-patienten">
              <Trans i18nKey="nav.forPatients" />
            </SwitchLink>
          )}
          {(this.props.pageType === 'patient' ||
            this.props.pageType === 'neutral') && (
            <SwitchLink to="/">
              <Trans i18nKey="nav.forDoctors" />
            </SwitchLink>
          )}
        </SheetMenu>
        <Section>
          <Container>
            <NavWrapper>
              <LeftContainer>
                {(this.props.pageType === 'doctor' ||
                  this.props.pageType === 'neutral') && (
                  <LogoLink to="/">
                    <img src={LogoUrl} />
                  </LogoLink>
                )}
                {this.props.pageType === 'patient' && (
                  <LogoLink to="/fuer-patienten">
                    <img src={LogoUrl} />
                  </LogoLink>
                )}
                <LeftNav>
                  {this.props.pageType === 'doctor' && (
                    <NavLink to="/order/plan">
                      <Trans i18nKey="nav.plans" />
                    </NavLink>
                  )}
                  {this.props.pageType === 'patient' && (
                    <NavLink to="/lifetime-praxen">
                      <Trans i18nKey="nav.findDoctors" />
                    </NavLink>
                  )}
                </LeftNav>
              </LeftContainer>
              <RightContainer>
                <RightNav>
                  {this.props.pageType === 'doctor' && (
                    <NavLink to="/profile/login">
                      <Trans i18nKey="nav.login" />
                    </NavLink>
                  )}
                  {this.props.pageType === 'doctor' && (
                    <SwitchLinkContainer>
                      <SwitchLink to="/fuer-patienten">
                        <Trans i18nKey="nav.forPatients" />
                      </SwitchLink>
                    </SwitchLinkContainer>
                  )}
                  {this.props.pageType === 'patient' && (
                    <SwitchLinkContainer>
                      <SwitchLink to="/">
                        <Trans i18nKey="nav.forDoctors" />
                      </SwitchLink>
                    </SwitchLinkContainer>
                  )}
                  {this.props.pageType === 'neutral' && (
                    <SwitchLinkContainer>
                      <SwitchLink to="/fuer-patienten">
                        <Trans i18nKey="nav.forPatientsShort" />
                      </SwitchLink>
                    </SwitchLinkContainer>
                  )}
                  {this.props.pageType === 'neutral' && (
                    <SwitchLinkContainer>
                      <SwitchLink to="/">
                        <Trans i18nKey="nav.forDoctorsShort" />
                      </SwitchLink>
                    </SwitchLinkContainer>
                  )}
                </RightNav>
                <BurgerMenu
                  sheetMenuOpen={this.state.sheetMenuOpen}
                  onClick={this.toggleSheetMenu}
                >
                  <BurgerMenuLine1 sheetMenuOpen={this.state.sheetMenuOpen} />
                  <BurgerMenuLine2 sheetMenuOpen={this.state.sheetMenuOpen} />
                  <BurgerMenuLine3 sheetMenuOpen={this.state.sheetMenuOpen} />
                </BurgerMenu>
              </RightContainer>
            </NavWrapper>
          </Container>
        </Section>
      </Fragment>
    )
  }
}

Nav.propTypes = {
  pageType: PropTypes.string
}

export default Nav
