import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { color } from 'styles/theme'
import Section from 'components/section'

import WinterhudeUrl from 'img/logos/praxisklinik-winterhude.png'
import BioscientiaUrl from 'img/logos/bioscientia.png'
import PraenatalzentrumUrl from 'img/logos/praenatalzentrum-stuttgart.png'
import HNOKoelnUrl from 'img/logos/hno-koeln.png'
import MarktapothekeUrl from 'img/logos/marktapotheke-eidelstedt.png'

const LogosContainer = styled.div`
  width: 100%;
`

const LogoHeading = styled.div`
  font-weight: 900;
  color: ${color.grey700};
`

const LogosWrapper = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  margin-top: 32px;
  align-items: center;
  justify-items: center;
  ${media.xs`
    grid-gap: 8px;
  `}
`

const Logo = styled.img`
  max-width: 160px;
  ${media.xs`
    max-width: 100px;
  `}
`

const Logos = props => {
  const { t } = useTranslation()
  return (
    <Section centerText noBottomPadding={props.noBottomPadding}>
      <LogosContainer>
        <LogoHeading>{t('logos.heading')}</LogoHeading>
        <LogosWrapper>
          <Logo src={BioscientiaUrl} />
          <Logo src={PraenatalzentrumUrl} />
          <Logo src={MarktapothekeUrl} />
          <Logo src={HNOKoelnUrl} />
          <Logo src={WinterhudeUrl} />
        </LogosWrapper>
      </LogosContainer>
    </Section>
  )
}

export default Logos

Logos.propTypes = {
  noBottomPadding: PropTypes.bool
}
