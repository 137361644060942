import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { useTranslation, Trans } from 'react-i18next'

import { color, shadow } from 'styles/theme'

import NoWrap from 'components/noWrap'
import Link from 'components/link'
import Nav from 'components/nav'
import Section from 'components/section'
import HeroText from 'components/hero/text'
import TextWithIllustration from 'components/textWithIllustration'
import CTAContainer from 'components/ctaContainer'
import Footer from 'components/footer'

import AppStoreUrl from 'img/app-store.svg'
import PlayStoreUrl from 'img/google-play.svg'
import PatientHeroUrl from 'img/patient-hero.jpg'
import PatientHeroPhoneUrl from 'img/patient-hero-phone.png'
import SecurityPhoneUrl from 'img/security-phone.png'
import MedicationPhoneUrl from 'img/medication-phone.png'
import Feature1Url from 'img/feature1.svg'
import Feature2Url from 'img/feature2.svg'
import Feature3Url from 'img/feature3.svg'

const StyledFeature = styled.div`
  display: flex;
  flex-direction: column;
  ${media.md`
    align-items: center;
    margin-bottom: 40px;
    padding: 0 80px;
  `}
  ${media.sm`
    padding: 0;
  `}
`

const Image = styled.div`
  width: 200px;
  height: 200px;
  background: ${color.grey100};
  border-radius: 10px;
  margin-bottom: 32px;
  background: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

const Title = styled.h2`
  margin: 0 0 24px 0;
  padding: 0;
  line-height: 1.2;
`

const Text = styled.div``

const Feature = props => {
  return (
    <StyledFeature>
      <Image url={props.image} />
      <Title>{props.title}</Title>
      <Text>{props.text}</Text>
    </StyledFeature>
  )
}

Feature.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
}

const Hero = styled.div`
  text-align: left;
  width: 100%;
  min-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 160px;
  ${media.xl`
    margin-bottom: 130px;    
  `}
  ${media.lg`
    grid-template-columns: 50% 50%;
    margin-bottom: 130px;
  `}
  ${media.md`
    grid-template-columns: auto;
  `}
  ${media.sm`
    margin-bottom: 60px;
  `}
`

const HeroTextContainer = styled.div`
  margin-bottom: 64px;
  ${media.sm`
    margin-bottom: 32px;
  `}
`

const StoreBadge = styled.div`
  width: 200px;
  height: 60px;
  background: url(${props => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  :first-of-type {
    margin-right: 16px;
  }
  ${media.xl`
    width: 180px;
    height: 54px;
  `}
  ${media.sm`
    width: 140px;
    height: 42px;
    :first-of-type{
      margin-bottom: 8px;
    }
  `}
`

const HeroImage1 = styled.div`
  width: 350px;
  height: 700px;
  background: ${color.grey150};
  background: url(${PatientHeroPhoneUrl});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-left: 140px;
  z-index: 1;
  ${media.lg`
    margin-left: 64px;
  `}
  ${media.md`
    height: 500px;
    width: 80%;
    left: 10%;
    margin-left: 0;
    position: absolute;
    bottom: 0;
  `}
  ${media.sm`
    height: 400px;
  `}
  ${media.xs`
    height: 400px;
  `}
`

const HeroImage2 = styled.div`
  width: 70%;
  height: 440px;
  background: url(${PatientHeroUrl});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  position: absolute;
  box-shadow: ${shadow.s1};
  top: 55%;
  ${media.lg`
    top: 54%;
  `}
  ${media.xl`
    top: 53%;
    background-position: 80% 27%;
    width: 65%;
    height: 420px;
  `}
  ${media.md`
    width: 100%;
    background-position: 80% 30%;
    position: static;
    margin-bottom: 240px;
  `}
  ${media.sm`
    margin-bottom: 170px;
  `}
`

const ThreeColumns = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 100px;
  grid-row-gap: 40px;
  margin-top: 48px;
  grid-auto-flow: column;
  ${media.lg`
    grid-column-gap: 64px;
  `}
  ${media.md`
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
  `}
  ${media.sm`
    margin-top: 32px;
    padding: 0;
    grid-row-gap: 32px;
  `}
`

const CtaText = styled.div`
  margin-bottom: 32px;
  ${media.sm`
    margin-bottom: 16px;
  `}
`

const AppStoreButton = () => (
  <a href="https://apps.apple.com/de/app/lifetime-medikamente-%C3%A4rzte/id981140831?mt=8">
    <StoreBadge image={AppStoreUrl} />
  </a>
)

const PlayStoreButton = () => (
  <a href="https://play.google.com/store/apps/details?id=eu.connectedhealth.lifetime&hl=de">
    <StoreBadge image={PlayStoreUrl} />
  </a>
)

const ReactPage = () => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <Nav pageType="patient" />
      <Section noBottomPadding>
        <Hero>
          <HeroTextContainer>
            <h1>
              <Trans i18nKey="pages.forPatients.title" />
            </h1>
            <HeroText>
              <Trans i18nKey="pages.forPatients.hero" />
            </HeroText>
            <AppStoreButton />
            <PlayStoreButton />
          </HeroTextContainer>
          <HeroImage2 />
          <HeroImage1 />
        </Hero>
      </Section>
      <TextWithIllustration
        subtitle={t('pages.forPatients.securitySubtitle')}
        title={
          <Trans
            i18nKey="pages.forPatients.securityTitle"
            components={[<NoWrap key="noWrapSecurityTitle" />]}
          />
        }
        illustrationUrl={SecurityPhoneUrl}
        background={color.grey50}
      >
        <Trans
          i18nKey="pages.forPatients.securityText"
          components={[<Link to="/lifetime-praxen" key="link" />]}
        />
      </TextWithIllustration>
      <Section>
        <ThreeColumns>
          <Feature
            image={Feature1Url}
            title={t('pages.forPatients.feature1Title')}
            text={t('pages.forPatients.feature1Text')}
          />
          <Feature
            image={Feature2Url}
            title={t('pages.forPatients.feature2Title')}
            text={t('pages.forPatients.feature2Text')}
          />
          <Feature
            image={Feature3Url}
            title={t('pages.forPatients.feature3Title')}
            text={t('pages.forPatients.feature3Text')}
          />
        </ThreeColumns>
      </Section>
      <TextWithIllustration
        subtitle={t('pages.forPatients.medicationSubtitle')}
        title={
          <Trans
            i18nKey="pages.forPatients.medicationTitle"
            components={[<NoWrap key="noWrapMedicationTitle" />]}
          />
        }
        illustrationUrl={MedicationPhoneUrl}
        reverse
        background={color.grey50}
      >
        <Trans i18nKey="pages.forPatients.medicationText" />
      </TextWithIllustration>
      <Section>
        <CTAContainer>
          <CtaText>
            <h2>{t('pages.forPatients.ctaTitle')}</h2>
            <p>{t('pages.forPatients.ctaText')}</p>
          </CtaText>
          <div>
            <AppStoreButton />
            <PlayStoreButton />
          </div>
        </CTAContainer>
      </Section>
      <Footer noTopPadding />
    </Fragment>
  )
}

export default ReactPage
