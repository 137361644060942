import Container from 'components/container'
import Section from 'components/section'
import APIUrl from 'img/api.jpg'
import ChatWindowUrl from 'img/chat-window.jpg'
import ChatUrl from 'img/chat.jpg'
import FaxUrl from 'img/fax.jpg'
import KIMUrl from 'img/kim-logo2.jpg'
import SerialLetterUrl from 'img/massenversand.jpg'
import PrinterUrl from 'img/printer.jpg'
import SMSUrl from 'img/sms.jpg'
import PostboxUrl from 'img/versandordner.jpg'
import PaperplaneUrl from 'img/paperplane.png'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { color, fontSize } from 'styles/theme'
import media from 'utils/media-queries'

const StyledSelect = styled.select`
  flex-grow: 1;
  width: 300px;
  font-weight: bold;
  color: ${color.grey900};
  ${media.lg`
    width: 200px;
  `}
  ${media.md`
    width: 100%;
  `}
`

const SelectWrapper = styled.div`
  position: relative;
  &:before {
    pointer-events: none;
    content: '';
    display: block;
    border: 0.4em solid transparent;
    border-top-color: ${color.grey700};
    position: absolute;
    right: 1.2em;
    top: 26px;
    z-index: 1;
  }
`

const SelectOuterWrapper1 = styled.div`
  grid-area: select1;
`

const SelectOuterWrapper2 = styled(SelectOuterWrapper1)`
  grid-area: select2;
`

const LifeTimeBox = styled.div`
  border: 2px solid ${color.blue500};
  color: ${color.blue500};
  height: 56px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 3px;
  font-weight: bold;
  font-size: ${fontSize.f5};
`

const FlexBottom = styled.div`
  display: flex;
  align-items: flex-end;
  grid-area: ${props => props.gridArea};
  ${media.md`
    display: none;
  `}
`

const GridContainer = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 32px;
  grid-template-rows: auto auto;
  grid-template-columns: 300px auto auto auto 300px;
  grid-template-areas:
    'select1 arrow1 lifetime arrow2 select2'
    'explainer1 spacer1 spacer2 spacer3 explainer2';
  ${media.lg`
    grid-template-columns: 200px auto auto auto 200px;
  `}
  ${media.md`
    grid-template-columns: auto;
    grid-template-areas:
      'select1'
      'explainer1'
      'select2'
      'explainer2';
  `}
`

const Center = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  ${media.md`
    width: 100%;
  `}
`

const Arrow = styled.div`
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${fontSize.f8};
  ${media.md`
    display: none;
  `}
`

const DetailImage = styled.div`
  background: url(${props => props.src}) no-repeat center center;
  background-size: contain;
  width: 100%;
  margin-bottom: 2rem;
  height: 180px;
  border-radius: 8px;
  overflow: hidden;
  ${media.lg`
    height: 150px;
  `}
`

const PaperPlaneImage = styled.div`
  background: url(${PaperplaneUrl}) no-repeat center center;
  background-size: contain;
  height: 100px;
  width: 100%;
  margin-bottom: 64px;
`

const Explainer1 = styled.div`
  grid-area: explainer1;
`

const Explainer2 = styled.div`
  grid-area: explainer2;
`

const Spacer1 = styled.div`
  grid-area: spacer1;
  ${media.md`
    display: none;
  `}
`

const Spacer2 = styled.div`
  grid-area: spacer2;
  ${media.md`
    display: none;
  `}
`

const Spacer3 = styled.div`
  grid-area: spacer3;
  ${media.md`
    display: none;
  `}
`

const WaysOfSending = props => {
  const [trigger, setTrigger] = useState('')
  const [channel, setChannel] = useState('')

  const handleTriggerChange = e => {
    setTrigger(e.target.value)
  }

  const handleChannelChange = e => {
    setChannel(e.target.value)
  }

  return (
    <Section id="channels">
      <Container>
        <PaperPlaneImage />
        <h2>LifeTime fügt sich nahtlos in Ihre Prozesse ein.</h2>
        <p>
          Wählen Sie zwischen den verschiedenen Versandmöglichkeiten, um zu
          erfahren, wie vielfältig LifeTime für die Kommunikation mit Patienten
          und anderen Einrichtungen eingesetzt werden kann.
        </p>
        <Center>
          <GridContainer>
            <SelectOuterWrapper1>
              <h3>Versand-Auslöser</h3>
              <SelectWrapper>
                <StyledSelect onChange={handleTriggerChange} value={trigger}>
                  <option value="">Option wählen</option>
                  <option value="Messenger-Fenster">Messenger-Fenster</option>
                  <option value="Versanddrucker">Versanddrucker</option>
                  <option value="Versandordner">Versandordner</option>
                  <option value="CSV-Massenversand">CSV-Massenversand</option>
                  <option value="API">API / PVS</option>
                </StyledSelect>
              </SelectWrapper>
            </SelectOuterWrapper1>
            <FlexBottom gridArea="arrow1">
              <Arrow>→</Arrow>
            </FlexBottom>
            <FlexBottom gridArea="lifetime">
              <LifeTimeBox>LifeTime</LifeTimeBox>
            </FlexBottom>
            <FlexBottom gridArea="arrow2">
              <Arrow>→</Arrow>
            </FlexBottom>
            <SelectOuterWrapper2>
              <h3>Versandweg</h3>
              <SelectWrapper>
                <StyledSelect onChange={handleChannelChange} value={channel}>
                  <option value="">Option wählen</option>
                  <option value="Sicherer Chat">Sicherer Chat</option>
                  <option value="KIM">KIM</option>
                  <option value="Fax">Fax</option>
                  <option value="SMS">SMS (Anleitung)</option>
                </StyledSelect>
              </SelectWrapper>
            </SelectOuterWrapper2>
            <Explainer1>
              {trigger === 'Messenger-Fenster' && (
                <div>
                  <DetailImage src={ChatWindowUrl} />
                  <p>
                    Empfänger wählen, Nachricht verfassen, Dokument anhängen –
                    so einfach geht datenschutzkonformer Versand mit dem
                    LifeTime Messenger.
                  </p>
                </div>
              )}
              {trigger === 'Versanddrucker' && (
                <div>
                  <DetailImage src={PrinterUrl} />
                  <p>
                    Mit dem LifeTime-Versanddrucker, können Sie aus jedem PVS,
                    Officeprogramm oder sonstiger Software über den
                    Standard-Druckdialog einen Versand auslösen. Anschließend
                    nur noch den Empfänger wählen und auf „Senden“ klicken.
                  </p>
                </div>
              )}
              {trigger === 'Versandordner' && (
                <div>
                  <DetailImage src={PostboxUrl} />
                  <p>
                    Der Versandordner befindet sich auf Ihrem Schreibtisch und
                    fungiert als digitaler Briefkasten.
                  </p>
                  <p>
                    Wenn Sie ein zu versendendes Dokument in den Versandordner
                    kopieren, öffnet sich automatisch ein Dialog für die
                    Empfängerauswahl.
                  </p>
                </div>
              )}
              {trigger === 'CSV-Massenversand' && (
                <div>
                  <DetailImage src={SerialLetterUrl} />
                  <p>
                    Mit dem LifeTime-Massenversand können Sie Nachrichten
                    gleichzeitig an hunderte Patienten senden. Legen Sie dafür
                    eine speziell formatierte .csv-Datei mit den Empfängerdaten
                    in den Versandordner auf dem Schreibtisch.
                  </p>
                  <p>
                    Dies eignet sich z.B. für die Übermittlung von
                    Impfeinladungen, Testergebnissen oder allgemeiner
                    Informationen an Ihre Patienten.
                  </p>
                </div>
              )}
              {trigger === 'API' && (
                <div>
                  <DetailImage src={APIUrl} />
                  <p>
                    Über die LifeTime-API lassen sich Dokumente automatisiert
                    versenden. Die Schnittstelle ist bereits in viele Programme,
                    wie z.B. Tomedo, Starnet oder mediDOK integriert.
                  </p>
                  <p>
                    Die API-Dokumentation finden Sie unter:{' '}
                    <a href="https://developer.lifetime.eu/v2/">
                      https://developer.lifetime.eu/v2/
                    </a>
                  </p>
                </div>
              )}
            </Explainer1>
            <Spacer1 />
            <Spacer2 />
            <Spacer3 />
            <Explainer2>
              {channel === 'Sicherer Chat' && (
                <div>
                  <DetailImage src={ChatUrl} />
                  <p>
                    Der sichere Chat ist der Ende-zu-Ende-verschlüsselte
                    Standard-Versandweg.
                  </p>
                  <p>
                    Kollegen erhalten Nachrichten und Dokumente DSGVO-konform
                    auf dem Praxisrechner. Patienten erhalten sie auf Ihrem
                    Smartphone. Daten werden ausschließlich lokal beim Empfänger
                    gespeichert.
                  </p>
                </div>
              )}
              {channel === 'KIM' && (
                <div>
                  <DetailImage src={KIMUrl} />
                  <p>
                    Mit dem Kommunikationsdienst KIM (Kommunikation im
                    Medizinwesen) können Sie medizinische Dokumente sicher über
                    die Telematikinfrastruktur (TI) versenden und empfangen.
                  </p>
                  <p>
                    Mit LifeTime versenden Sie KIM-eNachrichten so einfach wie
                    eine simple Chat-Nachricht. Über KIM versendete Dokumente
                    können abgerechnet werden.
                  </p>
                </div>
              )}
              {channel === 'Fax' && (
                <div>
                  <DetailImage src={FaxUrl} />
                  <p>
                    Wenn Ihr Empfänger weder LifeTime noch KIM nutzt, können
                    Ihre Nachrichten mit Anhängen optional auch einfach per Fax
                    zugestellt werden.
                  </p>
                </div>
              )}
              {channel === 'SMS' && (
                <div>
                  <DetailImage src={SMSUrl} />
                  <p>
                    Nutzt ein Patient LifeTime noch nicht, wird automatisch eine
                    Anleitung per SMS gesendet. Die Nachrichten werden
                    anschließend per Chat übertragen.
                  </p>
                  <p>
                    Beim Versand müssen Sie sich darüber keine Gedanken machen.
                    LifeTime informiert den Empfänger automatisch.
                  </p>
                </div>
              )}
            </Explainer2>
          </GridContainer>
        </Center>
      </Container>
    </Section>
  )
}

WaysOfSending.propTypes = {
  background: PropTypes.string
}

export default WaysOfSending
