import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { useTranslation } from 'react-i18next'

import Subtitle from 'components/subtitle'
import Section from 'components/section'
import EmbedContainer from 'components/embedContainer'

const CenterElements = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 100%;
  ${media.sm`
    text-align: left;
    align-items: flex-start;
  `}
`

const Video = props => {
  const { t } = useTranslation()
  return (
    <Section background={props.background}>
      <CenterElements>
        <Subtitle>{t('contentSections.video.subtitle')}</Subtitle>
        <h2>{t('contentSections.video.title')}</h2>
        <EmbedContainer />
      </CenterElements>
    </Section>
  )
}

Video.propTypes = {
  background: PropTypes.string
}

export default Video
