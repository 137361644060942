import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { useTranslation, Trans } from 'react-i18next'

import Section from 'components/section'
import Typewriter from 'components/typewriter'
import NoWrap from 'components/noWrap'
import Text from 'components/hero/text'
import Button from 'components/button'
import Card from 'components/card'
import List from 'components/list'
import Quotation from 'components/quotation'
import Logos from 'components/logos'

import ListStyleImageUrl from 'img/hartmannbund-list-style-image.svg'
import HartmannbundLogoSmallUrl from 'img/hartmannbund-logo-small.svg'
import DrVogtUrl from 'img/vogt.jpg'

const HartmannbundLogoSmall = styled.img`
  padding-right: 80px;
  max-height: 250px;
  object-fit: contain;
  ${media.md`
    padding-right: 0;
    padding-bottom: 80px;
  `}
  ${media.sm`
    max-height: 220px;
    padding-bottom: 40px;
  `}
`

const HartmannbundAdvantages = styled.div``

const Hero = props => {
  const { t } = useTranslation()
  return (
    <Section centerText background={props.background}>
      <h1>
        <Trans i18nKey="pages.hartmannbund.hero.title1" />
        <Typewriter />
        <Trans i18nKey="pages.hartmannbund.hero.title2" />
      </h1>
      <Text>
        {t('pages.hartmannbund.hero.text1')}
        <NoWrap>{t('pages.hartmannbund.hero.text2')}</NoWrap>
      </Text>
      <Button type="button" onClick={props.onClick}>
        {t('pages.hartmannbund.hero.button')}
      </Button>
      <Card>
        <HartmannbundLogoSmall src={HartmannbundLogoSmallUrl} />
        <HartmannbundAdvantages>
          <h3>{t('pages.hartmannbund.hero.advantagesTitle')}</h3>
          <List
            listStyleImage={ListStyleImageUrl}
            items={[
              t('pages.hartmannbund.hero.advantage1'),
              t('pages.hartmannbund.hero.advantage2'),
              t('pages.hartmannbund.hero.advantage3')
            ]}
          />
        </HartmannbundAdvantages>
      </Card>
      <Quotation
        avatar={DrVogtUrl}
        text={t('pages.hartmannbund.hero.quotationText')}
        name={t('pages.hartmannbund.hero.quotationName')}
        role={t('pages.hartmannbund.hero.quotationRole')}
      />
      <Logos />
    </Section>
  )
}

Hero.propTypes = {
  background: PropTypes.string,
  onClick: PropTypes.func
}

export default Hero
