import Reimbursement from 'components/contentSections/reimbursement'
import Footer from 'components/footer'
import React, { Fragment } from 'react'
import { color } from 'styles/theme'
import Advantages from './advantages'
import CallToAction from './callToAction'
import Header from './header'
import Hero from './hero'
import Logos from 'components/logos'

class ReactPage extends React.Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Hero />
        <Logos />
        <Reimbursement background={color.grey50} brandName="LifeTime-KIM" />
        <Advantages />
        <CallToAction />
        <Footer noTopPadding />
      </Fragment>
    )
  }
}
export default ReactPage
