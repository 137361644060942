export const color = {
  blue800: '#113B50',
  blue700: '#094C6D',
  blue600: '#006494',
  blue500: '#087FB9',
  blue400: '#2597D0',
  blue300: '#4CB3E7',
  blue200: '#8CD3F6',
  blue100: '#B7E4FA',
  blue50: '#E3F6FF',
  blue25: '#F1FAFF',
  grey900: '#1B1B1B',
  grey800: '#2B2B2B',
  grey700: '#414141',
  grey600: '#595959',
  grey500: '#737373',
  grey400: '#8F8F8F',
  grey300: '#A9A9A9',
  grey200: '#C4C4C4',
  grey150: '#DDDDDD',
  grey100: '#EDEDED',
  grey50: '#F9F9F9',
  grey25: '#FCFCFC',
  okgreen700: '#0E7500',
  okgreen600: '#2AAA19',
  okgreen500: '#5DD24D',
  okgreen200: '#CCFFC5',
  okgreen50: '#F1FFEF',
  warningred700: '#CC2020',
  warningred600: '#E74242',
  warningred500: '#FF7777',
  warningred200: '#FFE7E7',
  warningred50: '#FFF7F7'
}

export const shadow = {
  s3: '0px 16px 80px -24px rgba(0, 0, 0, 0.3)',
  s2: '0px 12px 64px -20px rgba(0, 0, 0, 0.25)',
  s1: '0px 8px 40px -16px rgba(0, 0, 0, 0.2)',
  s0: '0px 8px 40px -16px rgba(0, 0, 0, 0.5)'
}

export const fontSize = {
  f11: '58px',
  f10: '48px',
  f9: '42px',
  f8: '36px',
  f7: '30px',
  f6: '24px',
  f5: '20px',
  f4: '18px',
  f3: '16px',
  f2: '14px',
  f1: '12px'
}
