import React, { Fragment } from 'react'
import 'components/i18n'

import GlobalStyle from 'styles/global-styles'

const withGlobalWrapper = ReactPage => {
  return class WrappedReactPage extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        displayOutlines: false
      }
    }

    componentDidMount() {
      window.addEventListener('keydown', e => this.handleKeyboardInput(e))
    }

    handleKeyboardInput(e) {
      const key = e.keyCode || e.charCode
      // Tab
      if (key === 9) {
        this.setState({ displayOutlines: true })
      }
    }

    render() {
      return (
        <Fragment>
          <GlobalStyle displayOutlines={this.state.displayOutlines} />
          <ReactPage />
        </Fragment>
      )
    }
  }
}

export default withGlobalWrapper
