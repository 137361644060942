import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { color, fontSize } from 'styles/theme'

import LifeTimeLogoUrl from 'img/logo.svg'
import HartmannbundLogoUrl from 'img/hartmannbund-logo.svg'

const Section = styled.section`
  height: 80px;
  border-bottom: 1px solid ${color.grey150};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  max-height: 42px;
  ${media.sm`
    max-height: 32px;
  `}
`

const Plus = styled.span`
  font-size: ${fontSize.f3};
  margin: 0 32px;
  font-weight: 900;
  ${media.sm`
    font-size: ${fontSize.f2};
    margin: 0 8px;
  `}
`

const Header = () => {
  return (
    <Section>
      <Logo src={LifeTimeLogoUrl} />
      <Plus>+</Plus>
      <Logo src={HartmannbundLogoUrl} />
    </Section>
  )
}

export default Header
