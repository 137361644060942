import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { fontSize } from 'styles/theme'
import { useTranslation, Trans } from 'react-i18next'

import Section from 'components/section'
import Typewriter from 'components/typewriter'
import Card from 'components/card'
import List from 'components/list'
import Button from 'components/button'
import Logos from 'components/logos'

import AkquinetLogoUrl from 'img/akquinet-logo.svg'
import ListStyleImageUrl from 'img/list-style-image.svg'

const AkquinetLogoSmall = styled.img`
  padding-right: 100px;
  max-height: 250px;
  max-width: 350px;
  object-fit: contain;
  ${media.md`
    padding-right: 0;
    padding-bottom: 80px;
  `}
  ${media.sm`
    max-height: 220px;
    padding-bottom: 40px;
  `}
`

const StyledText = styled.div`
  font-size: ${fontSize.f5};
  line-height: 1.3;
  margin-bottom: 48px;
  max-width: 750px;
  ${media.md`
    font-size: ${fontSize.f4};
  `}
  ${media.sm`
    hyphens: auto;
    margin-bottom: 32px;
  `}
`

const CenteredCard = styled(Card)`
  justify-content: center;
`

const TomedoAdvantages = styled.div`
  text-align: left;
`

const Hero = props => {
  const { t } = useTranslation()
  return (
    <Section centerText>
      <h1>
        <Trans i18nKey="pages.tomedo.hero.title1" />
        <Typewriter />
        <Trans i18nKey="pages.tomedo.hero.title2" />
      </h1>
      <StyledText>
        Die einfachste Lösung für die datenschutzkonforme Kommunikation mit
        Patienten und anderen medizinischen Einrichtungen. Versenden Sie
        Dokumente und Nachrichten blitzschnell digital{' '}
        <em>mit LifeTime und KIM</em>.
      </StyledText>
      <Button type="button" onClick={props.onClick}>
        {t('pages.tomedo.hero.button')}
      </Button>
      <CenteredCard>
        <AkquinetLogoSmall src={AkquinetLogoUrl} />
        <TomedoAdvantages>
          <h3>Ihre Vorteile:</h3>
          <List
            listStyleImage={ListStyleImageUrl}
            items={[
              'Einheitlicher und unkomplizierter Versandweg',
              'Ende-zu-Ende verschlüsselte Übertragung',
              'Mit allen Systemen kompatibel'
            ]}
          />
        </TomedoAdvantages>
      </CenteredCard>
      <Logos />
    </Section>
  )
}

Hero.propTypes = {
  onClick: PropTypes.func
}

export default Hero
