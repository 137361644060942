import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { fontSize } from 'styles/theme'

const StyledText = styled.div`
  font-size: ${fontSize.f4};
  line-height: 1.3;
  margin-bottom: 48px;
  max-width: 864px;
  ${media.md`
    font-size: ${fontSize.f3};
  `}
  ${media.sm`
    hyphens: auto;
    margin-bottom: 32px;
  `}
`

const Text = props => {
  return <StyledText>{props.children}</StyledText>
}

Text.propTypes = {
  children: PropTypes.node.isRequired
}

export default Text
