import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { useTranslation, Trans } from 'react-i18next'

import Section from 'components/section'
import Subtitle from 'components/subtitle'

import SecurityIllustrationUrl from 'img/security-illustration.svg'

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 100%;
  ${media.md`
    flex-direction: column-reverse;
    align-items: center;
  `}
`

const Text = styled.div`
  ${media.md`
    padding: 0 80px;
  `}
  ${media.sm`
    padding: 0;
  `}
`

const SecurityIllustration = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  padding-left: 160px;
  align-self: center;
  ${media.lg`
    padding-left: 80px;
  `}
  ${media.md`
    padding-left: 0px;
    padding-bottom: 80px;
    max-height: 400px;
  `}
  ${media.sm`
    max-height: 250px;
    padding-bottom: 32px;
  `}
`

const Security = props => {
  const { t } = useTranslation()
  return (
    <Section background={props.background} size="md">
      <FlexContainer>
        <Text>
          <Subtitle>{t('contentSections.security.subtitle')}</Subtitle>
          <h2>
            <Trans i18nKey="contentSections.security.title" />
          </h2>
          <Trans i18nKey="contentSections.security.text" />
        </Text>
        <SecurityIllustration src={SecurityIllustrationUrl} />
      </FlexContainer>
    </Section>
  )
}

Security.propTypes = {
  background: PropTypes.string
}

export default Security
