import React, { Fragment } from 'react'
import styled from 'styled-components'

import { color } from 'styles/theme'

import i18n from 'components/i18n'

const TickerText = styled.span`
  color: ${color.blue500};
`

const Cursor = styled.span`
  border-right: 3px solid ${color.grey600};
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 300ms;
`

class Typewriter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayedString: this.words[0],
      displayedLetterCount: 1,
      activeWordIndex: 0,
      cursorVisible: true
    }
  }

  words = [
    i18n.t('typewriter.text1'),
    i18n.t('typewriter.text2'),
    i18n.t('typewriter.text3'),
    i18n.t('typewriter.text4'),
    i18n.t('typewriter.text5')
  ]

  // Animation config
  holdTime = 3000
  tickTime = 50
  cursorBlinkRate = 600

  componentDidMount() {
    setTimeout(() => {
      this.deleteLetter()
    }, this.holdTime)
    setTimeout(() => {
      this.blinkCursor()
    }, this.cursorBlinkRate)
  }

  blinkCursor = () => {
    this.setState({ cursorVisible: !this.state.cursorVisible }, () =>
      setTimeout(this.blinkCursor, this.cursorBlinkRate)
    )
  }

  deleteLetter = () => {
    if (this.state.displayedString.length > 0) {
      const newString = this.state.displayedString.slice(0, -1)
      this.setState({ displayedString: newString }, () =>
        setTimeout(this.deleteLetter, this.tickTime)
      )
    } else {
      const activeWordIndex = this.state.activeWordIndex
      if (activeWordIndex < this.words.length - 1) {
        this.setState(
          { activeWordIndex: activeWordIndex + 1, displayedLetterCount: 0 },
          () => setTimeout(this.addLetter, this.tickTime)
        )
      } else {
        this.setState({ activeWordIndex: 0, displayedLetterCount: 0 }, () =>
          setTimeout(this.addLetter, this.tickTime)
        )
      }
    }
  }

  addLetter = () => {
    if (
      this.state.displayedString.length <
      this.words[this.state.activeWordIndex].length
    ) {
      const displayedLetterCount = this.state.displayedLetterCount
      const newString = this.words[this.state.activeWordIndex].substring(
        0,
        displayedLetterCount
      )
      this.setState(
        {
          displayedString: newString,
          displayedLetterCount: displayedLetterCount + 1
        },
        () => setTimeout(this.addLetter, this.tickTime)
      )
    } else {
      setTimeout(() => {
        this.deleteLetter()
      }, this.holdTime)
    }
  }

  render() {
    return (
      <Fragment>
        <TickerText>{this.state.displayedString}</TickerText>
        <Cursor visible={this.state.cursorVisible} />
      </Fragment>
    )
  }
}

export default Typewriter
