import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import media from 'utils/media-queries'

import { color, fontSize } from 'styles/theme'

const buttonStyles = {
  default: css`
    background: ${color.blue500};
    &:hover {
      background: ${color.blue400};
      border: 2px solid ${color.blue400};
    }
    color: white;
    border: 2px solid ${color.blue500};
    padding: 16px 72px;
    font-size: ${fontSize.f5};
    font-weight: 900;
    border-radius: 3px;
    transition: all 0.3s;
    ${media.xl`
      padding: 16px 56px;
    `}
    ${media.sm`
      padding: 16px 40px;
    `}
    ${media.xs`
      padding: 8px;
    `}
  `,
  dark: css`
    background: ${color.blue700};
    &:hover {
      background: ${color.blue600};
      border: 2px solid ${color.blue600};
    }
    color: ${color.white};
    border: 2px solid ${color.blue700};
  `,
  light: css`
    background: white;
    &:hover {
      background: ${color.blue100};
      border: 2px solid ${color.blue100};
    }
    color: ${color.blue500};
    border: 2px solid white;
  `,
  outline: css`
    background: transparent;
    &:hover {
      background: ${color.blue400};
      border: 2px solid ${color.blue400};
    }
    color: white;
    border: 2px solid white;
  `,
  darkOutline: css`
    background: transparent;
    &:hover {
      background: ${color.blue50};
    }
    color: ${color.blue500};
    border: 2px solid ${color.blue500};
    box-sizing: border-box;
  `
}

const StyledButton = styled.button`
  ${buttonStyles.default};
  ${props => buttonStyles[props.buttonType]};
`

class Button extends React.Component {
  handleClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  render() {
    return (
      <StyledButton
        className={this.props.className}
        onClick={e => this.handleClick(e)}
        buttonType={this.props.buttonType}
      >
        {this.props.children}
      </StyledButton>
    )
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  buttonType: PropTypes.oneOf([
    'dark',
    'light',
    'outline',
    'darkOutline',
    'default'
  ])
}

export default Button
