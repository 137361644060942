import React, { Fragment } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { color } from 'styles/theme'
import media from 'utils/media-queries'

import Logos from 'components/logos'
import Dialog from 'components/dialog/'
import Nav from 'components/nav'
import Hero from './hero'
import TextWithIllustration from 'components/textWithIllustration'
import TestNow from './testNow'
import Security from 'components/contentSections/security'
import References from 'components/contentSections/references'
import CallToAction from 'components/contentSections/callToAction'
import Reimbursement from 'components/contentSections/reimbursement'
import Advantages from './advantages'
import WaysOfSending from './waysOfSending'
import Footer from 'components/footer'

import KimFaxChatUrl from 'img/kim-fax-chat.png'
import RetentionIllustrationUrl from 'img/retention-illustration.png'

import LifeTimeUIUrl from 'img/lifetime-ui.svg'
import LifeTimeUIUrl2 from 'img/lifetime-ui2.svg'

import BlueSwirl2Url from 'img/blue-swirl2.svg'

import { googleAnalyticsTracker } from 'utils/tracking'

const LifeTimeUISection = styled.section`
  background-image: url(${BlueSwirl2Url});
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: ${color.blue25};
  padding-top: 96px;
  margin-top: 16px;
  ${media.md`
    display: none;
  `}
`

const LifeTimeUIImg = styled.div`
  background: url(${LifeTimeUIUrl});
  height: 900px;
  width: calc(100% - 40px);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  ${media.lg`
    height: 700px;
  `}
`

const LifeTimeUISectionMobile = styled.section`
  background-image: url(${BlueSwirl2Url});
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: ${color.blue25};
  display: none;
  margin-top: 24px;
  ${media.md`
    display: block;
  `}
`

const LifeTimeUIImg2 = styled.div`
  background: url(${LifeTimeUIUrl2});
  height: 900px;
  width: 100%;
  background-size: contain;
  background-position: center 20%;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  ${media.md`
    height: 700px;
    background-size: cover;
    background-position: 0% 20%;
  `}
  ${media.sm`
    height: 500px;
  `}
  ${media.xs`
    height: 400px;
  `}
`

class ReactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false
    }
  }

  openDialog = e => {
    googleAnalyticsTracker.trackCTAButtonClick(e)
    this.setState({ dialogIsOpen: true })
  }

  closeDialog = () => {
    this.setState({ dialogIsOpen: false })
  }

  render() {
    return (
      <Fragment>
        <Nav pageType="doctor" />
        <Dialog
          isOpen={this.state.dialogIsOpen}
          closeDialog={this.closeDialog}
        />
        <Hero onClick={this.openDialog} />
        <LifeTimeUISection>
          <LifeTimeUIImg />
        </LifeTimeUISection>
        <LifeTimeUISectionMobile>
          <LifeTimeUIImg2 />
        </LifeTimeUISectionMobile>
        <Advantages />
        <Logos noBottomPadding />
        <WaysOfSending />
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.forDoctors.softwareSubtitle" />}
          title={<Trans i18nKey="pages.forDoctors.softwareTitle" />}
          illustrationUrl={KimFaxChatUrl}
          background={color.grey50}
        >
          <Trans i18nKey="pages.forDoctors.softwareText" />
        </TextWithIllustration>
        <TestNow background={color.blue500} />
        <Security />
        <Reimbursement background={color.grey50} />
        <TextWithIllustration
          subtitle={<Trans i18nKey="pages.digitalOffice.loyalitySubtitle" />}
          title={<Trans i18nKey="pages.digitalOffice.loyalityTitle" />}
          illustrationUrl={RetentionIllustrationUrl}
          reverse
        >
          {<Trans i18nKey="pages.digitalOffice.loyalityText" />}
        </TextWithIllustration>
        <References background={color.grey50} />
        <CallToAction onClick={this.openDialog} />
        <Footer noTopPadding />
      </Fragment>
    )
  }
}
export default ReactPage
