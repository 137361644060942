import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

import Container from 'components/container'

const StyledSection = styled.section`
  background: ${props => props.background};
  display: flex;
  justify-content: center;
  padding: ${props => (props.noBottomPadding ? '96px 0 0 0' : '96px 0')};
  ${media.xl`
    padding: ${props => (props.noBottomPadding ? '80px 0 0 0' : '80px 0')};
  `}
  ${media.sm`
    padding: ${props => (props.noBottomPadding ? '40px 0 0 0' : '40px 0')};
  `}
`

const Section = props => {
  return (
    <StyledSection
      background={props.background}
      className={props.className}
      noBottomPadding={props.noBottomPadding}
      id={props.id}
    >
      <Container centerText={props.centerText} size={props.size}>
        {props.children}
      </Container>
    </StyledSection>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  centerText: PropTypes.bool,
  footer: PropTypes.bool,
  className: PropTypes.string,
  noBottomPadding: PropTypes.bool,
  size: PropTypes.string,
  id: PropTypes.string
}

export default Section
