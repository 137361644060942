import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { fontSize } from 'styles/theme'

const StyledList = styled.div`
  text-align: left;
  margin-top: 32px;
  font-size: ${fontSize.f3};
  ${media.sm`
    font-size: ${fontSize.f3};
  `}
`

const ListEntry = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.narrow ? '0' : '28px')};
  &:last-child {
    margin-bottom: 0px;
  }
`

const ListEntryText = styled.div`
  display: inline;
  padding-top: 5px;
`

const ListEntryIcon = styled.img`
  display: inline;
  margin-right: 32px;
`

const List = props => {
  return (
    <StyledList>
      {props.items &&
        props.items.map((item, index) => (
          <ListEntry key={'ListEntry' + index} narrow={props.narrow}>
            <ListEntryIcon src={props.listStyleImage} />
            <ListEntryText>{item}</ListEntryText>
          </ListEntry>
        ))}
    </StyledList>
  )
}

List.propTypes = {
  items: PropTypes.array.isRequired,
  listStyleImage: PropTypes.string.isRequired,
  narrow: PropTypes.bool
}

export default List
