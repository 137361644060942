import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { color, shadow } from 'styles/theme'

const EmbedWrapper = styled.div`
  min-width: 100%;
`

const IFrameContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
  margin: 0;
  background: ${color.grey50};
  border-radius: 10px;
  margin-top: 64px;
  box-shadow: ${shadow.s3};
  ${media.xl`
    box-shadow: ${shadow.s2};
  `}
  ${media.lg`
    box-shadow: ${shadow.s1};
  `}
  ${media.sm`
    margin-top: 16px;
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    border-radius: 0;
  `}
`

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const EmbedContainer = () => {
  return (
    <EmbedWrapper>
      <IFrameContainer>
        <IFrame
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dSeNl6t6ysc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </IFrameContainer>
    </EmbedWrapper>
  )
}

export default EmbedContainer
