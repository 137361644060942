import { createGlobalStyle } from 'styled-components'
import media from 'utils/media-queries'

import { color, fontSize } from 'styles/theme'

const GlobalStyle = createGlobalStyle`

  /* normalize.css */
  html{line-height:1.15;-webkit-text-size-adjust:100%}body{margin:0}main{display:block}h1{font-size:2em;margin:.67em 0}hr{box-sizing:content-box;height:0;overflow:visible}pre{font-family:monospace,monospace;font-size:1em}a{background-color:transparent}abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}b,strong{font-weight:bolder}code,kbd,samp{font-family:monospace,monospace;font-size:1em}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}img{border-style:none}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,input{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}progress{vertical-align:baseline}textarea{overflow:auto}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}details{display:block}summary{display:list-item}template{display:none}[hidden]{display:none}

  html {
    height: 100%;
    font-family: 'avenir';
    -webkit-tap-highlight-color: transparent;
  }

  body {
    height: 100%;
    color: ${color.grey900};
    font-feature-settings: "liga","kern";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    font-size: ${fontSize.f3};
    ${media.sm`
      font-size: ${fontSize.f3};
      line-height: 1.4;
    `}
    -webkit-tap-highlight-color: transparent;
  }

  h1 {
    font-size: ${fontSize.f9};
    font-weight: 900;
    margin: 0 0 32px 0;
    padding: 0;
    line-height: 1.2;
    ${media.xl`
      font-size: ${fontSize.f9};
      line-height: 1.2;
    `}
    ${media.md`
      font-size: ${fontSize.f8};
    `}
    ${media.sm`
      font-size: ${fontSize.f7};
    `}
  }

  h2 {
    font-size: ${fontSize.f8};
    font-weight: 900;
    margin: 0 0 24px 0;
    padding: 0;
    line-height: 1.2;
    ${media.xl`
      font-size: ${fontSize.f7};
    `}
    ${media.md`
      font-size: ${fontSize.f6};
    `}
    ${media.sm`
      font-size: ${fontSize.f5};
    `}
  }

  h3 {
    font-size: ${fontSize.f5};
    font-weight: 900;
    margin: 0 0 16px 0;
    padding: 0;
    line-height: 1.2;
    ${media.sm`
      font-size: ${fontSize.f4};
    `}
  }

  h4 {
    font-size: ${fontSize.f4};
    font-weight: 900;
    margin: 0 0 12px 0;
    padding: 0;
    line-height: 1.2;
    ${media.md`
      font-size: ${fontSize.f3};
    `}
    ${media.sm`
      font-size: ${fontSize.f3};
    `}
  }

  p {
    font-size: ${fontSize.f3};
    line-height: 1.5;
    ${media.sm`
      font-size: ${fontSize.f3};
      line-height: 1.4;
    `}
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-top: 0;
    }
  }

  a {
    color: ${color.grey900};
    :hover {
      color: ${color.blue500};
    }
    :visited {
      color: ${color.grey900};
    }
    :active {
      color: ${color.blue500};
    }
  }

  img, svg, video {
    user-select: none;
  }

  em {
    font-style: normal;
    font-weight: 900;
  }

  div {
    box-sizing: border-box;
  }

  button {
    outline: ${props => (props.displayOutlines ? '' : 'none')};
  }

  select {
    border: 1px solid ${color.grey400};
    height: 56px;
    outline: ${props => (props.displayOutlines ? '' : 'none')};
  }

  input {
    appearance: none;
    height: 56px;
    border-radius: 3px;
    border:1px solid ${color.grey400};
    width: 100%;
    padding: 0 16px;
    outline: ${props => (props.displayOutlines ? '' : 'none')};
  }

  ::selection {
    background: rgba(37, 151, 208, .99);
    color: white;
  }
`

export default GlobalStyle
