import React, { Fragment } from 'react'

import Nav from 'components/nav'
import Footer from 'components/footer'
import Section from 'components/section'
import PlainText from 'components/plainText'

import { SupportEmail } from 'components/contactInformation'

const print = () => {
  window.print()
  return false
}

const ReactPage = () => {
  return (
    <Fragment>
      <Nav pageType="neutral" />
      <Section size="sm">
        <PlainText>
          <div>
            <h1>
              Allgemeine Geschäftsbedingungen
              <br />
              der LifeTime GmbH
            </h1>
            <div>
              <div>
                <p>
                  Herzlich willkommen bei <strong>LifeTime™ für Ärzte</strong>!
                </p>
                <p>
                  Nachfolgend finden Sie unsere Allgemeinen Geschäftsbedingungen
                  („
                  <strong>AGB</strong>“) für Vertragsverhältnisse zwischen der
                  LifeTime GmbH und Ärzten, die unser Produkt LifeTime™ in Ihrer
                  Praxis einsetzen möchten (zum Drucken der AGB klicken Sie{' '}
                  <a href="#" onClick={print}>
                    hier
                  </a>
                  ). Bitte lesen Sie diese sowie unsere Datenschutzbestimmungen
                  (
                  <a href="https://lifetime.eu/privacypolicy">
                    https://lifetime.eu/privacypolicy
                  </a>
                  ) sorgfältig durch.
                </p>
              </div>
              <strong>
                <br clear="all" />
              </strong>
              <h2>1. Geltungsbereich</h2>
              <p>
                (1) Durch die Bestellung des Produkts LifeTime™ sowie der
                Registrierung in dem von uns unter LifeTime.eu angebotenen
                Login-Bereich für Ärzte unter LifeTime.eu und dessen Nutzung
                gehen Sie („<strong>Kunde</strong>
                “, „<strong>Nutzer</strong>“ oder „<strong>Sie</strong>“) einen
                bindenden Vertrag mit uns, der
              </p>
              <p>
                <strong>LifeTime GmbH</strong>
                <br />
                Rehmkamp 1; 24161 Altenholz
                <br />
                Geschäftsführer: Dr. André Kaeding
                <br />
                Amtsgericht Kiel, HRB 24338 KI
                <br />
                <SupportEmail />
              </p>
              <p>
                („<strong>LIFETIME</strong>“ oder „<strong>wir</strong>
                “) ein, für den diese AGB gelten.
              </p>
              <p>
                (2) Alle zwischen Ihnen und uns im Zusammenhang mit der Nutzung
                von LifeTime™ getroffenen Vereinbarungen ergeben sich
                insbesondere aus diesen AGB, unserer Auftragsbestätigung bzw.
                unserer Annahmeerklärung.
              </p>
              <p>
                (3) Maßgebend ist die jeweils bei Abschluss des Vertrags gültige
                Fassung der AGB.
              </p>
              <p>
                (4) Abweichende Bedingungen des Nutzers akzeptieren wir nicht.
                Dies gilt auch, wenn wir der Einbeziehung nicht ausdrücklich
                widersprechen.
              </p>
              <h2>
                2. Nutzung von LifeTime™, Einwilligung in Veröffentlichung
              </h2>
              <p>
                (1) Gegenstand unseres Leistungsangebots ist die Nutzung des
                Produktes LifeTime™, das eine verschlüsselte, digitale
                Kommunikation zwischen Ihnen, Ihren Kollegen und Ihren Patienten
                ermöglicht.
              </p>
              <p>
                (2) LifeTime™ für Ärzte erfordert das Vorhalten unserer
                LifeTime™ Software. Die Kommunikation mit dem Patienten erfolgt
                über die LifeTime™ App auf dessen Mobiltelefon. Die LifeTime™
                App ist nicht Gegenstand dieser AGB. Bei dem Produkt LifeTime™
                handelt es sich nicht um ein Medizinprodukt.
              </p>
              <p>
                (3) Wir bieten LifeTime™ für Ärzte im Abonnement an (siehe zum
                Leistungsangebot Ziffer 5). Sobald die Bestellung abgeschlossen
                ist (siehe zum Vertragsschluss Ziffer 3), wird die Registrierung
                in unserem Login-Bereich freigeschaltet, über den Sie einen
                persönlichen Account anlegen sollen. Die Registrierung ist
                erforderlich, um LifeTime™ nutzen zu können. Die LifeTime™
                Software wird Ihnen nach der Registrierung freigeschaltet.
              </p>
              <p>
                (4) Die von Ihnen hinterlegten Account-Daten werden auf
                LifeTime.eu unter der Rubrik „Arzt finden“ und in der LifeTime™
                App veröffentlicht. Der Nutzer erklärt sich hiermit damit
                einverstanden, öffentlich als Nutzer genannt zu werden.
              </p>
              <h2>3. Vertragsschluss</h2>
              <p>
                (1) Die Präsentation und Bewerbung des Leistungsangebots auf
                LifeTime.eu stellt kein bindendes Angebot zum Abschluss eines
                Kaufvertrags dar.
              </p>
              <p>
                (2) Mit dem Absenden einer Bestellung über LifeTime.eu durch
                Anklicken des Buttons „Jetzt bestellen“ geben Sie eine
                rechtsverbindliche Bestellung ab. Sie sind an die Bestellung für
                die Dauer von zwei (2) Wochen nach Abgabe der Bestellung
                gebunden.
              </p>
              <p>
                (3) Wir werden den Zugang Ihrer über LifeTime.eu, per E-Mail
                oder Telefon abgegebenen Bestellung unverzüglich per E-Mail
                bestätigen. In einer solchen E-Mail liegt noch keine
                verbindliche Annahme der Bestellung, es sei denn, darin wird
                neben der Bestätigung des Zugangs zugleich die Annahme gemäß
                Ziffer 3(4) erklärt.
              </p>
              <p>
                (4) Ein Vertrag kommt erst zustande, wenn wir Ihre Bestellung
                durch eine Annahmeerklärung annehmen. Diese erhalten Sie in der
                Regel per E-Mail. Wird der Vertrag ohne Einschaltung von
                Fernkommunikationsmitteln geschlossen, gilt das Datum der
                letzten Unterschrift als Datum des Vertragsschlusses. Ein
                Vertrag kommt in jedem Fall spätestens zustande, wenn nach der
                Freischaltung des Login-Bereichs die Einrichtung des Accounts
                erfolgt ist.
              </p>
              <p>
                (5) Sollten die von Ihnen angefragten Leistungsangebote nicht
                verfügbar sein, sehen wir von einer Annahmeerklärung ab. In
                diesem Fall kommt ein Vertrag nicht zustande. Wir werden Sie
                darüber unverzüglich informieren und bereits erhaltene
                Gegenleistungen unverzüglich zurückerstatten.
              </p>
              <p>(6) Die Vertragssprache ist Deutsch.</p>
              <h2>4. Vertragsänderungen</h2>
              <p>
                (1) Wir behalten uns vor, diese AGB sowie die auf LifeTime.eu
                angebotenen Leistungen nach billigem Ermessen zu ändern oder
                abweichende Leistungen anzubieten, soweit dies für die Nutzer
                nicht unzumutbar ist.
              </p>
              <p>
                (2) Darüber hinaus behalten wir uns vor, diese AGB sowie die auf
                LifeTime.eu angebotenen Leistungen zu ändern oder abweichende
                Leistungen anzubieten wenn und soweit
              </p>
              <p>
                a. LIFETIME verpflichtet ist, die Übereinstimmung der
                angebotenen Leistungen mit dem auf die Leistungen anwendbaren
                Recht herzustellen, insbesondere wenn sich die Rechtslage
                ändert;
              </p>
              <p>
                b. LIFETIME damit einem gegen LIFETIME gerichteten
                Gerichtsurteil oder einer Behördenentscheidung nachkommt;
              </p>
              <p>
                c. die jeweilige Änderung notwendig ist, um bestehende
                Sicherheitslücken zu schließen;
              </p>
              <p>d. die Änderung für den Nutzer vorteilhaft ist; oder</p>
              <p>
                e. wenn die Änderung rein technischer oder prozessualer Natur
                und ohne wesentliche Auswirkungen für den Nutzer ist.
              </p>
              <p>
                (3) Änderungen mit lediglich unwesentlichem Einfluss auf die
                Funktionen von LifeTime.eu stellen keine Leistungsänderungen im
                oben genannten Sinne dar. Dies gilt insbesondere für Änderungen
                im Aufbau der Plattform und ihrer Funktionen.
              </p>
              <p>
                (4) Über Änderungen der AGB werden wir Sie informieren, indem
                wir Ihnen die geänderten AGB per E-Mail übersenden und Sie über
                Ihr Recht, diese abzulehnen und die Konsequenzen Ihrer Ablehnung
                informieren. Legen Sie nicht innerhalb von sechs Wochen nach
                Übersendung der geänderten AGB Widerspruch gegen diese ein,
                gelten diese als angenommen.
              </p>
              <p>
                (5) Ihr Kündigungsrecht nach Ziffer 16. bleiben von etwaigen
                Änderungen der AGB bzw. der von uns angebotenen Leistungen nach
                dieser Vorschrift unberührt.
              </p>
              <h2>
                5. Leistungsangebot, Preis- und Leistungsverzeichnis,
                Leistungs-Up- und Downgrade
              </h2>
              <p>
                (1) LIFETIME überlässt dem Nutzer für die Dauer dieses Vertrags
                die LifeTime™ Software und Updates zur bestimmungsgemäßen
                Nutzung gemäß Ziffer 2. Absatz (1) gegen ein monatliches
                Nutzungsentgelt entsprechend dem aktuell gültigen Preis- und
                Leistungsverzeichnis (
                <a href="https://lifetime.eu/order">
                  https://lifetime.eu/order
                </a>
                ).
              </p>
              <p>
                (2) Neben dem Stammangebot kann die LIFETIME andere
                Leistungsangebote definieren. Die zur Verfügung stehenden
                Leistungsangebote werden auf LifeTime.eu jeweils mit den
                Informationen zu den enthaltenen Leistungsinhalten, der Dauer
                etwaiger Testphasen, Laufzeit, Kündigungsfrist, Nutzungsentgelt
                sowie evtl. anfallender Versand- und Einrichtungskosten
                dargestellt.
              </p>
              <p>
                (3) Bei einer Online-Bestellung werden der Inhalt der vom Kunden
                ausgewählten Leistungen, deren Preis zuzüglich Mehrwertsteuer
                und anfallender Versand- und Einrichtungskosten in der
                Bestellmaske angezeigt, bevor Sie die Bestellung absenden. Diese
                Angaben sind vom Kunden sorgfältig zu prüfen.
              </p>
              <p>
                (4) Ein Wechsel zu einem anderen Leistungsangebot vor Ablauf der
                Laufzeit ist nur möglich, soweit es sich um ein nach dem Preis-
                und Leistungsverzeichnis umfangreicheres Leistungsangebot
                handelt („
                <strong>Leistungs-Upgrade</strong>“). Bestellt der Kunde ein
                Leistungs-Upgrade, ist ab dem Datum des Vertragsschlusses im
                Sinne von Ziffer 3 auf das Leistungs-Upgrade der Vertrag mit
                diesem Inhalt neu geschlossen. Möchte ein Kunde zu einem weniger
                umfangreichen Leistungsangebot wechseln („
                <strong>Leistungs-Downgrade</strong>“), hat er die für den
                bestehenden Vertrag geltenden Kündigungsfristen gemäß Ziffer 16
                zu beachten. Ein Leistungs-Downgrade kann erst nach deren Ablauf
                freigeschaltet werden.
              </p>
              <h2>6. Zahlungsbedingungen</h2>
              <p>
                (1) Soweit im Preis- und Leistungsverzeichnis nichts anderes
                geregelt wird, sind die für die einzelnen Leistungsangebote
                anfallenden Preise zuzüglich Umsatzsteuer nach Ablauf etwaiger
                Testphasen jeweils monatlich ab dem Tag fällig, der dem Tag des
                Vertragsschlusses entspricht. Etwaige Gebühren für den Versand
                von Faxen fallen auch während einer Testphase an und werden
                entsprechend dem Verbrauch monatlich ab dem Tag fällig, der dem
                Tag des Vertragsabschlusses entspricht.
              </p>
              <p>
                (2) Sie können das monatliche Nutzungsentgelt mittels SEPA
                Lastschrift oder Kreditkarte bezahlen. Im Fall einer Zahlung per
                EC-/Maestro- oder Kreditkarte werden wir die Belastung Ihres
                Kontos frühestens bei Vertragsschluss veranlassen.
              </p>
              <h2>7. Registrierung, Account, Zugangsdaten</h2>
              <p>
                (1) Um einen Vertrag mit uns schließen und die Registrierung bei
                LifeTime.eu vorzunehmen zu können, müssen Sie
              </p>
              <p>a. 18 Jahre alt oder älter sein und</p>
              <p>
                b. befugt sein, einen rechtsverbindlichen Vertrag mit uns
                abzuschließen und dürfen durch geltende Gesetze nicht daran
                gehindert sein.
              </p>
              <p>Sie sichern außerdem zu, dass</p>
              <p>
                c. die von Ihnen während der Registrierung übermittelten Daten
                wahrheitsgetreu, richtig und vollständig sind sowie, dass Sie
                uns über etwaige Änderungen unmittelbar informieren und
              </p>
              <p>
                d. Sie LifeTime™ ausschließlich für dessen bestimmungsgemäße
                Nutzung gemäß Ziffer 2. Absatz (1) ordern bzw. verwenden.
              </p>
              <p>
                (2) Wir können technisch nicht mit Sicherheit feststellen, ob
                ein auf LifeTime.eu registrierter Nutzer tatsächlich diejenige
                Person darstellt, die der Nutzer vorgibt zu sein. Wir leisten
                daher keine Gewähr für die tatsächliche Identität eines Nutzers.
              </p>
              <p>(3) Jeder Nutzer darf nur einen Account erstellen.</p>
              <p>
                (4) Dem Nutzer ist es nicht gestattet, Dritten Zugang zu seinen
                Zugangsdaten für seinen Account auf LifeTime.eu zu verschaffen.
                Er trägt Sorge dafür, dass kein Missbrauch seines Accounts bzw.
                Nutzerkontos durch Dritte erfolgen kann.
              </p>
              <p>
                (5) Sie erkennen hiermit an, dass Sie für jede Nutzung Ihres
                Benutzernamens und Ihres Passworts für LifeTime.eu
                verantwortlich sind, soweit dies durch mindestens fahrlässige
                Handlungen oder Unterlassungen verursacht wurde.
              </p>
              <p>
                (6) Wenn Sie Ihren Benutzernamen und Ihr Passwort verloren haben
                oder diese gestohlen wurden, oder wenn Sie annehmen, dass Ihr
                Konto von einem Dritten ohne Ihr Wissen genutzt wurde, setzen
                Sie sich sofort mit uns in Verbindung und ändern Sie Ihr
                Passwort so schnell wie möglich.
              </p>
              <h2>8. Von Nutzern eingestellte Inhalte</h2>
              <p>
                (1) Wir gestatten den Nutzern im Rahmen der Nutzung von
                LifeTime.eu, Inhalte zu speichern und an LIFETIME zu
                übermitteln, so dass sie der Öffentlichkeit zugänglich werden.
                LIFETIME überwacht dies dabei nicht und beansprucht auch keine
                Inhaberschaft an diesen Inhalten.
              </p>
              <p>
                (2) Der Nutzer hat gegenüber LIFETIME keinen Anspruch auf die
                Veröffentlichung bestimmter Inhalte auf LifeTime.eu.
              </p>
              <p>
                (3) Sofern ein Nutzer eigene Inhalte (wie Bilder) einstellt, ist
                er hierfür im Verhältnis zu LIFETIME ausschließlich
                verantwortlich und verpflichtet sich zur Einhaltung der
                gesetzlichen Bestimmungen. Insbesondere ist es dem Nutzer
                untersagt, pornografische, gewaltverherrlichende und
                volksverhetzende Inhalte zu verbreiten oder zu Straftaten
                aufzurufen.
              </p>
              <p>
                (4) Der Nutzer muss bei eigenen gespeicherten und
                veröffentlichten Inhalten Inhaber der Leistungsschutzrechte,
                Persönlichkeitsreche, Urheberrechte oder der Nutzungsrechte an
                diesen Rechten sein. Der Nutzer hat somit die eingestellten
                Inhalte entweder selber geschaffen oder die Nutzungsrechte zur
                Verwendung auf unserer Seite von den Berechtigten erworben.
              </p>
              <p>
                (5) LIFETIME kann jederzeit von ihrem Recht Gebrauch machen,
                sämtliche veröffentlichte Inhalte ganz oder teilweise zu sperren
                oder zu löschen. Der Nutzer ist selbst verantwortlich für die
                Sicherung der eingestellten Inhalte. Ersatzansprüche des Nutzers
                wegen gelöschter Inhalte sind ausgeschlossen.
              </p>
              <p>
                (6) Der Nutzer übernimmt die Verantwortung dafür, dass die von
                ihm eingestellten Inhalte frei von Viren, Trojanern und/oder
                sonstigen Programmen sind, welche die Funktionsfähigkeit, den
                Bestand von LifeTime.eu und/oder andere Nutzer erheblich
                beeinträchtigen oder gefährden können.
              </p>
              <p>
                (7) Der Nutzer gewährleistet gegenüber der LIFETIME, dass die
                vom Nutzer auf LifeTime.eu veröffentlichten Inhalte frei von
                Rechten Dritter genutzt werden können. Darunter fallen auch
                etwaige Persönlichkeitsrechte aller abgebildeten, genannten und
                in die Inhalte miteinbezogenen Dritten.
              </p>
              <p>
                (8) Durch das Einstellen von Inhalten auf LifeTime.eu gewährt
                der Nutzer LIFETIME eine nicht exklusive, übertragbare,
                unterlizenzierbare, gebührenfreie, unbefristete,
                unwiderrufliche, bereits vollständig vergütete, weltweit gültige
                Lizenz zur Nutzung, Vervielfältigung, öffentlichen
                Zugänglichmachung, Veröffentlichung, Übersetzung, Veränderung,
                Schaffung von abgeleiteten Werken und Weitergabe des
                nutzergenerierten Inhalts in Verbindung mit den Diensten über
                jedwedes Medium, gleich, ob ausschließlich oder in Verbindung
                mit anderen Inhalten oder Materialien, in jeder Weise,
                unabhängig davon, ob diese heute bereits bekannt ist oder
                hiernach entwickelt wird. Abgesehen von den hierin gesondert
                eingeräumten Rechten behalten Sie alle Rechte, einschließlich
                der Rechte an geistigem Eigentum an nutzergenerierten Inhalten.
              </p>
              <p>
                (9) Sollte ein Nutzer gegen diese Vereinbarung verstoßen, wird
                der Nutzer von seiner Mitgliedschaft oder zumindest von der
                Möglichkeit, Inhalte zu veröffentlichen, ausgeschlossen.
                Überdies behält sich LIFETIME die Geltendmachung von
                Schadensersatzansprüchen vor.
              </p>
              <h2>9. Zugänglichkeit der Produkte</h2>
              <p>
                (1) LIFETIME ist darum bemüht, jederzeit einen ordnungsgemäßen
                Betrieb von LifeTime.eu und der LifeTime™ Software
                sicherzustellen sowie Ausfälle schnellstmöglich zu beheben. Es
                kann jedoch gelegentlich zu bestimmten technischen
                Schwierigkeiten kommen, die zu einer zeitweiligen Unterbrechung
                führen. Sie erkennen hiermit an, dass LIFETIME keine
                Verpflichtung zur ununterbrochenen Verfügbarkeit, zum Upgrade
                oder zur Aktualisierung von LifeTime.eu oder der LifeTime™
                Software eingeht. Insbesondere bedarf LifeTime.eu naturgemäß
                regelmäßiger Wartungsarbeiten, wobei es zu Einschränkungen der
                Verfügbarkeit Ihres Accounts kommen kann. LIFETIME wird sich
                bemühen, Wartungs- sowie etwaige Reparaturarbeiten an
                LifeTime.eu zu Nachtzeiten durchzuführen bzw. durchführen zu
                lassen, um den Abruf von Leistungsangeboten durch die Kunden
                möglichst nicht zu beeinträchtigen. Über etwaige
                Beeinträchtigungen und deren Dauer informieren wir Sie im Rahmen
                des Möglichen unverzüglich auf LifeTime.eu.
              </p>
              <p>
                (2) Damit Sie unsere Leistungsangebote vollständig nutzen
                können, sollten Sie aktuelle und allgemein gängige Soft- und
                Hardware verwenden, d.h. insbesondere die von uns unterstützten
                Betriebssysteme, Windows, OSX oder Linux, vorhalten. Wir
                empfehlen die Nutzung von Windows ab Version 7 bzw. von OSX ab
                Version 10.11, wobei wir uns vorbehalten, bei entsprechender
                Fortentwicklung der Technik, auf neuere Versionen aufzusetzen,
                die wir nach billigem Ermessen bestimmen. Für die
                Betriebsfähigkeit dieser Programme stehen wir nicht ein. Bei
                Gebrauch veralteter oder anderweitiger Technologien besteht die
                Möglichkeit, dass Sie unsere angebotenen Leistungen nicht oder
                nur eingeschränkt nutzen können.
              </p>
              <h2>10. Urheberrechte</h2>
              <p>
                Sofern nicht anders gekennzeichnet, haben wir an dem gesamten
                Angebot von LifeTime.eu sowie der Kennzeichnung „LifeTime.eu“,
                einschließlich aller Bilder, Filme, Texte und sonstigen Werken,
                die auf LifeTime.eu veröffentlicht werden, Urheberrechte. Eine
                Verwendung der Bilder und sonstigen Werke über die von
                LifeTime.eu vorgegebenen Nutzungsarten hinaus ist ohne unsere
                ausdrückliche Einwilligung (§ 183 BGB) nicht gestattet.
              </p>
              <h2>11. LifeTime™ Software</h2>
              <p>
                (1) Die LifeTime™ Software und Updates werden dem Kunden während
                der Vertragslaufzeit zu deren bestimmungsgemäßen Nutzung gemäß
                Ziffer 2. Absatz (1) überlassen. Sämtliche Rechte an der
                LifeTime™ Software und Updates verbleiben im Übrigen bei
                LIFETIME.
              </p>
              <p>
                (2) Dem Kunden ist es untersagt, die LifeTime™ Software Dritten
                zu überlassen, etwa durch Besitzeinräumung oder
                Unterlizensierung.
              </p>
              <p>
                (3) Dem Kunden ist es weiterhin untersagt, die LifeTime™
                Software zu beschädigen, zu kopieren oder anderweitig zu
                beeinflussen.
              </p>
              <p>
                (4) Der Kunde hat LIFETIME jede Fehlfunktion, Beschädigung,
                Zerstörung, Diebstahl oder Verlust der LifeTime™ Software
                unverzüglich anzuzeigen.
              </p>
              <h2>12. Patientenbezogene Daten</h2>
              <p>
                Der Kunde ist verpflichtet, eigenständig alle auf ihn
                anwendbaren Gesetze und Verordnungen bezüglich der Verarbeitung
                personenbezogener Daten einzuhalten. LIFETIME hat keinen Zugriff
                und trägt keine Verantwortung für patientenbezogene Daten.
              </p>
              <h2>13. Geheimhaltung</h2>
              <p>
                Die Parteien sind verpflichtet, Vertrauliche Informationen der
                jeweils anderen Partei, die ihnen während der Vertragslaufzeit
                überlassen, bekannt geworden oder zwischen den Parteien
                vereinbart wurden, nicht ohne vorherige schriftliche
                Einwilligung der anderen Partei zu verwerten oder Dritten
                preiszugeben. Dies gilt nicht, soweit eine Partei gesetzlich
                verpflichtet ist, die Vertraulichen Informationen gegenüber
                Dritten zu offenbaren. In diesem Fall wird die betroffene Partei
                dies der anderen Partei unverzüglich, nachdem sie selbst
                Kenntnis von dieser Verpflichtung erlangt hat, schriftlich
                anzeigen und nur jenen Teil der Vertraulichen Informationen an
                Dritte weitergeben oder veröffentlichen, den sie nach den
                einschlägigen rechtlichen Vorschriften weiterzugeben oder zu
                veröffentlichen verpflichtet ist.
              </p>
              <p>
                „<strong>Vertrauliche Informationen</strong>“ sind alle
                Informationen, Analysen, Zusammenstellungen, Studien, Dokumente,
                Know-how oder andere Unterlagen (gleichgültig, ob mündlich,
                schriftlich, elektronisch oder in sonstiger Weise übermittelt),
                die als vertraulich gekennzeichnet sind oder als vertraulich
                bezeichnet werden (etwa mündlich oder in Begleitdokumenten), die
                die Parteien im Zusammenhang mit dem Vertrag über die Nutzung
                von LifeTime™ austauschen. Zu den Vertraulichen Informationen
                gehören nicht solche Informationen, die nicht als vertraulich
                gekennzeichnet oder bezeichnet wurden oder bereits öffentlich
                bekannt sind, ohne dass die Parteien oder von ihnen
                eingeschaltete Dritte dies zu vertreten hätten, und von denen
                die Parteien nachweisen können, dass sie zum Zeitpunkt der
                Offenlegung durch die andere Partei bereits bekannt bzw. in
                ihrem Besitz waren und ihr nicht direkt oder indirekt mit der
                Verpflichtung, insoweit Verschwiegenheit zu bewahren, bekannt
                oder bekannt gemacht wurden.
              </p>
              <h2>14. Haftung von LIFETIME</h2>
              <p>
                (1) Wir haften Ihnen gegenüber in allen Fällen vertraglicher und
                außervertraglicher Haftung bei Vorsatz und grober Fahrlässigkeit
                nach Maßgabe der gesetzlichen Bestimmungen auf Schadensersatz
                oder Ersatz vergeblicher Aufwendungen. Ansprüche aus § 536a Abs.
                1 Var. 1 BGB (Haftung für anfängliche Mängel) sind
                ausgeschlossen.
              </p>
              <p>
                (2) In sonstigen Fällen haften wir – soweit in Absatz (3) nicht
                abweichend geregelt – nur bei Verletzung einer Vertragspflicht,
                deren Erfüllung die ordnungsgemäße Durchführung des Vertrags
                überhaupt erst ermöglicht und auf deren Einhaltung Sie
                regelmäßig vertrauen dürfen (so genannte Kardinalpflicht), und
                zwar beschränkt auf den Ersatz des vorhersehbaren und typischen
                Schadens. In allen übrigen Fällen ist unsere Haftung
                ausgeschlossen.
              </p>
              <p>
                (3) Unsere Haftung für Schäden aus der Verletzung des Lebens,
                des Körpers oder der Gesundheit und nach dem
                Produkthaftungsgesetz bleibt von den vorstehenden
                Haftungsbeschränkungen und Haftungsausschlüssen unberührt.
              </p>
              <p>
                (4) Die für LIFETIME geltenden Haftungsbeschränkungen nach
                dieser Ziffer 14 gelten gleichermaßen zugunsten der gesetzlichen
                Vertreter, leitenden Angestellten oder Erfüllungsgehilfen der
                LIFETIME.
              </p>
              <h2>15. Minderung, Aufrechnung und Zurückbehaltungsrecht</h2>
              <p>
                (1) Sie sind nicht berechtigt, das anfallende Nutzungsentgelt
                wegen Vorliegens eines Minderungsgrundes im Sinne des § 536 BGB
                zu kürzen, soweit dieser sowie die angemessene Höhe der
                Minderung nicht rechtskräftig festgestellt oder unbestritten
                sind.
              </p>
              <p>
                (2) Sie sind nicht berechtigt, gegenüber unseren Forderungen
                aufzurechnen, es sei denn, Ihre Gegenansprüche sind
                rechtskräftig festgestellt oder unbestritten.
              </p>
              <p>
                (3) Sie dürfen ein Zurückbehaltungsrecht nur dann ausüben, wenn
                Ihr Gegenanspruch rechtskräftig festgestellt oder unbestritten
                ist und aus demselben Vertrag herrührt.
              </p>
              <h2>16. Vertragsdauer, Kündigung</h2>
              <p>
                (1) Die Vertragsdauer für das Stammangebot beträgt einen Monat
                ab Vertragsschluss. Wird der Vertrag nicht binnen einer Frist
                von einer Woche zum jeweiligen Monatsende ordentlich gekündigt,
                verlängert sich die Laufzeit automatisch um einen weiteren
                Monat. Dies gilt auch für eine etwaig vereinbarte Testphase.
              </p>
              <p>
                (2) Die Vertragsdauer, ordentliche Kündigungsfrist und Dauer
                einer etwaigen automatischen Verlängerung der Laufzeit für
                andere Leistungsangebote richtet sich nach dem bei
                Vertragsschluss maßgeblichen Preis- und Leistungsverzeichnis (
                <a href="https://lifetime.eu/order">
                  https://lifetime.eu/order
                </a>
                ).
              </p>
              <p>
                (3) Die Parteien können den Vertrag daneben stets aus wichtigem
                Grund fristlos nach Maßgabe des § 626 BGB kündigen. Ein
                wichtiger Grund für eine Kündigung durch LIFETIME liegt
                insbesondere vor, wenn der Nutzer
              </p>
              <p>
                (a) mit einer fälligen Zahlung nach Erhalt einer
                Zahlungserinnerung oder Mahnung länger als 10 Werktage in Verzug
                gerät oder
              </p>
              <p>
                (b) er gegen die in diesen AGB benannten Verhaltensregeln oder
                gegen sonstige Bestimmungen verstößt und trotz Abmahnung
                innerhalb einer angemessenen Frist keine Abhilfe schafft. Einer
                Abmahnung bedarf es dann nicht, wenn diese keinen Erfolg
                verspricht oder der Verstoß so schwerwiegend ist, dass uns ein
                Festhalten am Vertrag nicht zumutbar ist.
              </p>
              <p>
                (4) Jede Kündigung hat per Brief oder E-Mail zu erfolgen. Im
                Falle einer Kündigung durch den Kunden wird diese von LIFETIME
                unverzüglich nach Zugang der Kündigungserklärung per E-Mail
                bestätigt.
              </p>
              <h2>17. Löschung des Accounts</h2>
              <p>
                (1) LIFETIME kann den Account eines Nutzers nach Ablauf der
                Vertragslaufzeit sowie im Falle einer außerordentlichen
                Kündigung nach Ziffer 16(3) löschen.
              </p>
              <p>
                (2) Der Kunde hat nach Ablauf der Vertragslaufzeit sowie im
                Falle einer außerordentlichen Kündigung nach Ziffer 16(3) einen
                Anspruch auf Löschung seines Accounts gegenüber LIFETIME.
              </p>
              <p>
                (3) LIFETIME haftet in keinem Fall für den durch die Löschung
                eines Accounts beim Kunden erlittenen Verlust von Daten.
              </p>
              <h2>18. Schlussbestimmungen</h2>
              <p>
                (1) Es gilt das Recht der Bundesrepublik Deutschland unter
                Ausschluss des UN-Kaufrechts.
              </p>
              <p>
                (2) Ausschließlicher Gerichtsstand für alle Streitigkeiten aus
                oder in Zusammenhang mit diesem Vertrag einschließlich der
                Wirksamkeit des Vertrages ist der Sitz der LIFETIME, soweit
                gesetzlich zulässig. Im Übrigen gelten für die örtliche und die
                internationale Zuständigkeit die anwendbaren gesetzlichen
                Bestimmungen.
              </p>
              <p>
                (3) Erfüllungsort für LIFETIME und den Kunden ist, soweit
                zulässig, der Sitz der LIFETIME.
              </p>
              <p>
                (4) Sollte eine Bestimmung dieser Geschäftsbedingungen unwirksam
                sein oder werden oder eine Lücke enthalten, so berührt dies die
                Wirksamkeit der übrigen Bestimmungen nicht.
              </p>
              <p>
                (5) We do not conduct business in any other country than Germany
                and Austria as of now. If you are interested to use, purchase,
                license, download or distribute our products in a country other
                than Germany or Austria, please contact <SupportEmail />.
              </p>
            </div>
            <div>
              <p>Stand 02.06.2020</p>
              <p>© LifeTime GmbH</p>
            </div>
          </div>
        </PlainText>
      </Section>
      <Footer withBorder />
    </Fragment>
  )
}

export default ReactPage
