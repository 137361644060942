import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { useTranslation, Trans } from 'react-i18next'

import Section from 'components/section'
import Typewriter from 'components/typewriter'
import Text from 'components/hero/text'
import Button from 'components/button'
import EmbedContainer from 'components/embedContainer'
import Logos from 'components/logos'

const VerticalSpacer = styled.div`
  min-width: 100%;
  ${media.sm`
    margin-top: 32px;
  `}
`

const Hero = props => {
  const { t } = useTranslation()
  return (
    <Section centerText>
      <h1>
        <Trans i18nKey="pages.digitalOffice.hero.title1" />
        <Typewriter />
        <Trans i18nKey="pages.digitalOffice.hero.title2" />
      </h1>
      <Text>{t('pages.digitalOffice.hero.text')}</Text>
      <Button type="button" onClick={props.onClick}>
        {t('pages.digitalOffice.hero.button')}
      </Button>
      <VerticalSpacer>
        <EmbedContainer />
      </VerticalSpacer>
      <Logos />
    </Section>
  )
}

Hero.propTypes = {
  onClick: PropTypes.func
}

export default Hero
