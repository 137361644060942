import React, { Fragment } from 'react'

import Nav from 'components/nav'
import Footer from 'components/footer'
import Section from 'components/section'
import PlainText from 'components/plainText'

import {
  SupportEmail,
  SupportFax,
  SecurityEmail
} from 'components/contactInformation'

const ReactPage = () => {
  return (
    <Fragment>
      <Nav pageType="neutral" />
      <Section size="sm">
        <PlainText>
          <h1>Impressum</h1>
          <p>
            <strong>LifeTime GmbH</strong>
            <br />
            Rehmkamp 1
            <br />
            24161 Altenholz
            <br />
            Amtsgericht Kiel, HRB 24338 KI
            <br />
            <br />
            E-Mail: <SupportEmail />
            <br />
            Fax: <SupportFax />
            <br />
            USt-IdNr.: DE325724081
          </p>
          <p>
            <strong>Geschäftsführer</strong>
            <br />
            Dr. André Kaeding
          </p>
          <p>
            <strong>Inhaltlich Verantwortlicher gem. § 55 II RStV</strong>
            <br />
            Dr. André Kaeding, Rehmkamp 1, 24161 Altenholz
          </p>
          <hr />
          <p>
            Wenn Sie uns zu Sicherheitsthemen bezüglich unseres Produktes
            kontaktieren möchten, nutzen Sie bitte folgende E-Mail-Adresse:{' '}
            <SecurityEmail />
          </p>
          <p>
            Zur sicheren Übermittlung unterstützen wir PGP-verschlüsselte
            E-Mails mit folgendem Schlüssel:{' '}
            <a href="security-connected-health-eu.asc">
              security-connected-health-eu.asc
            </a>
            .
          </p>
          <h2>Haftung für Inhalte</h2>
          <p>
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
            die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können
            wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
            gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
            als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
          <hr />
          <p>LifeTime® ist eine Marke der LifeTime&nbsp;GmbH.</p>
          <p>© LifeTime GmbH</p>
        </PlainText>
      </Section>
      <Footer withBorder />
    </Fragment>
  )
}

export default ReactPage
