import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'
import join from 'url-join'
import { Trans } from 'react-i18next'

import { color, fontSize } from 'styles/theme'

import i18n from 'components/i18n'
import Link from 'components/link'
import Container from 'components/container'
import Icon from 'components/icons'
import Accordion from 'components/accordion'

import ExternalLinkIcon from 'img/external-link.svg'

const Section = styled.section`
  background: ${props => (props.dark ? color.grey800 : 'white')};
  display: flex;
  justify-content: center;
  line-height: 1.5;
  border-top: ${props =>
    props.withBorder ? `1px solid ${color.grey150}` : 'none'};
  ${media.sm`
    border: none;
  `}
`

const MenuContainer = styled(Container)`
  padding: ${props =>
    props.noTopPadding ? '0 32px 64px 32px;' : '72px 32px 64px 32px;'};
  ${media.md`
    padding: 0px 32px 32px 32px;
  `}
  ${media.sm`
    padding: 0px 16px 32px 16px;
  `}
`

const BottomSection = styled(Section)`
  background: ${props => (props.dark ? color.grey900 : color.grey50)};
`

const BottomContainer = styled(Container)`
  padding: 24px 16px;
`

const Padding = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 80px;
  ${media.sm`
    padding: 0;
  `}
`

const FooterDesktop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 64px;
  font-size: ${fontSize.f3};
  ${media.md`
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 32px;
    display: none;
  `}
  ${media.sm`
    display: none;
  `}
`

const Col = styled.div`
  &:last-of-type {
    margin: 0;
  }
`

const FooterHeading = styled.div`
  color: ${props => (props.dark ? color.grey200 : color.grey900)};
  font-weight: 900;
  margin-bottom: 16px;
  ${media.sm`
    margin-bottom: 16px;
  `}
`

const FooterLink = styled(Link)`
  display: block;
  color: ${props => (props.dark ? color.grey400 : color.grey900)};
  text-decoration: none;
  white-space: nowrap;
  float: left;
  clear: left;
  &:visited {
    color: ${props => (props.dark ? color.grey400 : color.grey900)};
  }
  &:hover {
    color: ${props => (props.dark ? color.grey400 : color.grey900)};
  }
`

const FooterLinkExternal = styled.a`
  display: block;
  color: ${props => (props.dark ? color.grey400 : color.grey900)};
  text-decoration: none;
  white-space: nowrap;
  float: left;
  clear: left;
  &:after {
    content: '';
    display: block;
    background: url(${ExternalLinkIcon}) no-repeat;
    width: 16px;
    height: 16px;
    background-size: contain;
    bottom: -3px;
    left: 8px;
    float: right;
    position: relative;
    margin-right: -16px;
  }
  &:visited {
    color: ${props => (props.dark ? color.grey400 : color.grey900)};
  }
  &:hover {
    color: ${props => (props.dark ? color.grey400 : color.grey900)};
  }
`

const FooterMobile = styled.div`
  width: 100%;
  font-size: ${fontSize.f3};
  padding-top: 32px;
  display: none;
  ${media.md`
    display: block;
  `}
`

const BottomBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  justify-content: space-between;
  width: 100%;
  padding: 0 80px;
  align-items: center;
  grid-template-areas: 'language copyright social';
  line-height: 1.6;
  ${media.md`
    grid-template-columns: auto;
    justify-content: center;
    text-align: center;
    grid-row-gap: 16px;
    grid-template-areas:
      'language'
      'social'
      'copyright';
  `}
  ${media.sm`
    padding: 0;
  `}
`

const LanguageSelectWrapper = styled.div`
  grid-area: language;
  padding-left: 12px;
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: left;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: ${color.grey400} transparent transparent transparent;
    position: relative;
    right: 12px;
    pointer-events: none;
  }
  ${media.md`
    justify-content: center;
  `}
`

const LanguageSelect = styled.select`
  height: 32px;
  border: none;
  background: none;
  font-weight: 700;
  color: ${props => (props.dark ? color.grey200 : color.grey400)};
  padding: 0 12px;
  width: 88px;
  font-size: ${fontSize.f2};
`

const SocialIcons = styled.div`
  grid-area: social;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  grid-column-gap: 16px;
  justify-content: end;
  ${media.md`
    justify-content: center;
  `}
  ${media.sm`
    grid-column-gap: 8px;
  `}
`

const SocialLink = styled.a`
  display: flex;
  align-items: center;
`

const Copyright = styled.div`
  grid-area: copyright;
  color: ${props => (props.dark ? color.grey600 : color.grey400)};
  max-width: 480px;
  text-align: center;
  font-size: ${fontSize.f2};
`

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedLanguage: 'de'
    }
  }

  componentDidMount() {
    this.setState({ selectedLanguage: this.getCurrentLanguage() })
  }

  getCurrentLanguage = () => i18n.language

  handleLanguageChange = () => {
    const path = window.location.pathname.split('/')
    path.shift() // remove first slash
    if (path[0] === 'de' || path[0] === 'en') {
      path.shift() // remove locale string in path if there is one
    }
    if (event.target.value === 'de') {
      path.unshift('/')
    }
    if (event.target.value === 'en') {
      path.unshift('/en')
    }
    const baseLocation = window.location.origin
    const newPath = join(path)
    const newLocation = baseLocation + newPath
    window.location.assign(newLocation)
    this.setState({ value: event.target.value })
  }

  render() {
    const forDoctorsLinks = (
      <Fragment>
        <FooterLink to="/order/plan" dark={this.props.dark}>
          <Trans i18nKey="footer.plans" />
        </FooterLink>
        <FooterLinkExternal
          href="https://support.lifetime.eu/"
          target="_blank"
          dark={this.props.dark}
        >
          <Trans i18nKey="footer.support" />
        </FooterLinkExternal>
        <FooterLink to="/download" dark={this.props.dark}>
          <Trans i18nKey="footer.demo" />
        </FooterLink>
        <FooterLink to="/profile/login" dark={this.props.dark}>
          <Trans i18nKey="footer.login" />
        </FooterLink>
      </Fragment>
    )
    const forPatientsLinks = (
      <Fragment>
        <FooterLinkExternal
          href="https://itunes.apple.com/de/app/lifetime-deine-gesundheit/id981140831"
          target="_blank"
          dark={this.props.dark}
        >
          <Trans i18nKey="footer.ios" />
        </FooterLinkExternal>
        <FooterLinkExternal
          href="https://play.google.com/store/apps/details?id=eu.connectedhealth.lifetime"
          target="_blank"
          dark={this.props.dark}
        >
          <Trans i18nKey="footer.android" />
        </FooterLinkExternal>
        <FooterLink to="/lifetime-praxen" dark={this.props.dark}>
          <Trans i18nKey="footer.findDoctors" />
        </FooterLink>
        <FooterLinkExternal
          href="https://support.lifetime.eu/"
          target="_blank"
          dark={this.props.dark}
        >
          <Trans i18nKey="footer.support" />
        </FooterLinkExternal>
      </Fragment>
    )
    const CompanyLinks = (
      <Fragment>
        <FooterLink to="/jobs" dark={this.props.dark}>
          <Trans i18nKey="footer.jobs" />
        </FooterLink>
        <FooterLink to="/presse" dark={this.props.dark}>
          <Trans i18nKey="footer.press" />
        </FooterLink>
      </Fragment>
    )
    const LegalLinks = (
      <Fragment>
        <FooterLink to="/impressum" dark={this.props.dark}>
          <Trans i18nKey="footer.imprint" />
        </FooterLink>
        <FooterLink to="/datenschutz/web" dark={this.props.dark}>
          <Trans i18nKey="footer.dataPrivacy" />
        </FooterLink>
        <FooterLink to="/agb" dark={this.props.dark}>
          <Trans i18nKey="footer.terms" />
        </FooterLink>
      </Fragment>
    )
    return (
      <Fragment>
        <Section withBorder={this.props.withBorder} dark={this.props.dark}>
          <MenuContainer noTopPadding={this.props.noTopPadding}>
            <Padding>
              <FooterDesktop>
                <Col>
                  <FooterHeading dark={this.props.dark}>
                    <Trans i18nKey="footer.forDoctorsHeading" />
                  </FooterHeading>
                  {forDoctorsLinks}
                </Col>
                <Col>
                  <FooterHeading dark={this.props.dark}>
                    <Trans i18nKey="footer.forPatientsHeading" />
                  </FooterHeading>
                  {forPatientsLinks}
                </Col>
                <Col>
                  <FooterHeading dark={this.props.dark}>
                    <Trans i18nKey="footer.companyHeading" />
                  </FooterHeading>
                  {CompanyLinks}
                </Col>
                <Col>
                  <FooterHeading dark={this.props.dark}>
                    <Trans i18nKey="footer.legalHeading" />
                  </FooterHeading>
                  {LegalLinks}
                </Col>
              </FooterDesktop>
              <FooterMobile>
                <Accordion
                  heading={<Trans i18nKey="footer.forDoctorsHeading" />}
                  dark={this.props.dark}
                >
                  {forDoctorsLinks}
                </Accordion>
                <Accordion
                  heading={<Trans i18nKey="footer.forPatientsHeading" />}
                  dark={this.props.dark}
                >
                  {forPatientsLinks}
                </Accordion>
                <Accordion
                  heading={<Trans i18nKey="footer.companyHeading" />}
                  dark={this.props.dark}
                >
                  {CompanyLinks}
                </Accordion>
                <Accordion
                  heading={<Trans i18nKey="footer.legalHeading" />}
                  dark={this.props.dark}
                >
                  {LegalLinks}
                </Accordion>
              </FooterMobile>
            </Padding>
          </MenuContainer>
        </Section>
        <BottomSection dark={this.props.dark}>
          <BottomContainer>
            <BottomBar>
              <LanguageSelectWrapper>
                <Icon
                  glyph="globe"
                  size={16}
                  color={this.props.dark ? color.grey200 : color.grey400}
                />
                <LanguageSelect
                  value={this.state.selectedLanguage}
                  onChange={this.handleLanguageChange}
                  dark={this.props.dark}
                >
                  <option value="de">Deutsch</option>
                  <option value="en">English</option>
                </LanguageSelect>
              </LanguageSelectWrapper>
              <Copyright dark={this.props.dark}>© 2022 LifeTime GmbH</Copyright>
              <SocialIcons>
                <SocialLink href="https://twitter.com/lifetime_eu">
                  <Icon
                    glyph="twitter"
                    color={this.props.dark ? color.grey600 : color.grey200}
                  />
                </SocialLink>
                <SocialLink href="https://www.facebook.com/lifetime.eu">
                  <Icon
                    glyph="facebook"
                    color={this.props.dark ? color.grey600 : color.grey200}
                  />
                </SocialLink>
                <SocialLink href="https://www.youtube.com/watch?v=dSeNl6t6ysc">
                  <Icon
                    glyph="youtube"
                    color={this.props.dark ? color.grey600 : color.grey200}
                  />
                </SocialLink>
              </SocialIcons>
            </BottomBar>
          </BottomContainer>
        </BottomSection>
      </Fragment>
    )
  }
}

Footer.propTypes = {
  withBorder: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  dark: PropTypes.bool
}

export default Footer
